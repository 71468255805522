import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./AvailableRewardsButton.scss";

import { PurchaseOrder } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";

interface AvailableRewardsButtonProps {
  marginTop?: boolean;
  purchaseOrder?: PurchaseOrder;
  redRipBackground?: boolean;
}

const AvailableRewardsButton = ({
  marginTop,
  purchaseOrder,
  redRipBackground,
}: AvailableRewardsButtonProps) => {
  const navigate = useNavigate();
  const sheetzButtonContainerClasses = classNames({
    "top-rip bottom-rip sheetz-red available-rewardz-button-container": redRipBackground,
  });
  const sheetzButtonClasses = classNames("available-rewardz-button", {
    "margin-top": marginTop,
  });

  if (
    purchaseOrder?.confirmableOffers &&
    purchaseOrder?.confirmableOffers?.length > 0 &&
    purchaseOrder.redeemableOfferStatus === "SUCCESS"
  ) {
    return (
      <div className={sheetzButtonContainerClasses}>
        <SheetzButton
          className={sheetzButtonClasses}
          label="Available Rewardz"
          label2={getIcon(IconType.arrow, "arrow-icon")}
          borderColor="light-border"
          onClick={(): void => navigate("/order/confirmOffers", { state: { goBack: true } })}
        />
      </div>
    );
  }

  return null;
};

export default AvailableRewardsButton;
