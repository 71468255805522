import React, { ReactElement, useContext, useEffect, useState } from "react";

import "./Rewardz.scss";

import { GetUserResponse, PurchasedReward, TierReward } from "assets/dtos/anywhere-dto";

import GetRewardz from "components/Account/Rewardz/GetRewardz/GetRewardz";
import MyRewardz from "components/Account/Rewardz/MyRewardz/MyRewardz";
import Tabs from "components/misc/view/Tabs/Tabs";

import { getUserSummary } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { getUserRewards } from "util/Rewardz.util";

const Rewardz = (): ReactElement => {
  const appContext = useContext(AppContext);
  const [getRewardzActiveTab, setGetRewardzActiveTab] = useState(true);
  const [myRewardzActiveTab, setMyRewardzActiveTab] = useState(false);
  const [tierRewards, setTierRewards] = useState<TierReward[]>();
  const [userData, setUserData] = useState<GetUserResponse>();
  const [purchasedRewards, setPurchasedRewards] = useState<PurchasedReward[]>();

  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;

  useEffect(() => {
    showLoading();
    Promise.allSettled([getUserSummary(), getUserRewards()])
      .then((values) => {
        const userInfoResponse = values[0];
        const rewardsResponse = values[1];

        if (rewardsResponse.status === "fulfilled") {
          setTierRewards(rewardsResponse.value.data.tierRewards);
          setPurchasedRewards(rewardsResponse.value.data.purchasedRewards);
        }

        if (userInfoResponse.status === "fulfilled") {
          setUserData(userInfoResponse.value.data);
        }
      })
      .finally(() => {
        hideLoading();
      });
  }, [hideLoading, showLoading]);

  const loadUserInfo = function (): void {
    getUserSummary().then((response) => {
      setUserData(response.data);
    });
  };

  const loadUserRewardz = function (): void {
    getUserRewards().then((response) => {
      setTierRewards(response.data.tierRewards);
      setPurchasedRewards(response.data.purchasedRewards);
    });
  };
  const reloadUserData = (): void => {
    loadUserInfo();
    loadUserRewardz();
  };

  const rewardzTabs = [
    {
      label: "Get Rewardz",
      onClick: (): void => {
        setGetRewardzActiveTab(true);
        setMyRewardzActiveTab(false);
      },
      className: "rewardz-tab-width",
      disabled: false,
      activeTab: getRewardzActiveTab,
    },
    {
      label: "My Rewardz",
      onClick: (): void => {
        setGetRewardzActiveTab(false);
        setMyRewardzActiveTab(true);
      },
      className: "rewardz-tab-width",
      disabled: false,
      activeTab: myRewardzActiveTab,
    },
  ];

  if (userData && tierRewards) {
    return (
      <Tabs tabs={rewardzTabs}>
        {getRewardzActiveTab && (
          <GetRewardz
            key="getRewardz"
            reloadUserData={reloadUserData}
            rewards={tierRewards}
            userData={userData}
          />
        )}
        {myRewardzActiveTab && <MyRewardz key="myRewardz" purchasedRewards={purchasedRewards} />}
      </Tabs>
    );
  } else {
    return <></>;
  }
};

export default Rewardz;
