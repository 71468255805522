import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./EditCombo.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ItemImageTextDisplay from "components/misc/item/ItemDisplay/ItemImageTextDisplay/ItemImageTextDisplay";

import { ShoppingBagCombo } from "util/Bag.util";
import { buildCondimentListString } from "util/Customization.util";
import { CustomizedItem, isCustomizedItem } from "util/Order.util";
import { ReorderedShoppingBagItem } from "util/Reorder.util";

interface EditComboProps {
  shoppingBagCombo: ShoppingBagCombo;
  onDoneEditing: () => void;
}

const EditCombo = (props: EditComboProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const comboItems = props.shoppingBagCombo.items.map(
    (comboItem: CustomizedItem | ReorderedShoppingBagItem, index: number) => {
      let retailModifiedItemId: number | undefined;
      let image: string | undefined;
      let title: string | undefined;

      if (isCustomizedItem(comboItem)) {
        retailModifiedItemId = comboItem.retailModifiedItem?.retailModifiedItemId;
        image = comboItem.retailItem?.image;
        title = comboItem.retailModifiedItem?.receiptText;
      } else {
        retailModifiedItemId = comboItem.retailModifiedItemId;
        image = comboItem.image;
        title = comboItem.receiptText;
      }

      const description = buildCondimentListString(comboItem);

      return (
        <div key={retailModifiedItemId + "_" + index} className="combo-list-item">
          <div className="item-image-text-container">
            <ItemImageTextDisplay
              description={description}
              image={image}
              title={title ?? "Item #" + index}
            />
          </div>

          {isCustomizedItem(comboItem) && (
            <SheetzTextButton
              textDark
              label="Edit"
              onClick={(): void => editComboItem(comboItem, index)}
            />
          )}
        </div>
      );
    }
  );

  function editComboItem(comboItem: CustomizedItem, index: number): void {
    props.onDoneEditing();

    navigate("/order/customize/" + comboItem.itemCustomizationId, {
      state: {
        bagComboId: props.shoppingBagCombo.id,
        bagComboItemIndex: index,
        previousLocation: location.pathname,
        event: comboItem.event,
      },
    });
  }

  return (
    <div className="edit-combo-container">
      <p className="edit-combo-title">My {props.shoppingBagCombo.comboDetails.name} Combo</p>
      <div className="combo-list-items">{comboItems}</div>
      <SheetzButton className="edit-combo-done-button" label="Done" onClick={props.onDoneEditing} />
    </div>
  );
};

export default EditCombo;
