import React, { useEffect, useState } from "react";

import { Condiment, Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import InlineSubSelector from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector";
import { setInlineSubSelectorRowPlacement } from "components/Order/ItemCustomization/Selectors/InlineSubSelector/InlineSubSelector.util";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { useMediaQuery } from "hooks/useMediaQuery";

import { desktopMediaQuery } from "util/AppContext.util";
import {
  DoubleOrSwitchOption,
  ExtraableOption,
  InlineSubOption,
  MultiOptionSwitchOption,
} from "util/Customization.util";

interface DoubleOrSwitchSelectorProps {
  selector: Selector;
  options: DoubleOrSwitchOption[];
  selectedOption?: DoubleOrSwitchOption;
  onOptionSelected: (singleSelectOption: ExtraableOption) => void;
  onOptionDeselected: () => void;
  onExtraSelected: () => void;
  onSwitchOptionSelected: (option: DoubleOrSwitchOption) => void;
  onSwitchOptionDeselected: () => void;
}

const DoubleOrSwitchSelector = (props: DoubleOrSwitchSelectorProps) => {
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const gridRef = React.createRef<HTMLDivElement>();
  const inlineSubSelectorRef = React.createRef<HTMLDivElement>();
  const [showInlineSubActionSheet, setShowInlineSubActionSheet] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<MultiOptionSwitchOption>();

  const doubleableOptions = props.options
    .filter((option) => "extraOption" in option)
    .map((doubleableOption) => {
      const isSelected =
        props.selectedOption &&
        doubleableOption.condiment.retailModifiedItem.retailModifiedItemId ===
          props.selectedOption.condiment.retailModifiedItem.retailModifiedItemId;
      return (
        <CustomizationOption
          key={doubleableOption.option.text}
          option={doubleableOption.option}
          condiment={doubleableOption.condiment}
          type={props.selector.type}
          isSelected={isSelected}
          onCondimentOptionSelected={condimentOptionSelected}
        />
      );
    });

  useEffect(() => {
    setInlineSubSelectorRowPlacement(
      useDesktopView,
      gridRef.current,
      inlineSubSelectorRef.current,
      props.options,
      props.selectedOption
    );
  }, [gridRef, inlineSubSelectorRef, props.options, props.selectedOption, useDesktopView]);

  const switchOptions = props.options
    .filter((option) => "inlineSubOptions" in option)
    .map((multiOptionSwitchOption: MultiOptionSwitchOption) => {
      const isSelected =
        props.selectedOption &&
        multiOptionSwitchOption.condiment.retailModifiedItem.retailModifiedItemId ===
          props.selectedOption.condiment.retailModifiedItem.retailModifiedItemId;
      return (
        <CustomizationOption
          key={multiOptionSwitchOption.option.text}
          option={multiOptionSwitchOption.option}
          type={props.selector.type}
          condiment={multiOptionSwitchOption.condiment}
          price={multiOptionSwitchOption.priceDifference}
          isSelected={isSelected}
          onCondimentOptionSelected={switchOptionCondimentSelected}
          isGreenBorder={props.selector.theme === "ULTIMATE"}
        />
      );
    });

  function createOptionButtons(): JSX.Element {
    let buttons = null;

    if (selectedOption?.option.inlineSubSelector && selectedOption.inlineSubOptions?.length) {
      buttons = selectedOption.inlineSubOptions.map((inlineSubOption) => {
        return (
          <li key={inlineSubOption.option.text}>
            <SheetzButton
              transparentDark
              className="inline-sub-option-button"
              label={inlineSubOption.option.text}
              label2={"+" + inlineSubOption.condiment.retailModifiedItem.price.toFixed(2)}
              onClick={(): void => {
                setShowInlineSubActionSheet(false);
                inlineSubOptionSelected(inlineSubOption);
              }}
            />
          </li>
        );
      });
    }

    return <ul className="inline-options-list">{buttons}</ul>;
  }

  function inlineSubOptionSelected(inlineSubOption: InlineSubOption): void {
    const selectedMultiOptionSwitchOption = {
      ...selectedOption,
      selectedInlineSubOption: inlineSubOption,
    } as MultiOptionSwitchOption;
    props.onSwitchOptionSelected(selectedMultiOptionSwitchOption);
  }

  function switchOptionCondimentSelected(condiment: Condiment): void {
    const newlySelectedOption = props.options.find(
      (option) =>
        option.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );

    if (!newlySelectedOption || !("inlineSubOptions" in newlySelectedOption)) {
      return;
    }

    // If the newly selected condiment is the same as the currently selected condiment, then un-switch.
    if (
      props.selectedOption?.condiment.retailModifiedItem.retailModifiedItemId ===
      condiment.retailModifiedItem.retailModifiedItemId
    ) {
      setShowInlineSubActionSheet(false);
      props.onSwitchOptionDeselected();
    } else {
      // Show the action sheet if the option contains an inline sub selector.
      if (
        newlySelectedOption.option.inlineSubSelector &&
        newlySelectedOption.inlineSubOptions?.length
      ) {
        setShowInlineSubActionSheet(true);
        setSelectedOption(newlySelectedOption);
        return;
      } else {
        props.onSwitchOptionSelected(newlySelectedOption);
      }
    }
  }

  function condimentOptionSelected(condiment: Condiment): void {
    // If the newly selected condiment is the same as the currently selected condiment, then remove it.
    if (
      props.selectedOption?.condiment.retailModifiedItem.retailModifiedItemId ===
      condiment.retailModifiedItem.retailModifiedItemId
    ) {
      props.onOptionDeselected();
      return;
    }
    const newlySelectedOption = props.options.find(
      (option) =>
        option.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );
    if (newlySelectedOption) {
      props.onOptionSelected(newlySelectedOption);
    }
  }

  return (
    <>
      <div className="double-or-switch-selector">
        <SelectorHeader
          headerText={props.selector.text ?? ""}
          maxSelections={1}
          required={props.selector.optional === false}
          selected={!!props.selectedOption}
        />
        <CondimentGrid ref={gridRef}>
          {switchOptions}
          {doubleableOptions}
          {props.selectedOption &&
            "extraOption" in props.selectedOption &&
            props.selectedOption.extraCondiment && (
              <InlineSubSelector
                ref={inlineSubSelectorRef}
                condiment={props.selectedOption.extraCondiment}
                selected={!!props.selectedOption.extraOptionSelected}
                onInlineSubSelectorSelected={(): void => props.onExtraSelected()}
              />
            )}
        </CondimentGrid>
      </div>
      <ActionSheet
        color={ActionSheetColor.red}
        title={selectedOption?.option.inlineSubSelector?.text ?? "Choose an option"}
        overlay={true}
        shouldDisplay={showInlineSubActionSheet}
        onOverlayClick={(): void => {
          setShowInlineSubActionSheet(false);
          setSelectedOption(undefined);
        }}
        cancelFunction={(): void => {
          setShowInlineSubActionSheet(false);
          setSelectedOption(undefined);
        }}
      >
        {props.selector?.options && createOptionButtons()}
      </ActionSheet>
    </>
  );
};

export default DoubleOrSwitchSelector;
