import React from "react";

import "./OfferRebate.scss";

import { Offer as OfferDTO, OfferRequestAction } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { IconType, getIcon } from "util/Icon.util";
import { OfferAction } from "util/Offerz.util";
import { getDateTimeFromString } from "util/Time.util";

interface OfferProps {
  offer: OfferDTO;
  onUpdateOffer: (offerId: number, offerAction: OfferRequestAction) => void;
}

const Offer = ({ offer, onUpdateOffer }: OfferProps) => {
  return (
    <>
      <div className={"offers-container" + (offer.isAccepted ? " purchased" : "")}>
        <div className="offer-contents">
          <img className="offer-product-image" src={offer.imageURL} alt={offer.offerName} />
          <div className="offer-info">
            <div className="rebate-title">{offer.offerName}</div>
            <div className="offer-description">{offer.description}</div>
            {offer.balanceExpireDate !== undefined && (
              <div className="offer-expiration">
                {"Expires " + getDateTimeFromString(offer.balanceExpireDate).toFormat("L'/'d'/'yy")}
              </div>
            )}
            {offer.endDate && offer.endDate !== undefined && (
              <div className="offer-expiration">
                {"Expires " + getDateTimeFromString(offer.endDate).toFormat("L'/'d'/'yy")}
              </div>
            )}
          </div>
        </div>

        {offer.isAccepted ? (
          <>
            <div className="offer-redemption">
              <div className="offer-redemption-uses">{offer.balance} Uses Left</div>
              <div className="offer-label">
                {getIcon(IconType.ageicon, "age-icon")}
                On your account!
              </div>
            </div>
          </>
        ) : (
          <div className="offer-btn-container">
            <SheetzTextButton
              className="remove-btn"
              textDark
              label="Remove"
              onClick={(): void => onUpdateOffer(offer.offerId, OfferAction.DECLINE)}
            />
            <SheetzButton
              className="confirm-btn"
              label="Add to Card"
              onClick={(): void => {
                onUpdateOffer(offer.offerId, OfferAction.ACCEPT);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Offer;
