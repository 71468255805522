import classNames from "classnames";
import React, { useState } from "react";

import "./OfferRebate.scss";

import {
  AgeRestrictedRebate as AgeRestrictedRebateDTO,
  RebateRequestAction,
} from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { RebateAction } from "util/AgeRestricted.util";
import { IconType, getIcon } from "util/Icon.util";
import { getDateTimeFromString } from "util/Time.util";

interface RebateProps {
  ageRestrictedRebate: AgeRestrictedRebateDTO;
  onUpdateRebates: (rebateId: number, rebateAction: RebateRequestAction) => void;
}

const offersClass = classNames("offers-container");

const Rebate = ({ ageRestrictedRebate, onUpdateRebates }: RebateProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className={offersClass + (ageRestrictedRebate.isClipped ? " purchased" : "")}>
        <div className="offer-contents">
          <img
            className="offer-product-image"
            src={ageRestrictedRebate.imageUrl}
            alt={ageRestrictedRebate.name}
          />
          <div className="offer-info">
            <div className="rebate-title">{ageRestrictedRebate.name}</div>
            <div className="offer-description">{ageRestrictedRebate.details}</div>
            {ageRestrictedRebate.endDate && (
              <div className="offer-expiration">
                {"Expires " +
                  getDateTimeFromString(ageRestrictedRebate.endDate).toFormat("L'/'d'/'yy")}
              </div>
            )}
          </div>
        </div>

        {ageRestrictedRebate.isClipped ? (
          <>
            <div className="offer-redemption">
              <div className="offer-redemption-uses">
                {ageRestrictedRebate.redemptionsRemaining} Uses Left
              </div>
              <div className="offer-label">
                {getIcon(IconType.ageicon, "age-icon")}
                On your account!
              </div>
            </div>
          </>
        ) : (
          <div className="offer-btn-container">
            <>
              <SheetzTextButton
                className="remove-btn"
                textDark
                label="Remove"
                onClick={(): void =>
                  onUpdateRebates(ageRestrictedRebate.rebateId, RebateAction.DISMISS)
                }
              />
              <SheetzButton
                className="confirm-btn"
                label="Add to Card"
                onClick={(): void => {
                  onUpdateRebates(ageRestrictedRebate.rebateId, RebateAction.CLIP);
                }}
              />
            </>
          </div>
        )}

        <div className="bybe-conditions-container">
          <div className="bybe-icon">Rebates by {getIcon(IconType.bybe, "bybe-icon")}</div>

          <div className="conditions">
            <SheetzTextButton
              className="conditions-text"
              label={
                !isExpanded ? (
                  <span className="conditions-arrow">
                    View full Terms & Conditions {getIcon(IconType.arrow, "arrow-icon")}
                  </span>
                ) : (
                  ""
                )
              }
              label2={
                isExpanded ? (
                  <span className="conditions-close-arrow">
                    Close full Terms & Conditions {getIcon(IconType.arrow, "arrow-icon")}
                  </span>
                ) : (
                  ""
                )
              }
              value={ageRestrictedRebate.conditions}
              onClick={(): void => {
                setIsExpanded((currentValue) => !currentValue);
              }}
            />
          </div>
        </div>

        <div className="offer-conditions">
          {isExpanded && (
            <>
              {ageRestrictedRebate.conditions}
              <div className="view-conditions">
                <SheetzTextButton
                  className="close-conditions"
                  onClick={(): void => {
                    setIsExpanded(false);
                  }}
                  label="Close"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Rebate;
