import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import { useLocation, useNavigate } from "react-router-dom";

import "./AddFunds.scss";

import { PaymentMethod, ZCardAddFundsRequest } from "assets/dtos/anywhere-dto";

import AddFundsHeader from "components/Account/MyWallet/AddFunds/AddFundsHeader/AddFundsHeader";
import ConfirmGiftCardReload from "components/Account/MyWallet/AddFunds/ConfirmGiftCardReload/ConfirmGiftCardReload";
import ChoosePaymentMethod from "components/Account/MyWallet/ChoosePaymentMethod/ChoosePaymentMethod";
import Pin from "components/Account/MyWallet/Pin/Pin";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import {
  AddFundsHeaderContext,
  AddFundsHeaderContextProps,
  GiftCardState,
  addFundsGiftCard,
} from "util/Payment.util";
import { verifyPinStillValid } from "util/Pin.util";
import getOrGenerateRavelinId from "util/Ravelin.util";
import {
  getPinAuthToken,
  getUserId,
  removeSelectedWalletPaymentMethodId,
  setSelectedPaymentMethodId,
} from "util/Storage.util";

const AddFunds = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [fundAmount, setFundAmount] = useState<number>(25);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod>();
  const [paymentMethodId, setPaymentMethodId] = useState<number>();
  const [showGiftCardReloadModal, setShowGiftCardReloadModal] = useState<boolean>(false);
  const [pinAuthenticated, setPinAuthenticated] = useState<boolean>(false);
  const addFundsHeaderContext = AddFundsHeaderContext;
  const addFundsState = location.state as GiftCardState;

  useEffect(() => {
    // user navigates to add funds from MyWallet or AddPaymentMethod - both pass the payment method ID
    if (addFundsState && addFundsState.paymentMethodId) {
      setPaymentMethodId(addFundsState.paymentMethodId);
      if (addFundsState.amount) {
        setFundAmount(addFundsState.amount);
        setAmount(addFundsState.amount);
      }
    }
    if (addFundsState.amount) {
      setFundAmount(addFundsState.amount);
      setAmount(addFundsState.amount);
    }
  }, [addFundsState]);

  function handleEditPaymentMethodClick(paymentMethod: PaymentMethod): void {
    if (!paymentMethod.creditCard?.isExpired) {
      setPaymentMethodSelected(paymentMethod);
    } else {
      navigate(location.pathname, {
        state: {
          ...addFundsState,
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: fundAmount,
          returnUri: "/account/addFunds",
        },
        replace: true,
      });
      navigate("/account/myWallet", {
        state: {
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: fundAmount,
          returnUri: "/account/addFunds",
        },
      });
    }
  }

  function setAmount(amount: number): void {
    setFundAmount(amount);
  }

  function setPinAuthentication(authenticated: boolean): void {
    setPinAuthenticated(authenticated);
  }

  function closedWithoutAuthenticating(): void {
    navigate("/account/myWallet");
  }

  // pass the payment method to the Add Payment Method screen so when the user is finished there, they can pass the payment method back
  // this payment method Id represents the gift card that funds are being added to
  function navigateToPaymentMethod(): void {
    navigate(location.pathname, {
      state: {
        ...addFundsState,
        amount: fundAmount,
        returnUri: "/account/addFunds",
      },
      replace: true,
    });
    navigate("/account/addPaymentMethod", {
      state: {
        paymentMethodId: paymentMethodId,
        amount: fundAmount,
        returnUri: "/account/addFunds",
      },
    });
  }

  function closeGiftCardReloadModal(): void {
    setShowGiftCardReloadModal(false);
  }

  function submitGiftCardReload(): void {
    const pinAuthJWT = getPinAuthToken();
    const isPinStillValid = verifyPinStillValid();
    if (!isPinStillValid) {
      appContext.showToast("Oh Sheetz!", "Your Pin session has expired!", ToastType.error);
      navigate("/account/myWallet");
      return;
    }

    if (fundAmount && paymentMethodSelected && paymentMethodId && pinAuthJWT) {
      getOrGenerateRavelinId()
        .then((id) => {
          const addFundsRequest: ZCardAddFundsRequest = {
            amount: fundAmount,
            targetZCardPaymentMethodId: paymentMethodId,
            sourcePaymentMethodId: paymentMethodSelected.paymentMethodId,
            jwt: pinAuthJWT.jwtToken,
            deviceId: id,
          };

          const dataLayer = {
            event: "purchase",
            ecommerce: {
              value: fundAmount,
              currency: "USD",
            },
            userId: getUserId(),
            transactionType: "Reload Gift Card",
            dateTime: DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
            gtmevent: "ordercomplete",
          };

          addFundsGiftCard(addFundsRequest).then(() => {
            removeSelectedWalletPaymentMethodId();
            appContext.showToast("Funds successfully added!", "", ToastType.success);

            const tagManagerArgs = {
              dataLayer,
            };

            // Clear previous ecommerce object
            TagManager.dataLayer({ dataLayer: { ecommerce: null } });

            // Push new purchase event to dataLayer
            TagManager.dataLayer(tagManagerArgs);

            if (addFundsState.ordering) {
              setSelectedPaymentMethodId(paymentMethodId);
              navigate(-2);
              return;
            }
            setShowGiftCardReloadModal(false);
            navigate("/account/myWallet");
          });
        })
        .catch((error) => showBoundary(error));
    }
  }

  const giftCardReloadModal = (
    <SheetzModal
      className="gift-card-reload-modal"
      isOpen={showGiftCardReloadModal}
      closeFunction={closeGiftCardReloadModal}
      contentLabel="Add Funds"
      onRequestClose={closeGiftCardReloadModal}
      shouldCloseOnOverlayClick={false}
      headerText="Add Funds"
      backgroundColor="green"
    >
      {fundAmount && paymentMethodSelected && (
        <ConfirmGiftCardReload
          amount={fundAmount}
          paymentMethod={paymentMethodSelected}
          closeGiftCardReload={closeGiftCardReloadModal}
          submitGiftCardReload={submitGiftCardReload}
        />
      )}
    </SheetzModal>
  );

  if (pinAuthenticated) {
    return (
      <>
        <addFundsHeaderContext.Provider
          value={{ setAmount: setAmount, amount: fundAmount } as AddFundsHeaderContextProps}
        >
          <AddFundsHeader />
        </addFundsHeaderContext.Provider>

        <div className="fund-amount-container">
          <ChoosePaymentMethod paymentMethodSelectedCallback={handleEditPaymentMethodClick} />
          <div className="button-row add-payment-method-container">
            <SheetzButton
              className="submit-button"
              transparentDark
              type="button"
              label="Add Payment Method"
              onClick={(): void => {
                navigateToPaymentMethod();
              }}
            />
          </div>
          <div className="button-row next-button">
            <SheetzButton
              className="submit-button"
              type="button"
              label="Next"
              onClick={(): void => {
                if (!paymentMethodSelected) {
                  appContext.showToast(
                    "Oh Sheetz!",
                    "Please select a payment method to continue",
                    ToastType.error
                  );
                } else {
                  setShowGiftCardReloadModal(true);
                }
              }}
            />
          </div>
        </div>
        {showGiftCardReloadModal && giftCardReloadModal}
      </>
    );
  } else {
    return (
      <>
        <Pin
          setIsAuthenticatedCallback={setPinAuthentication}
          closedWithoutAuthenticatingCallback={closedWithoutAuthenticating}
        />
      </>
    );
  }
};

export default AddFunds;
