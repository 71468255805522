import Axios, { AxiosPromise } from "axios";

import { GetRewardsResponse, PurchaseRewardRequest } from "assets/dtos/anywhere-dto";

import { LOYALTY_REWARDS, LOYALTY_REWARDS_PURCHASE } from "endpoints/loyalty.endpoints";

export function purchaseReward(request: PurchaseRewardRequest): AxiosPromise<null> {
  return Axios({
    method: "POST",
    url: LOYALTY_REWARDS_PURCHASE,
    data: request,
  });
}

export function getUserRewards(): AxiosPromise<GetRewardsResponse> {
  return Axios({
    method: "GET",
    url: LOYALTY_REWARDS,
  });
}
