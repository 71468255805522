import classNames from "classnames";
import React from "react";
import { NumericFormat } from "react-number-format";

import "./ReceiptHeader.scss";

import { OrderState } from "assets/dtos/anywhere-dto";

import SheetzBarcode from "components/Order/Receipt/SheetzBarcode/SheetzBarcode";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { getFirstName } from "util/Storage.util";
import { getDateTimeFromString } from "util/Time.util";

interface ReceiptHeaderProps {
  viewDetailsPressed: () => void;
  numberOfItems: number;
  orderTotal: number;
  pastOrder?: boolean;
  barcode?: string;
  pickupTime?: string;
  orderNumber?: number;
  orderState?: OrderState;
}

const ReceiptHeader = (props: ReceiptHeaderProps) => {
  const pickupTimeAsDate =
    props.pickupTime !== undefined ? getDateTimeFromString(props.pickupTime) : undefined;

  let alertMessage: string | undefined;
  let textColorClass = "dark-gray";
  switch (props.orderState) {
    case "PROCESSING":
      alertMessage = "Order in progress";
      break;
    case "SCHEDULED":
      alertMessage = "Order Scheduled";
      break;
    case "CANCELLED":
      alertMessage = "Order Cancelled";
      textColorClass = "red";
      break;
    case "FAILED":
      alertMessage = "Order Failed";
      textColorClass = "red";
      break;
    case "PENDING_DELIVERY":
      alertMessage = "Order awaiting delivery";
      break;
    default:
      break;
  }

  return (
    <>
      <div className="receipt-top-header bottom-rip sheetz-red">
        {props.orderState && alertMessage && (
          <p className={classNames("alert", textColorClass)}>{alertMessage}</p>
        )}
        <ResponsiveLayoutContainer>
          <FlexContainer
            className="top-content-flex-container"
            flexStyles={{ gap: "20px", justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="greeting-date">
              {props.pastOrder && (
                <>
                  <p className="date">{pickupTimeAsDate?.toFormat("MMM d, yyyy")}</p>
                  <p className="time">{pickupTimeAsDate?.toFormat("t")}</p>
                  <p className="order-number">{"#" + props.orderNumber}</p>
                </>
              )}
              {!props.pastOrder && (
                <>
                  <p className="greeting">Thanks, {getFirstName()}</p>
                  <p className="sub-greeting">That&apos;s a totally Freaky order</p>
                </>
              )}
            </div>
            <div className="order-total">
              <p className="number-of-items">
                {props.numberOfItems + (props.numberOfItems === 1 ? " item" : " items")}
              </p>
              <NumericFormat
                value={props.orderTotal}
                renderText={(formattedValue) => (
                  <p className="order-total-label">{formattedValue}</p>
                )}
                displayType="text"
                decimalScale={2}
                prefix="$"
                fixedDecimalScale
              />
              <div>
                <SheetzTextButton label="View details" onClick={props.viewDetailsPressed} />
              </div>
            </div>
          </FlexContainer>
        </ResponsiveLayoutContainer>
        {props.barcode !== undefined && (
          <div className="barcode-container">
            <SheetzBarcode barcode={props.barcode} />
          </div>
        )}
      </div>
    </>
  );
};

export default ReceiptHeader;
