export const LOYALTY_CARDS = "/loyalty/cards";
export const ACTIVE_OFFERS = "/loyalty/offers";
export const ACTIVE_AGE_RESTRICTED_OFFERS = "/loyalty/ageRestrictedOffers";
export const ACTIVE_AGE_RESTRICTED_REBATES = "/loyalty/ageRestrictedRebates";

export const UPDATE_OFFER = (offerId: number): string => {
  return "/loyalty/offers/" + offerId;
};
export const UPDATE_AGE_RESTRICTED_OFFER = (offerId?: number): string => {
  return "/loyalty/ageRestrictedOffers" + (offerId !== undefined ? "/" + offerId : "");
};
export const UPDATE_AGE_RESTRICTED_REBATE = (rebateId?: number): string => {
  return "/loyalty/ageRestrictedRebates" + (rebateId !== undefined ? "/" + rebateId : "");
};
export const UPDATE_CARD = (cardId: number): string => {
  return "/loyalty/cards/" + cardId;
};
export const LOYALTY_REWARDS = "/loyalty/rewards";
export const LOYALTY_REWARDS_PURCHASE = "/loyalty/purchaseReward";
