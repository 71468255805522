import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { useMemo } from "react";

import "./MyOrder.scss";

import {
  LoyaltyDiscountItem,
  PurchaseOrderItem,
  PurchaseOrderLoyaltyDiscount,
  PurchaseOrderSpecialDiscount,
  SpecialDiscountItem,
} from "assets/dtos/anywhere-dto";

import OrderTotals from "components/Order/OrderTotals/OrderTotals";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ItemDisplay from "components/misc/item/ItemDisplay/ItemDisplay";

import { useMediaQuery } from "hooks";

import { orderConfirmationDesktopMediaQuery } from "util/AppContext.util";
import {
  CustomizedShoppingBagItem,
  ShoppingBagCombo,
  isShoppingBagCombo,
  isShoppingBagItem,
} from "util/Bag.util";
import { OrderSession } from "util/Order.util";
import { Discount, DiscountItem, ReorderedShoppingBagItem } from "util/Reorder.util";

interface MyOrderProps {
  resetPurchaseOrder?: () => void;
  orderSession?: OrderSession;
  confirmation?: boolean;
}

const MyOrder = (props: MyOrderProps): JSX.Element | null => {
  const [useDesktopView] = useMediaQuery(orderConfirmationDesktopMediaQuery);
  const bagFeeDisplay = useMemo(findBagFee, [props.orderSession]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const purchaseOrderItems = useMemo(mapPurchaseOrderItems, [props.orderSession]);

  if (props.orderSession?.shoppingBag === undefined) {
    return null;
  }

  let loyaltyDiscountItems: DiscountItem[] = [];
  let specialDiscountItems: DiscountItem[] = [];

  if (
    props.orderSession?.purchaseOrder?.loyaltyDiscounts &&
    props.orderSession?.purchaseOrder?.loyaltyDiscounts.length > 0
  ) {
    loyaltyDiscountItems = props.orderSession.purchaseOrder.loyaltyDiscounts.map(
      (loyaltyDiscount: PurchaseOrderLoyaltyDiscount) => {
        return {
          amount: loyaltyDiscount.amount,
          lineItems: loyaltyDiscount.loyaltyDiscountItems,
          name: loyaltyDiscount.offerName,
          type: "loyaltyDiscount",
        };
      }
    );
  }

  if (
    props.orderSession?.purchaseOrder?.specialDiscounts &&
    props.orderSession?.purchaseOrder?.specialDiscounts.length > 0
  ) {
    specialDiscountItems = props.orderSession?.purchaseOrder?.specialDiscounts.map(
      (specialDiscount: PurchaseOrderSpecialDiscount) => {
        return {
          amount: specialDiscount.amount,
          lineItems: specialDiscount.specialDiscountItems,
          name: specialDiscount.receiptText ?? "",
          type: "specialDiscount",
        };
      }
    );
  }

  const discountItems: DiscountItem[] = loyaltyDiscountItems.concat(specialDiscountItems);

  function findBagFee(): JSX.Element | undefined {
    if (props.orderSession?.shoppingBag !== undefined) {
      // Create new array without combos and virtual item from the purchase order.
      const items = props.orderSession.purchaseOrder?.items.filter(
        (item: PurchaseOrderItem) => !item.comboId && !item.virtual
      );

      // Create and sort new array of shopping bag items to match item order from purchase order.
      const shoppingBagContents = [...(props.orderSession?.shoppingBag.items || [])]
        .sort(
          (
            a: CustomizedShoppingBagItem | ReorderedShoppingBagItem,
            b: CustomizedShoppingBagItem | ReorderedShoppingBagItem
          ) => (a.id < b.id ? -1 : 1)
        )
        .map((bagEntity: CustomizedShoppingBagItem | ReorderedShoppingBagItem) => {
          return bagEntity;
        });

      // If there are more items in the purchase order, we are assuming that it is a bag fee item
      // so, we pop it off the end of the array.
      if (items && items.length > shoppingBagContents.length) {
        const bag = items.pop();

        if (bag) {
          return <ItemDisplay item={bag} key={"bagFee"} sidePadding bagFee />;
        }
      }
    }

    return undefined;
  }

  /**
   * We don't have discounts per item in the shopping bag because we get them after a purchaseOrder has been generated.
   * We are using the shoppingBag to display the items and this doesn't include the lineNumber property to map them to the discounts.
   * The order of the purchaseOrder items is the same as the shopping bag, so we will use the index to extract the lineNumber.
   */
  function mapPurchaseOrderItems(): PurchaseOrderItem[] {
    if (props.orderSession?.purchaseOrder?.items) {
      // We don't want the virtual store items so, we remove them.
      const items = props.orderSession.purchaseOrder?.items
        .filter((item: PurchaseOrderItem) => !item.virtual)
        .map((item) => {
          return { ...item };
        });

      // Since combos can have multiple rows, we collapse them into one row, so it represents the correct index.
      return collapseComboItems(items);
    }

    return [];
  }

  function collapseComboItems(items: PurchaseOrderItem[]): PurchaseOrderItem[] {
    // Does item array contain a combo.
    const comboItem = items.find((item) => item.comboId);

    if (comboItem !== undefined) {
      let loyaltyDiscountAmount = 0;
      let specialDiscountAmount = 0;

      // We have to find the discounts for a combo.
      items.forEach((item) => {
        if (item.comboId === comboItem.comboId) {
          if (item.loyaltyDiscountAmount) {
            loyaltyDiscountAmount += item.loyaltyDiscountAmount;
          }

          if (item.specialDiscountAmount) {
            specialDiscountAmount += item.specialDiscountAmount;
          }
        }
      });

      comboItem.loyaltyDiscountAmount = loyaltyDiscountAmount;
      comboItem.specialDiscountAmount = specialDiscountAmount;

      // Find index of combo, so you can replace after removing other combo items.
      const index = items.findIndex((item) => item.comboId === comboItem.comboId);

      // Create new array without combo items.
      const newItems = items.filter((item) => item.comboId !== comboItem.comboId);

      // Remove comboId, so it doesn't get removed and splice back into array.
      delete comboItem["comboId"];
      newItems.splice(index, 0, comboItem);

      // Repeat for subsequent combos.
      if (newItems.find((item) => item.comboId)) {
        return collapseComboItems(newItems);
      }

      return newItems;
    }

    return items;
  }

  const shoppingBagContents = [
    ...(props.orderSession?.shoppingBag.items || []),
    ...(props.orderSession?.shoppingBag.combos || []),
  ]
    .sort(
      (
        a: CustomizedShoppingBagItem | ReorderedShoppingBagItem | ShoppingBagCombo,
        b: CustomizedShoppingBagItem | ReorderedShoppingBagItem | ShoppingBagCombo
      ) => (a.id < b.id ? -1 : 1)
    )
    .map(
      (
        bagEntity: CustomizedShoppingBagItem | ReorderedShoppingBagItem | ShoppingBagCombo,
        itemIndex
      ) => {
        if (isShoppingBagItem(bagEntity) || isShoppingBagCombo(bagEntity)) {
          const discounts: Discount[] = [];
          const purchaseOrderItem = purchaseOrderItems[itemIndex];

          discountItems.forEach((discountItem: DiscountItem) => {
            // There might be multiple line items, so we use findIndex, so we don't get duplicate results.
            const index = discountItem.lineItems.findIndex(
              (lineItem: LoyaltyDiscountItem | SpecialDiscountItem) =>
                lineItem.itemLineNumber === purchaseOrderItem.lineNumber
            );

            if (index !== -1) {
              discounts.push({ name: discountItem.name });
            }
          });

          return (
            <ItemDisplay
              discounts={discounts}
              loyaltyDiscounts={purchaseOrderItem.loyaltyDiscountAmount}
              item={bagEntity}
              key={bagEntity.id}
              sidePadding
              specialDiscounts={purchaseOrderItem.specialDiscountAmount}
            />
          );
        } else {
          // This shouldn't happen, as we only have ShoppingBagItem and ShoppingBagCombo objects... but better to check typing on both.
          throw new SheetzError("Unhandled object type in Shopping bag contents", {
            userReadableMessage: "Something went wrong with your order. Please try again.",
            primaryButton: SheetzErrorButtonType.OK,
          });
        }
      }
    );

  function resetPurchaseOrder(): void {
    props.resetPurchaseOrder?.();
  }

  return (
    <>
      {!useDesktopView && props.confirmation && (
        <div className="continue-ordering-button">
          <SheetzButton
            transparentDark
            className="continue-ordering-button"
            label="Continue Ordering"
            onClick={(): void => resetPurchaseOrder()}
          />
        </div>
      )}

      {props.orderSession.purchaseOrder && !props.confirmation && (
        <OrderTotals purchaseOrder={props.orderSession.purchaseOrder} totalSavingBar />
      )}

      <div className="my-order-container">
        {props.confirmation && <p className="my-order-header">My Order</p>}
        {shoppingBagContents}
        {bagFeeDisplay}
      </div>
    </>
  );
};

export default MyOrder;
