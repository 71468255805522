import React, { useState } from "react";

import "./BagItem.scss";

import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ItemDisplay from "components/misc/item/ItemDisplay/ItemDisplay";
import QuantitySelector from "components/misc/item/QuantitySelector/QuantitySelector";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { ShoppingBagItem } from "util/Bag.util";

interface BagItemProps extends OrderSubviewProps {
  shoppingBagItem: ShoppingBagItem;
  closeBagFn: () => void;
}

const BagItem = (props: BagItemProps) => {
  const [showEditQuantity, setShowEditQuantity] = useState<boolean>(false);
  const [showRemoveItem, setShowRemoveItem] = useState<boolean>(false);

  function updateItemQuantity(newQuantity: number): void {
    props.dispatch({
      type: "UPDATE_BAG_ITEM_QUANTITY",
      payload: [props.shoppingBagItem.id, newQuantity],
    });
    setShowEditQuantity(false);
  }

  function removeItem(): void {
    props.dispatch({
      type: "REMOVE_ITEM_FROM_BAG",
      payload: props.shoppingBagItem.id,
    });
  }

  return (
    <>
      <ItemDisplay
        closeBagFn={props.closeBagFn}
        editable
        item={props.shoppingBagItem}
        sidePadding
        showEditQuantity={setShowEditQuantity}
        showRemoveButton
        showRemoveItem={setShowRemoveItem}
      />
      <ActionSheet
        color={ActionSheetColor.red}
        title="How Many?"
        overlay={true}
        shouldDisplay={showEditQuantity}
        required={false}
        onOverlayClick={(): void => {
          setShowEditQuantity(false);
        }}
        cancelFunction={(): void => {
          setShowEditQuantity(false);
        }}
      >
        <QuantitySelector
          selectedQuantity={props.shoppingBagItem.quantity}
          setQuantity={updateItemQuantity}
        />
      </ActionSheet>
      <ActionSheet
        color={ActionSheetColor.red}
        title="Are You Sure?"
        overlay={true}
        shouldDisplay={showRemoveItem}
        required={true}
        onOverlayClick={(): void => {
          setShowRemoveItem(false);
        }}
        cancelFunction={(): void => {
          setShowRemoveItem(false);
        }}
      >
        <div className="remove-item-container">
          <div className="remove-item-header">Do you want to remove the following item?</div>
          <ItemDisplay
            closeBagFn={props.closeBagFn}
            item={props.shoppingBagItem}
            showEditQuantity={setShowEditQuantity}
            showRemoveItem={setShowRemoveItem}
          />
          <div className="remove-item-flex-container">
            <SheetzTextButton
              textDark
              label="Cancel"
              onClick={(): void => setShowRemoveItem(false)}
            />
            <SheetzButton
              className="remove-item-button"
              label="Remove"
              onClick={(): void => {
                removeItem();
                setShowRemoveItem(false);
              }}
            />
          </div>
        </div>
      </ActionSheet>
    </>
  );
};

export default BagItem;
