import classNames from "classnames";
import React, { ReactElement } from "react";
import { useErrorBoundary } from "react-error-boundary";

import "./SheetzButton.scss";

export enum ButtonColor {
  sheetzRed = "sheetz-red",
  payGreen = "pay-green",
  white = "white",
}

interface SheetzButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  smallButton?: boolean;
  borderColor?: "light-border" | "dark-border";
  buttonColor?: ButtonColor;
  transparentDark?: boolean;
  transparentLight?: boolean;
  label: string | ReactElement;
  label2?: string | ReactElement;
}

const SheetzButton = (props: SheetzButtonProps) => {
  const { showBoundary } = useErrorBoundary();

  const buttonBackgroundColor =
    !props.transparentDark && !props.transparentLight
      ? (props.buttonColor ?? ButtonColor.sheetzRed).toString()
      : "";

  const buttonBorderColor =
    !props.transparentDark && !props.transparentLight ? props.borderColor ?? "dark-border" : "";

  const {
    smallButton,
    borderColor = buttonBorderColor,
    buttonColor = buttonBackgroundColor,
    transparentDark,
    transparentLight,
    label,
    label2,
    onClick,
    ...rest
  } = props;

  // Force dark/tertiary gray buttons to be transparent (there are no solid dark/tertiary-gray buttons)
  const sheetzButtonClasses = classNames(
    "sheetz-button",
    rest.className,
    borderColor,
    buttonColor,
    {
      small: smallButton,
      disabled: rest.disabled,
      "transparent-dark": props.transparentDark,
      "transparent-light": props.transparentLight,
    }
  );

  const buttonContents =
    label !== undefined && label2 !== undefined ? (
      <>
        <span className="text-left">{label}</span>
        <span className={typeof label2 == "string" ? "text-right" : "text-right"}>{label2}</span>
      </>
    ) : (
      <>
        <span className="text-center">{label}</span>
      </>
    );
  const buttonType = "type=" + rest.type ? rest.type : "button";

  function handleClickWithErrorBoundary(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    try {
      props.onClick?.(event);
    } catch (error) {
      showBoundary(error);
    }
  }

  return (
    <button
      {...rest}
      onClick={handleClickWithErrorBoundary}
      className={sheetzButtonClasses}
      type={buttonType}
    >
      {buttonContents}
    </button>
  );
};

export default SheetzButton;
