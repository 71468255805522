import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./AutoReload.scss";

import { PaymentMethod } from "assets/dtos/anywhere-dto";

import ChoosePaymentMethod from "components/Account/MyWallet/ChoosePaymentMethod/ChoosePaymentMethod";
import ConfirmWalletActionBody from "components/Account/MyWallet/ConfirmWalletActionBody/ConfirmWalletActionBody";
import PaymentFooter from "components/Order/PaymentFooter/PaymentFooter";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { GiftCardState, getGiftCard, updateGiftCard } from "util/Payment.util";
import { removeSelectedWalletPaymentMethodId } from "util/Storage.util";

const AutoReload = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const addFundsState = location.state as GiftCardState;
  const reloadAmount = addFundsState?.amount ? addFundsState.amount : 25;
  const reloadThreshold = addFundsState?.reloadThreshold ? addFundsState.reloadThreshold : 10;
  // Payment Method - Credit Card used to reload gift card
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod>();
  const [giftCard, setGiftCard] = useState<PaymentMethod>();
  // Payment Method - Gift Card Id of gift card that is setting up autoreload
  const paymentMethodId = addFundsState?.paymentMethodId;
  const [showConfirmAutoReload, setShowConfirmAutoReload] = useState<boolean>(false);
  if (paymentMethodId && !giftCard) {
    getGiftCard(paymentMethodId).then((response) => {
      const zCard = response.data.zcard.zCard;
      if (zCard) {
        zCard.balanceThreshold = reloadThreshold;
        zCard.reloadAmount = reloadAmount;
        zCard.paymentMethodId = response.data.zcard.paymentMethodId;
        if (paymentMethodSelected?.paymentMethodId) {
          zCard.sourceCreditCardPaymentMethodId = paymentMethodSelected?.paymentMethodId;
        }
        setGiftCard(response.data.zcard);
      }
    });
  }

  function handleEditPaymentMethodClick(paymentMethod: PaymentMethod): void {
    if (!paymentMethod.creditCard?.isExpired) {
      setPaymentMethodSelected(paymentMethod);
    } else {
      navigate(location.pathname, {
        state: {
          ...addFundsState,
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: reloadAmount,
          reloadThreshold: reloadThreshold,
          returnUri: "/account/addFunds",
        },
        replace: true,
      });
      navigate("/account/myWallet", {
        state: {
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: reloadAmount,
          reloadThreshold: reloadThreshold,
          returnUri: "/account/autoReload",
        },
      });
    }
  }

  // pass the payment method to the Add Payment Method screen so when the user is finished there, they can pass the payment method back
  // this payment method Id represents the gift card that funds are being added to
  function navigateToPaymentMethod(): void {
    navigate(location.pathname, {
      state: {
        ...addFundsState,
        amount: reloadAmount,
        reloadThreshold: reloadThreshold,
        returnUri: "/account/addFunds",
      },
      replace: true,
    });
    navigate("/account/AddPaymentMethod", {
      state: {
        paymentMethodId: paymentMethodId,
        amount: reloadAmount,
        reloadThreshold: reloadThreshold,
        returnUri: "/account/autoReload",
      },
    });
  }

  function submitAutoReload(): void {
    if (giftCard && giftCard.zCard && paymentMethodSelected) {
      giftCard.zCard.autoReloadEnabled = true;
      giftCard.zCard.sourceCreditCardPaymentMethodId = paymentMethodSelected.paymentMethodId;
      updateGiftCard(giftCard.zCard).then(() => {
        removeSelectedWalletPaymentMethodId();
        appContext.showToast("Auto reload settings saved!", "", ToastType.success);
        navigate("/account/myWallet");
      });
    }
  }

  const closeAutoReloadConfirmCard = (): void => {
    setShowConfirmAutoReload(false);
  };

  const confirmAutoReloadModal = (
    <SheetzModal
      className="confirm-auto-reload-modal"
      isOpen={showConfirmAutoReload}
      closeFunction={closeAutoReloadConfirmCard}
      contentLabel="Add Auto Reload"
      onRequestClose={closeAutoReloadConfirmCard}
      shouldCloseOnOverlayClick={false}
      headerText="Add Auto Reload"
      backgroundColor="green"
    >
      {paymentMethodSelected && (
        <div className="confirm-gift-card-reload-container">
          <div className="confirm-gift-card-reload-container-header">
            <span className="header-row">
              {"You're reloading this gift card in the amount of:"}
            </span>
            <span className="amount-header header-row">{"$" + reloadAmount}</span>
          </div>
          <ConfirmWalletActionBody
            reloadAmount={reloadAmount}
            reloadThreshold={reloadThreshold}
          ></ConfirmWalletActionBody>

          <div className="confirm-gift-card-reload-footer">
            <PaymentFooter
              fetchPaymentMethods={false}
              total={reloadAmount}
              onSubmitButtonPressed={submitAutoReload}
              onChangePaymentPressed={closeAutoReloadConfirmCard}
              paymentMethod={paymentMethodSelected}
              isReloadGiftCard={true}
            ></PaymentFooter>
          </div>
        </div>
      )}
    </SheetzModal>
  );

  return (
    <>
      <div className="reload-container">
        <ChoosePaymentMethod paymentMethodSelectedCallback={handleEditPaymentMethodClick} />
        <div className="button-row add-payment-method-button">
          <SheetzButton
            className="submit-button"
            transparentDark
            type="button"
            label="Add Payment Method"
            onClick={(): void => {
              navigateToPaymentMethod();
            }}
          />
        </div>
        <div className="button-row next-button">
          <SheetzButton
            className="submit-button"
            type="button"
            label="Next"
            onClick={(): void => {
              if (!paymentMethodSelected) {
                appContext.showToast(
                  "Oh Sheetz!",
                  "Please select a payment method to continue",
                  ToastType.error
                );
              } else {
                setShowConfirmAutoReload(true);
              }
            }}
          />
        </div>
      </div>
      {showConfirmAutoReload && confirmAutoReloadModal}
    </>
  );
};

export default AutoReload;
