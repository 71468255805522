import { AutofillSuggestion, SessionToken } from "@mapbox/search-js-core";
import { useMapboxAutofill } from "@mapbox/search-js-react";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./DeliveryAddresses.scss";

import { DeliveryAddress, ItemEvent, VerifyDeliveryAddressRequest } from "assets/dtos/anywhere-dto";

import DeliveryAddressListItem from "components/Order/Delivery/DeliveryAddressListItem/DeliveryAddressListItem";
import DeliveryCurrentlyUnavailableActionSheet from "components/Order/Delivery/DeliveryCurrentlyUnavailableActionSheet/DeliveryCurrentlyUnavailableActionSheet";
import PhoneNumberConsent from "components/Order/Delivery/PhoneNumberConsent/PhoneNumberConsent";
import { OrderSubviewProps } from "components/Order/Order";
import OrderConfiguration from "components/Order/OrderConfiguration/OrderConfiguration";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import FlexContainer from "components/misc/containers/FlexContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { sheetzMapBoxAccessToken } from "util/Geolocation.util";
import { IconType } from "util/Icon.util";
import {
  deleteDeliveryAddress,
  getDeliveryEstimate,
  getDeliveryInfo,
  shouldShowPhoneNumberConsent,
  verifyAndUpdateDeliveryAddress,
} from "util/Order.util";

const DeliveryAddresses = (props: OrderSubviewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    event: ItemEvent;
    homepageBannerRedirect: string;
    redirectOnOrderFlowFinish: string;
  };
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>();
  const appContext = useContext(AppContext);
  const [showPhoneNumberConsent, setShowPhoneNumberConsent] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [isAddressDeliverable, setIsAddressDeliverable] = useState(false);

  const mapboxAutofill = useMapboxAutofill({
    accessToken: sheetzMapBoxAccessToken,
    country: "US",
    limit: 3,
    bbox: [
      [-90.011735, 24.9493],
      [-66.9326, 49.5904],
    ],
  });

  useEffect(() => {
    appContext.showLoading();

    // If navigated here (like from a back button), then delivery must be true, so set it
    props.dispatch({ type: "SET_DELIVERY", payload: true });
    fetchDeliveryInfo();

    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchDeliveryInfo(): void {
    getDeliveryInfo()
      .then((response): void => {
        setDeliveryAddresses(response.data.deliveryAddresses || []);
        props.dispatch({ type: "SET_DELIVERY_PHONE_NUMBER", payload: response.data.phoneNumber });
        props.dispatch({
          type: "SET_DELIVERY_PHONE_NUMBER_CONSENT",
          payload: response.data.consentToShareContactInfo,
        });
      })
      .finally((): void => appContext.hideLoading());
  }

  function verifyDeliveryAddress(
    suggestedAddress: AutofillSuggestion,
    originalAddress: DeliveryAddress,
    useOriginal?: boolean
  ): void {
    appContext.hideConfirmationActionSheet();
    appContext.showLoading();

    // User is keeping their original address entered, not the one provided by mapbox.
    // In this case, we do not mark the address as verified, and continue to the delivery estimate.
    if (useOriginal) {
      fetchDeliveryEstimate(originalAddress).finally(appContext.hideLoading);
      return;
    }

    const addressRequest: VerifyDeliveryAddressRequest = {
      street: suggestedAddress.address_line1 ?? "",
      unit: suggestedAddress.address_line2 ?? "",
      city: suggestedAddress.address_level2 ?? "",
      stateCode: suggestedAddress.address_level1 ?? "",
      zipCode: suggestedAddress.postcode ?? "",
      specialInstructions: originalAddress.specialInstructions ?? "",
    };

    verifyAndUpdateDeliveryAddress(originalAddress.deliveryAddressId, addressRequest)
      .then((response) => {
        fetchDeliveryEstimate(response.data).finally(appContext.hideLoading);
      })
      .catch(appContext.hideLoading);
  }

  function fetchDeliveryEstimate(deliveryAddress: DeliveryAddress): Promise<void> {
    return getDeliveryEstimate(deliveryAddress.deliveryAddressId).then((response): void => {
      // If the delivery is currently available for this address, then move on to the next step
      const deliveryEstimate = response.data.deliveryEstimate;
      if (deliveryEstimate.available) {
        props.dispatch({
          type: "SET_DELIVERY_ADDRESS_ID",
          payload: deliveryAddress.deliveryAddressId,
        });
        props.dispatch({
          type: "SET_DELIVERY_ESTIMATED_DURATION",
          payload: deliveryEstimate.estimatedNumberOfMinutesToDelivery,
        });
        props.dispatch({ type: "SET_STORE_NUMBER", payload: deliveryEstimate.storeNumber });
        props.dispatch({ type: "SET_DAYPART", payload: deliveryEstimate.dayPart });
        if (shouldShowPhoneNumberConsent(props.orderSession)) {
          setShowPhoneNumberConsent(true);
        } else {
          if (
            locationState &&
            (!!locationState.redirectOnOrderFlowFinish ||
              !!locationState.homepageBannerRedirect ||
              !!locationState.event)
          ) {
            navigate("/order/delivery/time", {
              state: locationState,
            });
          } else {
            navigate("/order/delivery/time");
          }
        }
        return;
      } else {
        setIsAddressDeliverable(
          deliveryEstimate.unavailableReasonType === "DELIVERY_NOT_CURRENTLY_AVAILABLE"
        );
        setShowActionSheet(true);
      }
    });
  }

  function deliveryAddressSelected(deliveryAddress: DeliveryAddress): void {
    if (deliveryAddress.verified) {
      fetchDeliveryEstimate(deliveryAddress);
      return;
    }

    const sessionToken = new SessionToken();
    const deliveryAddressQuery = `${deliveryAddress.street} ${
      deliveryAddress.unit ? deliveryAddress.unit + " " : ""
    }${deliveryAddress.city}, ${deliveryAddress.stateCode} ${deliveryAddress.zipCode}`;

    mapboxAutofill.suggest(deliveryAddressQuery, { sessionToken }).then((result) => {
      const suggestion: AutofillSuggestion | undefined = result.suggestions[0];

      if (!suggestion) {
        const message = (
          <>
            <p style={{ fontWeight: "normal" }}>{deliveryAddressQuery}</p>
            <p style={{ marginTop: "1rem" }}>
              This address cannot be found within our delivery area.
            </p>
            <p>Would you like to delete it?</p>
          </>
        );
        appContext.showConfirmationActionSheet(
          message,
          "Delete",
          () => {
            deleteDeliveryAddress(deliveryAddress.deliveryAddressId).then(() => {
              fetchDeliveryInfo();
              appContext.hideConfirmationActionSheet();
              appContext.showToast("Address removed!", "", ToastType.success);
            });
          },
          undefined,
          "Cancel"
        );
      } else if (suggestion.match_code.exact_match) {
        const message = (
          <>
            <p style={{ fontWeight: "normal" }}>{suggestion.place_name ?? deliveryAddressQuery}</p>
            <p style={{ marginTop: "1rem" }}>Please confirm the above address is correct.</p>
          </>
        );
        appContext.showConfirmationActionSheet(
          message,
          "Confirm",
          () => verifyDeliveryAddress(suggestion, deliveryAddress),
          undefined,
          "Cancel"
        );
      } else {
        const message = (
          <>
            <p style={{ fontWeight: "normal" }}>{deliveryAddressQuery}</p>
            <p style={{ marginTop: "1rem" }}>
              We found the following matching address to the one above. Would you like to use it
              instead?
            </p>
            <p style={{ fontWeight: "normal", marginTop: "1rem" }}>{suggestion.place_name}</p>
          </>
        );
        appContext.showConfirmationActionSheet(
          message,
          "Yes",
          () => verifyDeliveryAddress(suggestion, deliveryAddress),
          () => verifyDeliveryAddress(suggestion, deliveryAddress, true),
          "Use original"
        );
      }
    });
  }

  function closePhoneNumberConsent(): void {
    setShowPhoneNumberConsent(false);
  }

  function choosePickup(): void {
    props.dispatch({ type: "SET_DELIVERY", payload: false });
    navigate("/order/selectStore", {
      state: locationState,
    });
  }

  function chooseAnotherAddress(): void {
    setShowActionSheet(false);
  }

  const addNewAddressContainer = (
    <ButtonFooterContainer>
      <SheetzButton
        className="add-new-address"
        borderColor="light-border"
        label="Add New Address"
        onClick={(): void => {
          navigate("/order/delivery/createAddress", {
            state: locationState,
          });
        }}
      />
    </ButtonFooterContainer>
  );

  const phoneNumberConsentModal = (
    <SheetzModal
      className="phone-number-consent-modal"
      isOpen={showPhoneNumberConsent}
      closeFunction={closePhoneNumberConsent}
      contentLabel="Mobile Phone Number"
      onRequestClose={closePhoneNumberConsent}
      shouldCloseOnOverlayClick={false}
      headerText="Mobile Phone Number"
      backgroundColor="red"
    >
      <PhoneNumberConsent orderSession={props.orderSession} dispatch={props.dispatch} />
    </SheetzModal>
  );

  const addressContainerClasses = classNames("delivery-addresses-container", {
    "padding-bottom": deliveryAddresses && deliveryAddresses.length !== 0,
  });

  return (
    <FlexContainer flexStyles={{ flexDirection: "column", height: "100%" }}>
      <OrderConfiguration dispatch={props.dispatch} orderSession={props.orderSession} />

      <div className={addressContainerClasses}>
        <ResponsiveLayoutContainer>
          {deliveryAddresses && deliveryAddresses.length === 0 && (
            <div className="empty-delivery-addresses">
              <EmptyPage title="You have no saved delivery addresses" icon={IconType.home} noMargin>
                <SheetzButton
                  transparentDark
                  label="Add New Address"
                  onClick={(): void => {
                    navigate("/order/delivery/createAddress", {
                      state: locationState,
                    });
                  }}
                />
              </EmptyPage>
            </div>
          )}

          {deliveryAddresses && deliveryAddresses.length !== 0 && (
            <>
              <p className="heading">My Saved Addresses</p>

              {deliveryAddresses.map((deliveryAddress, index) => {
                return (
                  <DeliveryAddressListItem
                    key={index}
                    deliveryAddress={deliveryAddress}
                    onDeliveryAddressSelected={deliveryAddressSelected}
                  />
                );
              })}
            </>
          )}
        </ResponsiveLayoutContainer>
        {deliveryAddresses?.length !== 0 && addNewAddressContainer}
      </div>

      <div>{showPhoneNumberConsent && phoneNumberConsentModal}</div>

      <div>
        <DeliveryCurrentlyUnavailableActionSheet
          isAddressDeliverable={isAddressDeliverable}
          choosePickupCallback={choosePickup}
          chooseAnotherAddressCallback={chooseAnotherAddress}
          shouldDisplay={showActionSheet}
        />
      </div>
    </FlexContainer>
  );
};

export default DeliveryAddresses;
