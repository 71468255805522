import React from "react";
import { useNavigate } from "react-router-dom";

import "./ReorderGuide.scss";

import { Store } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { getReorderActionSheetShown, setReorderActionSheetShown } from "util/Storage.util";

interface ReorderGuideProps {
  recentStore?: Store;
}

const ReorderGuide = (props: ReorderGuideProps) => {
  const navigate = useNavigate();

  const isAlreadyViewed = !!getReorderActionSheetShown();

  function handleDefaultReorder(): void {
    setReorderActionSheetShown();
    navigate("/order/favorites", {
      state: {
        defaultReorderParams: true,
        recentStore: props.recentStore,
      },
    });
  }

  function handleChangeToDelivery(): void {
    setReorderActionSheetShown();
    navigate("/order/delivery/address", {
      state: { redirectOnOrderFlowFinish: "/order/favorites" },
    });
  }

  function handleChangeStore(): void {
    setReorderActionSheetShown();
    navigate("/order/information", {
      state: { redirectOnOrderFlowFinish: "/order/favorites" },
    });
  }

  return (
    <div className="reorder-guide">
      <p className="reorder-text">
        {isAlreadyViewed
          ? "Knock, Knock, it's Sheetz"
          : "We'll use your most recent store for even faster ordering!"}
      </p>
      {}
      {!isAlreadyViewed && (
        <p className="reorder-subtext">
          We default to in-store pickup, but you can change that. Your store is shown above
          favorites and within your shopping bag.
        </p>
      )}
      <p className="reorder-subtext">
        Want Delivery? Change to delivery and get your Sheetz Favorites Delivered!
      </p>
      <div className="reorder-buttons">
        <SheetzButton
          className="reorder-continue-button"
          label="Continue"
          onClick={handleDefaultReorder}
        />
        <SheetzButton
          className="reorder-change-button"
          transparentDark
          label="Change to Delivery"
          onClick={handleChangeToDelivery}
        />
        <SheetzButton
          className="reorder-change-button"
          transparentDark
          label="Change"
          onClick={handleChangeStore}
        />
      </div>
    </div>
  );
};

export default ReorderGuide;
