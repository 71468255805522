import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, {
  Dispatch,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./ItemCustomization.scss";

import {
  Combo,
  ItemEvent,
  MenuCategory,
  OrderType,
  PickupLocation,
  Selector,
} from "assets/dtos/anywhere-dto";
import woodBackgroundImage from "assets/images/wood_bg_cropped.jpg";
import { ReactComponent as CustomizationSwoop } from "assets/swoops/customization_swoop.svg";

import ChangeSizeButton from "components/Order/ItemCustomization/ChangeSizeButton/ChangeSizeButton";
import ComboPagination from "components/Order/ItemCustomization/Combo/ComboPagination/ComboPagination";
import { getSelectorId } from "components/Order/ItemCustomization/SelectorContainer/SelectorContainer";
import SelectorTabs from "components/Order/ItemCustomization/SelectorTabs/SelectorTabs";
import { processAutoCondimentSelector } from "components/Order/ItemCustomization/Selectors/AutoCondimentSelector.util";
import { processAutoQuantitySelector } from "components/Order/ItemCustomization/Selectors/AutoQuantitySelector.util";
import { processAutoSizeSelector } from "components/Order/ItemCustomization/Selectors/AutoSizeSelector.util";
import { processDoubleableCondimentSelector } from "components/Order/ItemCustomization/Selectors/DoubleCondimentSelector/DoubleableCondimentSelector.util";
import { processDoubleOrSwitchSelector } from "components/Order/ItemCustomization/Selectors/DoubleableSwitchSelector/DoubleOrSwitchSelector.util";
import { processExtraableCondimentSelector } from "components/Order/ItemCustomization/Selectors/ExtraableCondimentSelector/ExtraableCondimentSelector.util";
import { processItemSwitchSelector } from "components/Order/ItemCustomization/Selectors/ItemSwitchSelector/ItemSwitchSelector.util";
import { processMultiSelectCondimentSelector } from "components/Order/ItemCustomization/Selectors/MultiSelectCondimentSelector/MultiSelectCondimentSelector.util";
import { processSingleSelectCondimentSelector } from "components/Order/ItemCustomization/Selectors/SingleSelectCondimentSelector/SingleSelectCondimentSelector.util";
import { processSizeSelector } from "components/Order/ItemCustomization/Selectors/SizeSelector/SizeSelector.util";
import {
  processMultiOptionSwitchSelector,
  processNoOptionSwitchSelector,
} from "components/Order/ItemCustomization/Selectors/SwitchSelectors/SwitchSelectors.util";
import { OrderSubviewProps } from "components/Order/Order";
import { OrderItemActions } from "components/Order/OrderItemReducer";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { useMediaQuery } from "hooks";

import { calculateSavedComboPrice } from "../../../util/Price.util";
import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import {
  CustomizedShoppingBagItem,
  ShoppingBagCombo,
  isCustomizedShoppingBagItem,
} from "util/Bag.util";
import {
  DoubleOrSwitchOption,
  ExtraableOption,
  ItemCustomization as ItemCustomizationModel,
  ItemCustomizationSelections,
  ItemSwitchOption,
  MultiOptionSwitchOption,
  PortionedCondiment,
  SingleSelectOption,
  SizeSelectOption,
  calculateItemSubtotal,
  createShoppingBagItemUUID,
  findAutoSizeSelector,
  findItemSwitchSelector,
  findSizeSelector,
  getSelectorTabs,
} from "util/Customization.util";
import { getImageSrc, getImageSrcSet, imageBuckets } from "util/Image.util";
import {
  CustomizedItem,
  OrderItemState,
  getItemCustomization,
  isCustomizedItem,
} from "util/Order.util";
import { isReorderedShoppingBagItem } from "util/Reorder.util";
import { getAllTags, tagsMatch } from "util/Tag.util";

interface ProcessedSelectorResults {
  selectors: ReactElement[];
  visibleSelectors: Selector[];
  item: CustomizedItem;
  requiredSelectors?: Selector[];
}

interface ItemCustomizationProps extends OrderSubviewProps {
  combo?: Combo;
  itemCustomization?: ItemCustomizationModel;
  currentComboItemIndex?: number;
  numberOfItemsInCombo?: number;
  comboItem?: ItemCustomizationModel;
  dispatchOrderItem: React.Dispatch<OrderItemActions>;
  evergageAddToBagEvent: (itemRmiId?: number, price?: number) => void;
  evergageViewItemEvent: (
    categories?: number,
    imageUrl?: string,
    itemRmiId?: number,
    name?: string,
    price?: number
  ) => void;
  existingComboItemCustomizations?: ItemCustomizationSelections;
  goToNextComboItem?: (
    itemCustomizationSelections: ItemCustomizationSelections,
    item: CustomizedItem
  ) => void;
  goToPreviousComboItem?: (
    itemCustomizationSelections: ItemCustomizationSelections,
    item: CustomizedItem
  ) => void;
  handleItemSwitchSizeSelection: (
    itemSwitchOption?: ItemSwitchOption,
    sizeSelectOption?: SizeSelectOption
  ) => void;
  isFirstComboItem?: boolean;
  isLastComboItem?: boolean;
  orderItemState: OrderItemState;
  resetOrderItemState: () => void;
  setItemCustomizationTitle?: Dispatch<React.SetStateAction<string | undefined>>;
}

export const CustomizationSubmittedContext = createContext<boolean>(false);

const ItemCustomization = (props: ItemCustomizationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { itemCustomizationId } = useParams<{ itemCustomizationId: string }>();
  const [addToBagAttempted, setAddToBagAttempted] = useState<boolean>(false);
  const itemCustomizationState = location.state as
    | {
        bagItemId?: string;
        bagComboId?: number;
        bagComboItemIndex?: number;
        event?: ItemEvent;
        previousCategory?: MenuCategory;
        previousLocation?: string;
      }
    | undefined;
  const bagItemId = itemCustomizationState?.bagItemId;
  const bagComboId = itemCustomizationState?.bagComboId;
  const bagComboItemIndex = itemCustomizationState?.bagComboItemIndex;
  const previousLocation = itemCustomizationState?.previousLocation;
  const isEditing = !!bagItemId || !!bagComboId;
  const orderType: OrderType = props.orderSession.delivery ? "DELIVERY" : "PICKUP";
  // Delivery orders are considered "IN_STORE" for purposes of pick-up location
  const pickupLocation: PickupLocation = props.orderSession.pickupLocation ?? "IN_STORE";
  const appContext = useContext(AppContext);
  const [itemCustomization, setItemCustomization] = useState<ItemCustomizationModel | undefined>(
    props.itemCustomization
  );
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const itemCustomizationContent = useRef<HTMLDivElement | null>(null);
  const itemCustomizationSelectors = useRef<HTMLDivElement | null>(null);

  const savedCombo =
    bagComboId !== undefined
      ? props.orderSession.shoppingBag?.combos.find(
          (combo) => combo.id.toString() === bagComboId.toString()
        )
      : undefined;

  // TODO: move redirect logic to Order parent component
  if (
    (itemCustomization && itemCustomization.retailItem.retailModifiedItems === undefined) ||
    (itemCustomization && itemCustomization.retailItem.retailModifiedItems.length === 0)
  ) {
    navigate("/order/menu", { replace: true });
    // This view may be loading from a deeplink, so we need to set a timeout before attempting to show the action sheet.
    setTimeout(() => {
      appContext.showAlertActionSheet(
        "This item is currently unavailable, please try another item."
      );
    }, 1000);
  }

  // If there is a comboItem to customize, and it is different from the currently set itemCustomization, update the state value.
  if (
    props.comboItem &&
    props.comboItem.itemCustomizationId !== itemCustomization?.itemCustomizationId
  ) {
    setItemCustomization(props.comboItem);
  }

  // If a comboItem is passed in, then no call to the server is needed.
  if (props.combo) {
    setRetailItemImage(props.combo.image);
  }

  useEffect(() => {
    itemCustomizationContent?.current?.scrollTo(0, 0);

    if (itemCustomizationSelectors.current) {
      itemCustomizationSelectors.current.scrollTop = 0;
    }
  }, [bagItemId, itemCustomizationId, location]);

  // Fetch the Item Customization data - only fires once.
  useEffect(() => {
    // If a combo or itemCustomization is passed in, then no call to the server is needed.
    if (props.combo !== undefined || props.itemCustomization !== undefined) {
      // Set the retail image and header title
      setRetailItemImage(props.itemCustomization?.retailItem.image ?? props.combo?.image ?? "");

      props.setItemCustomizationTitle?.(
        props.combo?.name ?? props.itemCustomization?.retailItem.receiptText
      );
      return;
    }

    // For a delivery order, we only have the store number.
    const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;
    // TODO: For deep-linking, this may be an issue: we are now requiring a daypart which is needed for AIUs.
    if (storeNumber === undefined || !props.orderSession.dayPart || !itemCustomizationId) {
      navigate("/order");
    } else {
      appContext.showLoading();
      getItemCustomization(
        parseInt(itemCustomizationId),
        storeNumber,
        props.orderSession.dayPart,
        orderType,
        pickupLocation
      )
        .then((response): void => {
          if (
            response.data.retailItem.retailModifiedItems === undefined ||
            response.data.retailItem.retailModifiedItems.length === 0
          ) {
            navigate("/order/menu", { replace: true });
            // This view may be loading from a deeplink, so we need to set a timeout before attempting to show the action sheet.
            setTimeout(() => {
              appContext.showAlertActionSheet(
                "This item is currently unavailable, please try another item."
              );
            }, 1000);
            return;
          }

          props.setItemCustomizationTitle?.(
            props.combo?.name ?? response.data.retailItem.receiptText
          );
          if (
            ((bagItemId || bagComboId) && isEditing) ||
            props.orderSession.itemCustomizationId !== response.data.itemCustomizationId
          ) {
            props.dispatch({ type: "CLEAR_ITEM_CUSTOMIZATIONS", payload: undefined });
            props.dispatch({
              type: "SET_ITEM_CUSTOMIZATION_ID",
              payload: response.data.itemCustomizationId,
            });
          }

          setItemCustomization(response.data);

          // Set RetailItem image
          setRetailItemImage(response.data.retailItem.image);

          if (isEditing) {
            let savedCustomizations: ItemCustomizationSelections = {};

            if (bagItemId) {
              const itemBeingEdited = props.orderSession.shoppingBag?.items.find((item) => {
                return item.id.toString() === bagItemId.toString();
              });

              if (itemBeingEdited && isCustomizedShoppingBagItem(itemBeingEdited)) {
                savedCustomizations = itemBeingEdited.itemDetails.customizations || {};
                props.dispatch({
                  type: "REPLACE_ITEM_CUSTOMIZATIONS",
                  payload: savedCustomizations,
                });
              }
            } else if (bagComboId && bagComboItemIndex !== undefined && bagComboItemIndex >= 0) {
              const bagComboItem = savedCombo?.items[bagComboItemIndex];

              if (isCustomizedItem(bagComboItem)) {
                savedCustomizations = bagComboItem.customizations || {};

                props.dispatch({
                  type: "REPLACE_ITEM_CUSTOMIZATIONS",
                  payload: savedCustomizations,
                });
              }
            }
          }
        })
        .finally((): void => {
          appContext.hideLoading();
        });
    }
    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemCustomizationId, bagItemId]);

  // Register the IntersectionObserver for each selector to help with scroll synchronization. This will fire on each render.
  useEffect(() => {
    const itemCustomizationContentElement = itemCustomizationContent.current;
    let observer: IntersectionObserver;
    if (itemCustomizationContentElement) {
      const selectors = document.getElementsByClassName("item-customization-selector");
      const observerOptions: IntersectionObserverInit = {
        root: itemCustomizationContentElement,
        rootMargin: "-9% 0px -90% 0px",
      };
      const observerCallback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedTab(entry.target.id);
          }
        });
      };
      observer = new IntersectionObserver(observerCallback, observerOptions);
      Array.from(selectors).forEach((selector) => observer.observe(selector));
      return (): void => {
        observer?.disconnect();
      };
    }
  });

  const accompanyingItemUpsells =
    props.combo?.accompanyingItemUpsells ?? itemCustomization?.accompanyingItemUpsells ?? [];

  // Using useMemo() here to increase performance. We only want to recalculate selectors when the customized item changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectorResults = useMemo(processSelectors, [
    props.orderSession.itemCustomizationSelections,
    itemCustomization,
    props.currentComboItemIndex,
  ]);

  function throwUnknownSelector(): never {
    throw new SheetzError("Unable to process unknown selector.", {
      userReadableMessage: "Something went wrong within Item Customization. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      secondaybutton: SheetzErrorButtonType.CLOSE,
    });
  }

  function setRetailItemImage(retailItemImage: string): void {
    if (itemCustomizationContent.current) {
      itemCustomizationContent.current.style.backgroundImage =
        "url(" + imageBuckets.large + retailItemImage + "), url(" + woodBackgroundImage + ")";
    }
  }

  function processSelectors(): ProcessedSelectorResults {
    const item: CustomizedItem = {
      retailItem: undefined,
      retailModifiedItem: undefined,
      condiments: [],
      price: 0,
      itemCustomizationId: itemCustomizationId ?? "",
    };

    // If we don't have the ItemCustomization, selectors can't be built.
    if (!itemCustomization) {
      return { selectors: [], visibleSelectors: [], item: item };
    }

    const sizeSelector = findSizeSelector(itemCustomization.template);
    const hasAutoSizeSelector = !!findAutoSizeSelector(itemCustomization.template);
    const hasItemSwitchSelector = !!findItemSwitchSelector(itemCustomization.template);

    // Build out the base of the item first
    item.retailItem = itemCustomization.retailItem;

    /**
     * Auto-select the first RMI if there is only an item switch selector instead of an auto size selector.
     * This is needed because there are some (Saladz) customization templates that don't contain an
     * auto size selector before the item switch selector.
     */
    if (!hasAutoSizeSelector && hasItemSwitchSelector) {
      item.retailModifiedItem = itemCustomization.retailItem.retailModifiedItems[0];
    }

    /**
     * If there is a size selector, and it is configured to not display as an action sheet, and there are no current
     * customizations, then a size must be selected first.
     */
    if (
      sizeSelector &&
      !sizeSelector.displayAsActionSheet &&
      !props.orderSession.itemCustomizationSelections
    ) {
      const sizeSelectorComponent = processSizeSelector(
        sizeSelector,
        item,
        itemCustomization,
        props.dispatch,
        0
      );
      if (sizeSelectorComponent) {
        return { selectors: [sizeSelectorComponent], visibleSelectors: [], item: item };
      }
      return { selectors: [], visibleSelectors: [], item: item };
    }

    // If we don't have an auto size selector, item switch selector, or the beginning of a customization, selectors can't be built.
    if (
      !props.orderSession.itemCustomizationSelections &&
      !hasAutoSizeSelector &&
      !hasItemSwitchSelector
    ) {
      return { selectors: [], visibleSelectors: [], item: item };
    }

    const itemCustomizationSelections =
      props.orderSession.itemCustomizationSelections ?? props.existingComboItemCustomizations ?? {};

    let requiredSelectors: Selector[] | undefined;

    // TODO: Remove once all selectors are implemented.
    console.log("Generating selectors");
    const selectors: (JSX.Element | null)[] = itemCustomization.template.selectors.map(
      (selector, index): JSX.Element | null => {
        // Handle hideTags
        if (selector.hideTags?.length) {
          const accumulatedTags = getAllTags(item);
          if (tagsMatch(accumulatedTags, selector.hideTags)) {
            return null;
          }
        }

        // Handle required selectors by adding ones that don't yet have a selection to the requiredSelectors array.
        if (selector.optional === false && !itemCustomizationSelections[selector.text ?? ""]) {
          requiredSelectors = requiredSelectors ? requiredSelectors.concat([selector]) : [selector];
        }

        switch (selector.type) {
          case "AUTO_SIZE":
            processAutoSizeSelector(selector, item, itemCustomization);
            return null;
          case "SIZE":
            return processSizeSelector(
              selector,
              item,
              itemCustomization,
              props.dispatch,
              index,
              itemCustomizationSelections
            );
          case "AUTO_QUANTITY":
            processAutoQuantitySelector(selector, item);
            return null;
          case "AUTO_CONDIMENT": {
            processAutoCondimentSelector(selector, item, itemCustomization);
            return null;
          }
          case "SINGLE_SELECT_CONDIMENT": {
            return processSingleSelectCondimentSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as SingleSelectOption | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "MULTI_SELECT_CONDIMENT": {
            return processMultiSelectCondimentSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as PortionedCondiment[] | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "DOUBLEABLE_CONDIMENT": {
            return processDoubleableCondimentSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as ExtraableOption | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "EXTRAABLE_CONDIMENT": {
            return processExtraableCondimentSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as ExtraableOption[] | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "NO_OPTION_SWITCH": {
            return processNoOptionSwitchSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as boolean | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "MULTI_OPTION_SWITCH": {
            return processMultiOptionSwitchSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as
                | MultiOptionSwitchOption
                | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "ITEM_SWITCH": {
            return processItemSwitchSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as ItemSwitchOption | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
          case "INLINE_SUB": {
            throw new SheetzError("Inline Sub Selector found as top-level selector!", {
              userReadableMessage: "Looks like we've hit a snag. Please try again.",
              primaryButton: SheetzErrorButtonType.OK,
            });
          }
          case "DOUBLE_OR_SWITCH": {
            return processDoubleOrSwitchSelector(
              selector,
              itemCustomizationSelections[selector.text ?? ""] as DoubleOrSwitchOption | undefined,
              item,
              itemCustomization,
              props.dispatch,
              index
            );
          }
        }
        return throwUnknownSelector();
      }
    );

    // Set RetailItem image again, as it might have changed
    if (item.retailItem) {
      setRetailItemImage(props.combo?.image ?? item.retailItem.image);
    }

    // Calculate price
    item.price = calculateItemSubtotal(item);

    item.customizations = itemCustomizationSelections;

    // TODO: Remove once all selectors are implemented, or keep and implement more robust debug logging.
    console.log("Built item: ");
    console.log(item);

    // Filter out any elements that are null, and build a list of visible selectors for the tab header to consume.
    const visibleSelectors: Selector[] = [];
    const filteredSelectors = selectors.filter((selector, index) => {
      if (selector !== null) {
        visibleSelectors.push(itemCustomization.template.selectors[index]);
        return true;
      }
      return false;
    }) as JSX.Element[];
    return {
      selectors: filteredSelectors,
      visibleSelectors: visibleSelectors,
      item: item,
      requiredSelectors: requiredSelectors,
    };
  }

  const tabs = itemCustomization ? getSelectorTabs(selectorResults.visibleSelectors) : [];
  // Start out by selecting the first tab, when none is selected
  if (tabs.length > 0 && selectedTab === "") {
    setSelectedTab(tabs[0].id);
  }

  // TODO: Can this method be moved into a util or the ChangeSizeButton component itself?
  function createChangeSizeButton(): React.ReactNode {
    if (!itemCustomization) {
      return null;
    }

    const sizeSelector = findSizeSelector(itemCustomization.template);
    const itemSwitchSelector = findItemSwitchSelector(itemCustomization.template);

    /**
     * If the template has no size selector and no item switch selector, or has a
     * size/item switch selector with `displayAsActionSheet=false` then no button is needed.
     */
    if (!sizeSelector && !itemSwitchSelector) {
      return null;
    }
    if (
      (sizeSelector && !sizeSelector.displayAsActionSheet) ||
      (itemSwitchSelector && !itemSwitchSelector.displayAsActionSheet)
    ) {
      return null;
    }

    // Get the pre-selected option for either the size or item switch selectors, if they exist.
    let selectedSizeOption: SizeSelectOption | undefined;
    let selectedItemSwitchOption: ItemSwitchOption | undefined;

    const customizationSelections = props.orderSession.itemCustomizationSelections;

    if (customizationSelections) {
      if (sizeSelector) {
        selectedSizeOption = customizationSelections[sizeSelector.text ?? ""] as SizeSelectOption;
      } else if (itemSwitchSelector) {
        selectedItemSwitchOption = customizationSelections[
          itemSwitchSelector.text ?? ""
        ] as ItemSwitchOption;
      }
    }

    return (
      <ChangeSizeButton
        selector={(sizeSelector ?? itemSwitchSelector) as Selector}
        itemCustomization={itemCustomization}
        selectedSizeOption={selectedSizeOption}
        selectedItemSwitchOption={selectedItemSwitchOption}
        dispatch={props.dispatch}
      />
    );
  }

  function createItemDetails(): React.ReactNode {
    if (!selectorResults.item.retailModifiedItem && !props.combo) {
      return null;
    } else {
      let title = "My ";

      if (props.combo) {
        title = title + props.combo.name + " Combo";
      } else {
        title = title + selectorResults.item.retailModifiedItem?.receiptText;
      }

      let price = "$";

      if (props.combo) {
        price = price + props.combo.startsAtPrice.toFixed(2);
      } else if (savedCombo) {
        price = price + savedCombo.comboDetails.startsAtPrice.toFixed(2);
      } else {
        price = price + selectorResults.item.retailModifiedItem?.startsAtPrice?.toFixed(2);
      }

      const cals = props.combo
        ? props.combo.nutrition?.calories ?? 0
        : selectorResults.item.retailModifiedItem?.nutrition?.calories ?? 0;

      return (
        <div className="item-details">
          <p className="item-title">{title}</p>
          <div className="item-price-nutrition">
            <p>Starts at</p>
            <p className="price">{price}</p>
            <p>{cals + " Cals"}</p>
          </div>
        </div>
      );
    }
  }

  function createComboItemBanner(): React.ReactNode {
    if (
      !props.combo ||
      props.currentComboItemIndex === undefined ||
      props.numberOfItemsInCombo === undefined
    ) {
      return <></>;
    } else {
      const image = itemCustomization?.retailItem.image;
      const itemName = itemCustomization?.retailItem.receiptText || "Item";
      return (
        <>
          <ComboPagination
            currentComboItemIndex={props.currentComboItemIndex}
            numberOfItemsInCombo={props.numberOfItemsInCombo}
          />
          <div className="combo-item-banner">
            <img src={getImageSrc(image)} srcSet={getImageSrcSet(image)} sizes="30vw" alt="Item" />
            <p>Build my {itemName}</p>
          </div>
        </>
      );
    }
  }

  function calculatePrice(): number {
    let price = selectorResults.item.price;

    if (bagComboId && bagComboItemIndex !== undefined && bagComboItemIndex >= 0 && savedCombo) {
      const bagComboItem = savedCombo?.items[bagComboItemIndex];
      const comboPrice = calculateSavedComboPrice(savedCombo, bagComboItemIndex);

      price = comboPrice + selectorResults.item.price + (bagComboItem.discount ?? 0);
    }

    return price;
  }

  function createFooter(): React.ReactNode {
    // Don't show any footer if there hasn't been a size/RMI selected yet.
    if (!selectorResults.item.retailModifiedItem) {
      return <></>;
    }
    if (props.combo) {
      return (
        <>
          {!props.isFirstComboItem && (
            <SheetzButton
              className="previous-item-button"
              transparentDark
              label="Previous Item"
              onClick={() => itemButtonPressed()}
            />
          )}
          {!props.isLastComboItem && (
            <SheetzButton
              className="next-item-button"
              label="Next Item"
              onClick={() => itemButtonPressed(true)}
            />
          )}
          {props.isLastComboItem && (
            <SheetzButton
              className="add-to-bag-button"
              label="Add to Bag"
              onClick={handleItemCompletion}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {useDesktopView && (
            <div className="subtotal-container">
              <span className="subtotal-label">Item Subtotal: </span>
              <span className="subtotal">{calculatePrice().toFixed(2)}</span>
            </div>
          )}
          <SheetzButton
            className="add-to-bag-button"
            label={isEditing ? "Save to Bag" : "Add to Bag"}
            label2={!useDesktopView ? calculatePrice().toFixed(2) : undefined}
            onClick={handleItemCompletion}
          />
        </>
      );
    }
  }

  // Check for selectors that are required but don't yet have a selection.
  function areSelectionsRequired(): boolean {
    if (selectorResults.requiredSelectors && selectorResults.requiredSelectors.length > 0) {
      const selectorElement = document.getElementById(
        getSelectorId(selectorResults.requiredSelectors[0])
      );
      selectorElement?.scrollIntoView();
      const optionsText = selectorResults.requiredSelectors.map(
        (selector) => " " + selector.shortText
      );
      appContext.showToast(
        "Selections Required",
        "The following options require a selection before continuing: " + optionsText,
        ToastType.error
      );
      return true;
    }
    return false;
  }

  function handleItemCompletion(): void {
    setAddToBagAttempted(true);

    if (areSelectionsRequired()) {
      return;
    }

    isEditing ? dispatchEditingCustomizations() : addComboOrItemToBag();
  }

  function itemButtonPressed(nextItem?: boolean): void {
    setAddToBagAttempted(true);

    if (areSelectionsRequired()) {
      return;
    }

    /**
     * Reset this value since moving from one step in a combo to another doesn't actually
     * destroy the ItemCustomization component, but simply re-renders it for the next step.
     * Adding setTimeout so scrolling in useEffect works.
     */
    setAddToBagAttempted(false);

    /**
     * If a user comes back to an item in the combo and makes no further selections,
     * `props.orderSession.itemCustomizationSelections` will be undefined. In this case,
     * use the existing selections passed in as the source of truth.
     */

    const selections =
      props.orderSession.itemCustomizationSelections ?? props.existingComboItemCustomizations ?? {};

    nextItem
      ? props.goToNextComboItem?.(selections, selectorResults.item)
      : props.goToPreviousComboItem?.(selections, selectorResults.item);
  }

  function createNewShoppingBagItem(): CustomizedShoppingBagItem {
    const itemDetails = selectorResults.item;

    itemDetails.event = props.orderItemState.itemEvent
      ? props.orderItemState.itemEvent
      : itemCustomizationState?.event;

    return {
      id: createShoppingBagItemUUID(),
      itemDetails: selectorResults.item,
      itemCustomizationId: itemCustomizationId ?? "",
      quantity: selectorResults.item.quantity || 1,
    };
  }

  async function dispatchComboEvents(newBagCombo: ShoppingBagCombo): Promise<void> {
    for (const comboItem of newBagCombo.items) {
      await dispatchComboEventsWait(1000);
      const imageUrl = isReorderedShoppingBagItem(comboItem)
        ? comboItem.image
        : comboItem.retailModifiedItem?.image;

      const itemRmiId = isReorderedShoppingBagItem(comboItem)
        ? comboItem.retailModifiedItemId
        : comboItem.retailModifiedItem?.retailModifiedItemId;

      const name = isReorderedShoppingBagItem(comboItem)
        ? comboItem.receiptText
        : comboItem.retailModifiedItem?.receiptText;

      const price = isReorderedShoppingBagItem(comboItem)
        ? comboItem.price
        : comboItem.retailModifiedItem?.price;

      // Dispatch evergage addToBag event
      props.evergageAddToBagEvent(itemRmiId, price);

      // Dispatch evergage viewItem event
      dispatchViewMenuItemEvent(
        itemCustomizationState?.previousCategory?.menuCategoryId,
        imageUrl,
        itemRmiId,
        name,
        price
      );
    }
  }

  function dispatchComboEventsWait(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  function dispatchViewMenuItemEvent(
    categories?: number,
    imageUrl?: string,
    itemRmiId?: number,
    name?: string,
    price?: number
  ): void {
    props.evergageViewItemEvent(categories, imageUrl, itemRmiId, name, price);
  }

  function addComboOrItemToBag(): void {
    let url =
      itemCustomizationState?.previousCategory?.menuCategoryId !== undefined
        ? "/order/menu/" + itemCustomizationState?.previousCategory?.menuCategoryId
        : "/order/menu/";

    if (props.orderItemState.navigate) {
      url = props.orderItemState.navigate;
    } else {
      props.dispatchOrderItem({
        type: "SET_NAVIGATE",
        payload: {
          navigate: url,
        },
      });
    }

    if (
      props.combo &&
      props.orderSession.customizedComboItems &&
      props.orderSession.currentComboItemIndex
    ) {
      // Add the item itself to the list of a combo's items.
      const comboItems = [...props.orderSession.customizedComboItems];
      comboItems[props.orderSession.currentComboItemIndex] = selectorResults.item;

      if (itemCustomizationState?.event) {
        comboItems.map((comboItem) => (comboItem.event = itemCustomizationState.event));
      }

      const newBagCombo: ShoppingBagCombo = {
        id: createShoppingBagItemUUID(),
        items: comboItems,
        comboDetails: props.combo,
        quantity: 1,
      };

      // Add the generated shopping bag entity ID to each combo item.
      newBagCombo.items.forEach((comboItem) => (comboItem.comboId = newBagCombo.id));

      void dispatchComboEvents(newBagCombo);

      props.dispatch({ type: "ADD_COMBO_TO_BAG", payload: newBagCombo });

      props.resetOrderItemState();
    } else {
      const newShoppingBagItem = createNewShoppingBagItem();
      const rmiDetails = newShoppingBagItem.itemDetails.retailModifiedItem;

      props.dispatch({ type: "ADD_ITEM_TO_BAG", payload: newShoppingBagItem });

      // Dispatch evergage addToBag event
      props.evergageAddToBagEvent(
        newShoppingBagItem.itemDetails.retailModifiedItem?.retailModifiedItemId,
        newShoppingBagItem.itemDetails.retailModifiedItem?.price
      );

      // Dispatch evergage viewItem event
      dispatchViewMenuItemEvent(
        itemCustomizationState?.event?.soldFromCategoryPath?.slice(-1)[0],
        rmiDetails?.image,
        rmiDetails?.retailModifiedItemId,
        rmiDetails?.receiptText,
        rmiDetails?.price
      );

      props.resetOrderItemState();
    }

    if (accompanyingItemUpsells.length > 0 && !isEditing) {
      props.dispatchOrderItem({
        type: "SET_AIU_INITIAL_STATE",
        payload: {
          accompanyingItemUpsells,
          itemCustomizationId: itemCustomizationId ? parseInt(itemCustomizationId) : undefined,
          menuCategory: itemCustomizationState?.previousCategory,
          showAIUActionSheet: true,
        },
      });

      props.dispatchOrderItem({ type: "SET_NAVIGATE", payload: url });

      return;
    }

    navigate(url, { replace: true });
    setTimeout(() => appContext.showAddToBagPopup(false), 600);
  }

  function dispatchEditingCustomizations(): void {
    const redirectLocation = previousLocation ?? "/order/menu";

    if (bagItemId) {
      const previouslySavedItem = props.orderSession.shoppingBag?.items.find((item) => {
        return item.id.toString() === bagItemId?.toString();
      });

      if (previouslySavedItem && isCustomizedShoppingBagItem(previouslySavedItem)) {
        const updatedItem: CustomizedShoppingBagItem = createNewShoppingBagItem();

        updatedItem.id = previouslySavedItem.id;
        updatedItem.quantity = previouslySavedItem.quantity;
        updatedItem.itemDetails.quantity = previouslySavedItem.quantity;

        props.dispatch({ type: "SAVE_ITEM_TO_BAG", payload: updatedItem });

        setTimeout(() => appContext.showAddToBagPopup(true), 600);

        navigate(redirectLocation, { replace: true });
      } else {
        throw new SheetzError("Item is not in bag or not editable, unable to save item.", {
          userReadableMessage:
            "This item is not customizable. Please remove it and try another item.",
          primaryButton: SheetzErrorButtonType.SELECT_NEW_ITEM,
          secondaybutton: SheetzErrorButtonType.CLOSE,
        });
      }
    } else if (bagComboId && bagComboItemIndex !== undefined && bagComboItemIndex >= 0) {
      // Add the generated shopping bag entity ID to the combo item.
      selectorResults.item.comboId = bagComboId;
      selectorResults.item.event = itemCustomizationState?.event;

      props.dispatch({
        type: "SAVE_COMBO_ITEM_TO_BAG",
        payload: [bagComboId, bagComboItemIndex, selectorResults.item],
      });

      navigate(redirectLocation, { state: { editComboId: bagComboId }, replace: true });
    }
  }

  return (
    <CustomizationSubmittedContext.Provider value={addToBagAttempted}>
      {itemCustomization !== undefined && (
        <div className="item-customization" id="item-customization-ordering">
          {!useDesktopView && <SelectorTabs tabs={tabs} selectedTab={selectedTab} />}
          <div className="item-customization-content" ref={itemCustomizationContent}>
            <div className="item-details-container">
              {useDesktopView ? (
                <div className="item-customization-image">
                  <img
                    src={getImageSrc(
                      props.combo?.image ??
                        selectorResults.item.retailItem?.image ??
                        itemCustomization?.retailItem.image
                    )}
                    srcSet={getImageSrcSet(
                      props.combo?.image ??
                        selectorResults.item.retailItem?.image ??
                        itemCustomization?.retailItem.image
                    )}
                    sizes="25vw"
                    alt="Item Preview"
                  />
                  <CustomizationSwoop className="customization-swoop" />
                </div>
              ) : (
                <CustomizationSwoop className="customization-swoop" />
              )}
              {createItemDetails()}
              {createChangeSizeButton()}
              {createComboItemBanner()}
              <div className="divider-container">
                {useDesktopView ? <SelectorTabs tabs={tabs} selectedTab={selectedTab} /> : <></>}
              </div>
            </div>
            <div className="item-customization-selectors" ref={itemCustomizationSelectors}>
              {selectorResults.selectors}
            </div>
            <div className="item-customization-footer">{createFooter()}</div>
          </div>
        </div>
      )}
    </CustomizationSubmittedContext.Provider>
  );
};

export default ItemCustomization;
