import React, { ReactNode, useState } from "react";

import "./ChangeSizeButton.scss";

import { Selector } from "assets/dtos/anywhere-dto";

import ItemSwitchSelector from "components/Order/ItemCustomization/Selectors/ItemSwitchSelector/ItemSwitchSelector";
import { deriveItemSwitches } from "components/Order/ItemCustomization/Selectors/ItemSwitchSelector/ItemSwitchSelector.util";
import SizeSelector from "components/Order/ItemCustomization/Selectors/SizeSelector/SizeSelector";
import { deriveSizeOptions } from "components/Order/ItemCustomization/Selectors/SizeSelector/SizeSelector.util";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { ItemCustomization, ItemSwitchOption, SizeSelectOption } from "util/Customization.util";

interface ChangeSizeButtonProps {
  selector: Selector;
  itemCustomization: ItemCustomization;
  selectedSizeOption?: SizeSelectOption;
  selectedItemSwitchOption?: ItemSwitchOption;
  dispatch: React.Dispatch<OrderSessionAction>;
}

const ChangeSizeButton = (props: ChangeSizeButtonProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showSelectorActionSheet, setShowSelectorActionSheet] = useState(false);

  function getSizeSelector(): ReactNode {
    if (!props.selector.options) {
      return null;
    }

    const sizeSelectOptions = deriveSizeOptions(props.selector.options, props.itemCustomization);
    if (!sizeSelectOptions.length) {
      return null;
    }

    const onSizeSelectOptionSelected = (sizeSelectOption: SizeSelectOption): void => {
      setShowSelectorActionSheet(false);
      props.dispatch({
        type: "RESET_ITEM_CUSTOMIZATIONS",
        payload: [props.selector.text ?? "", sizeSelectOption],
      });
    };

    return (
      <SizeSelector
        selector={props.selector}
        sizeSelectOptions={sizeSelectOptions}
        itemCustomization={props.itemCustomization}
        onSizeOptionSelected={onSizeSelectOptionSelected}
      />
    );
  }

  function getItemSwitchSelector(): ReactNode {
    if (!props.selector.options) {
      return null;
    }

    const itemSwitchOptions = deriveItemSwitches(
      props.selector,
      props.selector.options,
      props.itemCustomization
    );
    if (!itemSwitchOptions.length) {
      return null;
    }

    const onItemSwitchSelected = (itemSwitchOption: ItemSwitchOption): void => {
      setShowSelectorActionSheet(false);
      props.dispatch({
        type: "RESET_ITEM_CUSTOMIZATIONS",
        payload: [props.selector.text ?? "", itemSwitchOption],
      });
    };

    return (
      <ItemSwitchSelector
        selector={props.selector}
        itemSwitchOptions={itemSwitchOptions}
        onItemSwitchSelected={onItemSwitchSelected}
      />
    );
  }

  return (
    <>
      <div className="change-size-container">
        <button
          className="change-size-button"
          aria-label="Change Size"
          onClick={(): void => setShowWarning(true)}
        >
          <p className="size-description">
            {props.selectedSizeOption?.option.text ||
              props.selectedItemSwitchOption?.option.text ||
              "Choose a Size or Style"}
          </p>
          <p className="change-label">
            {props.selectedSizeOption || props.selectedItemSwitchOption ? "Change" : "Choose"}
          </p>
        </button>
      </div>

      <ActionSheet
        color={ActionSheetColor.error}
        title="SON OF A SHMISCUIT!"
        text="Changing your size will remove any customizations in place!"
        overlay={true}
        shouldDisplay={showWarning}
        required
        onOverlayClick={(): void => {
          setShowWarning(false);
        }}
      >
        <div className="warning-flex-container">
          <SheetzTextButton
            textDark
            label="No, Thanks"
            onClick={(): void => setShowWarning(false)}
          />
          <SheetzButton
            className="continue-button"
            label="Continue"
            onClick={(): void => {
              setShowWarning(false);
              setShowSelectorActionSheet(true);
            }}
          />
        </div>
      </ActionSheet>

      <ActionSheet
        color={ActionSheetColor.red}
        title={props.selector.text ?? ""}
        overlay={true}
        shouldDisplay={showSelectorActionSheet}
        cancelFunction={(): void => {
          setShowSelectorActionSheet(false);
        }}
        onOverlayClick={(): void => {
          setShowSelectorActionSheet(false);
        }}
      >
        {props.selector.type === "SIZE" && getSizeSelector()}
        {props.selector.type === "ITEM_SWITCH" && getItemSwitchSelector()}
      </ActionSheet>
    </>
  );
};

export default ChangeSizeButton;
