import React from "react";
import { useNavigate } from "react-router-dom";

import "./RestrictedItemActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

interface RestrictedItemActionSheetProps {
  show: boolean;
  errorText?: string;
  setShowRestrictedItemErrorActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
}

const RestrictedItemActionSheet = (props: RestrictedItemActionSheetProps) => {
  const navigate = useNavigate();

  return (
    <ActionSheet
      color={ActionSheetColor.error}
      title="Oh Sheetz!"
      overlay={true}
      shouldDisplay={props.show}
    >
      <p className="restricted-item-error-text">{props.errorText}</p>
      <div className="restricted-item-options-flex-container">
        <SheetzButton
          transparentDark
          label="Edit Bag"
          onClick={(): void => navigate("/order/menu", { state: { showBag: true } })}
        />
        <SheetzTextButton
          textDark
          label="Close"
          onClick={(): void => props.setShowRestrictedItemErrorActionSheet(false)}
        />
      </div>
    </ActionSheet>
  );
};

export default RestrictedItemActionSheet;
