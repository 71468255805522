import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./CreatePin.scss";

import { ReactComponent as IconCircle } from "assets/icons/loader_circle.svg";

import { PinFormValues } from "components/Account/MyWallet/Pin/PinEntry/PinEntry";
import PinInput from "components/Account/MyWallet/Pin/PinInput/PinInput";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { IconType, getIcon } from "util/Icon.util";
import { PinStatus, validatePin } from "util/Pin.util";

interface ChecklistIndicatorProps {
  status?: boolean;
}

interface SavePinProps {
  closeCreatePinCallback?: () => void;
  editPin?: boolean;
  resetPin?: boolean;
  savePinCallback: (pin: string) => void;
  serverSaveError?: boolean;
  serverValidationError?: string;
}
const CreatePin = (props: SavePinProps) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [pinStatus, setPinStatus] = useState<PinStatus>({
    valid: false,
  });
  const [pinSaveServerError, setPinSaveServerError] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (props.serverValidationError) {
      setErrorMessage(props.serverValidationError);
      setHasErrors(true);
      setStep(1);
    }
  }, [props.serverValidationError]);

  useEffect(() => {
    if (props.serverSaveError) {
      setPinSaveServerError(true);
    }
  }, [props.serverSaveError]);

  const initialValues = {
    pinEntryOne: "",
    pinEntryTwo: "",
    pinEntryThree: "",
    pinEntryFour: "",
  };

  const validationSchema = Yup.object({
    pinEntryOne: Yup.string().required(""),
    pinEntryTwo: Yup.string().required(""),
    pinEntryThree: Yup.string().required(""),
    pinEntryFour: Yup.string().required(""),
  });

  function onClickBack(): void {
    if (step === 1) {
      props.closeCreatePinCallback ? props.closeCreatePinCallback() : navigate(-1);
    } else {
      setStep(1);
    }
  }

  function verifyPin(value: string, resetForm: () => void): void {
    const status: PinStatus = step === 1 ? validatePin(value) : validatePin(pin, value);

    setPinStatus(status);

    if (status.valid) {
      if (step === 1) {
        setPin(value);
        resetForm();
        setStep(2);
        setErrorMessage("");
      } else {
        // using setTimeout so user can see pins match validation complete
        setTimeout(() => props.savePinCallback(pin), 500);
      }
    }
  }

  const IndicatorIcon = (props: ChecklistIndicatorProps) => {
    const indicatorCircleClasses = classNames("indicator-circle", {
      fail: props.status === true,
      success: props.status === false,
      "": props.status === undefined,
    });

    return (
      <span className={"checklist-indicator "}>
        <IconCircle className={indicatorCircleClasses} />
        {props.status === false && getIcon(IconType.check, "indicator-icon")}
        {props.status === true && getIcon(IconType.x, "indicator-icon")}
      </span>
    );
  };

  const pinServerErrorActionSheet = (
    <ActionSheet
      color={ActionSheetColor.error}
      title="OH SHEETZ!"
      text={props.editPin || props.resetPin ? "UNABLE TO CHANGE PIN!" : "UNABLE TO CREATE PIN!"}
      shouldDisplay={pinSaveServerError}
      overlay={true}
      required
    >
      <div className="pin-creation-error-action-sheet-container">
        <div className="pin-create-error-message">
          Error code 314 - Please contact Sheetz Customer service for additional assistance:
          1-800-765-4686
        </div>
        <div className="button-row">
          <SheetzButton
            className="submit-button"
            type="button"
            onClick={(): void => setPinSaveServerError(false)}
            label="Continue"
          />
        </div>
      </div>
    </ActionSheet>
  );

  return (
    <>
      <div className="create-pin-container">
        {!props.editPin && !props.resetPin && (
          <div className="create-pin-header">
            <div className="security-message">
              <div className="security-message-padding">
                {"We're making your payments even more secure."}
              </div>
              <div>{"You'll use this PIN to access Sheetz Pay."}</div>
            </div>
          </div>
        )}
        {
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values: PinFormValues, { resetForm }): void => {
              const value =
                values.pinEntryOne +
                values.pinEntryTwo +
                values.pinEntryThree +
                values.pinEntryFour;

              verifyPin(value, resetForm);
            }}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<PinFormValues>) => (
              <form>
                <div className="create-pin-body">
                  <div className="confirm-your-pin-label">
                    {(props.editPin || props.resetPin) && (
                      <p className="reset-pin-text">
                        {props.editPin ? "Editing" : "Resetting"} your PIN will update your PIN on
                        all devices and clear all of your payment methods. If you have biometrics
                        (FaceID, TouchId, etc.), you will be prompted to re-enter your PIN.
                      </p>
                    )}

                    <div className="pin-validations">
                      <p>PIN Requirements</p>
                      <div className="pin-validation">
                        <IndicatorIcon status={pinStatus.repeating} />
                        Do not use 4 of the same numbers (0000)
                      </div>
                      <div className="pin-validation">
                        <IndicatorIcon status={pinStatus.ascending || pinStatus.descending} />
                        Do not use 4 sequential numbers (1234, 4321)
                      </div>
                      <div className="pin-validation">
                        <IndicatorIcon status={pinStatus.vertical} />
                        Do not use 4 vertical keypad numbers (0852, 2580)
                      </div>
                      <div className="pin-validation">
                        <IndicatorIcon status={pinStatus.matching} />
                        PINs match
                      </div>
                    </div>
                  </div>
                  <div className="confirm-your-pin-label step-text">
                    <h3>{step === 1 ? "Step 1" : "Step 2"}</h3>
                    {step === 1 ? "Enter your new" : "Confirm your new"}{" "}
                    <span className="four-digit-label">4-digit PIN.</span>
                  </div>
                  {hasErrors && (
                    <div className="error-message">
                      <span>{errorMessage}</span>
                    </div>
                  )}
                  <PinInput />
                  <div className="button-row">
                    <div className="pin-buttons">
                      <button
                        type="button"
                        className="pin-back-button"
                        onClick={(): void => onClickBack()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="pin-buttons">
                      <div className="pin-continue-button">
                        <SheetzButton
                          className="submit-button"
                          type="button"
                          onClick={(): void => {
                            formikProps.handleSubmit();
                          }}
                          label={step === 1 ? "Continue" : "Save PIN"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        }
      </div>
      <div>{pinSaveServerError && pinServerErrorActionSheet}</div>
    </>
  );
};

export default CreatePin;
