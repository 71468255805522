import classNames from "classnames";
import React, { Dispatch, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";

import "./ItemDisplay.scss";

import { PurchaseOrderItem } from "assets/dtos/anywhere-dto";

import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import DiscountDisplay from "components/misc/item/DiscountDisplay/DiscountDisplay";
import ItemImageTextDisplay from "components/misc/item/ItemDisplay/ItemImageTextDisplay/ItemImageTextDisplay";
import ItemQuantityDisplay from "components/misc/item/ItemQuantityDisplay/ItemQuantityDisplay";
import PriceDisplay from "components/misc/pricing/PriceDisplay/PriceDisplay";

import {
  ShoppingBagCombo,
  ShoppingBagItem,
  calculateComboPrice,
  isCustomizedShoppingBagItem,
  isShoppingBagCombo,
  isShoppingBagItem,
} from "util/Bag.util";
import { buildCondimentListString } from "util/Customization.util";
import { CustomizedItem, isCustomizedItem } from "util/Order.util";
import { calculateItemDisplayTotal } from "util/Price.util";
import { Discount, ReorderedShoppingBagItem } from "util/Reorder.util";

interface ItemDisplayProps {
  bagFee?: boolean;
  closeBagFn?: () => void;
  discounts?: Discount[];
  editable?: boolean;
  editCombo?: () => void;
  loyaltyDiscounts?: number;
  item: ShoppingBagCombo | ShoppingBagItem | PurchaseOrderItem;
  showEditQuantity?: Dispatch<SetStateAction<boolean>>;
  showRemoveButton?: boolean;
  showRemoveItem?: Dispatch<SetStateAction<boolean>>;
  specialDiscounts?: number;
  sidePadding?: boolean;
}

const ItemDisplay = (props: ItemDisplayProps) => {
  const location = useLocation();
  const item = props.item;
  const itemDisplayClassNames = classNames("item-display", {
    "side-padding": props.sidePadding,
  });

  let combo = false;
  let customizable = false;
  let discount: number | undefined;
  let image: string | undefined;
  let originalPrice: number | undefined;
  let title: string | undefined;
  let total = calculateItemDisplayTotal(item as ShoppingBagItem);

  if (isShoppingBagCombo(item)) {
    combo = true;
    customizable = true;
    discount = 0;
    image = item.comboDetails.image;
    title = item.comboDetails.name;
    total = calculateComboPrice(props.item as ShoppingBagCombo);
    originalPrice = calculateComboPrice(props.item as ShoppingBagCombo, true);

    if (props.loyaltyDiscounts) {
      discount += props.loyaltyDiscounts;
    }

    if (props.specialDiscounts) {
      discount += props.specialDiscounts;
    }
  } else if (isCustomizedShoppingBagItem(item)) {
    customizable = item.itemDetails.customizations !== undefined;
    discount = item.itemDetails.discount ?? 0;
    image = item.itemDetails.retailItem?.image;
    originalPrice = item.itemDetails.price * item.quantity;
    title = item.itemDetails.retailModifiedItem?.receiptText;

    if (props.loyaltyDiscounts) {
      discount += props.loyaltyDiscounts;
    }
  } else if (isShoppingBagItem(item)) {
    discount = item.discount ?? 0;
    image = item.image;
    originalPrice = item.price ? item.price * item.quantity : item.price;
    title = item.receiptText;

    if (props.loyaltyDiscounts) {
      discount += props.loyaltyDiscounts;
    }
  } else if (props.bagFee) {
    discount = 0;
    originalPrice = item.price;
    title = item.receiptText;
  }

  if (props.loyaltyDiscounts) {
    total = total + props.loyaltyDiscounts;
  }

  function buildCondimentList(): string | null {
    return buildCondimentListString(props.item as ShoppingBagItem);
  }

  function buildItemList(): string | null {
    const combo = item as ShoppingBagCombo;

    if (combo.items.length === 0) {
      return null;
    }

    let items = "";

    combo.items.forEach((comboItem: CustomizedItem | ReorderedShoppingBagItem) => {
      let itemText: string;

      if (isCustomizedItem(comboItem)) {
        itemText =
          comboItem.retailModifiedItem?.receiptText ?? comboItem.retailItem?.receiptText ?? "";
      } else {
        itemText = comboItem.receiptText;
      }
      items.length === 0 ? (items = itemText) : (items += ", " + itemText);
    });
    return items;
  }

  const description = combo ? buildItemList() : buildCondimentList();

  let discountName = "";

  if (props.discounts && props.discounts.length > 0) {
    discountName = props.discounts.map((discount) => discount.name).join(", ");
  }

  return (
    <div className={itemDisplayClassNames}>
      <ItemImageTextDisplay description={description} image={image} title={title} />

      <div className="item-price">
        <PriceDisplay
          discount={discount}
          loyaltyDiscounts={props.loyaltyDiscounts}
          originalPrice={originalPrice}
          total={total}
        />
      </div>

      {props.discounts && props.discounts?.length > 0 && (
        <div className="item-discount">
          <DiscountDisplay name={discountName} />
        </div>
      )}

      <div className="item-display-footer">
        <div>
          {props.showRemoveButton && (
            <SheetzTextButton
              textDark
              label="Remove"
              onClick={(): void => props.showRemoveItem?.(true)}
            />
          )}
        </div>

        <div className="edit-item">
          {props.editable && customizable && (
            <>
              {isCustomizedShoppingBagItem(item) && (
                <Link
                  to={"/order/customize/" + item.itemCustomizationId}
                  state={{
                    bagItemId: item.id,
                    previousLocation: location.pathname,
                    event: item.itemDetails.event,
                  }}
                >
                  <SheetzTextButton
                    textDark
                    label="Edit"
                    onClick={(): void => props.closeBagFn?.()}
                  />
                </Link>
              )}

              {isShoppingBagCombo(item) && (
                <SheetzTextButton
                  textDark
                  label="Edit"
                  onClick={(): void => {
                    props.closeBagFn?.();
                    props.editCombo?.();
                  }}
                />
              )}
            </>
          )}
        </div>

        <ItemQuantityDisplay
          editable={props.editable}
          quantity={item.quantity}
          showEditQuantity={props.showEditQuantity}
        />
      </div>
    </div>
  );
};

export default ItemDisplay;
