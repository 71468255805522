import React from "react";

import "./RestrictedItemPickupTermsActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { OrderSession } from "util/Order.util";

interface RestrictedItemPickupTermsActionSheetProps {
  show: boolean;
  setShowRestrictedItemPickupTermsActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
  orderSession: OrderSession;
}

const RestrictedItemPickupTermsActionSheet = (props: RestrictedItemPickupTermsActionSheetProps) => {
  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Get Prepared For 21+ Pick-up"
      overlay={true}
      shouldDisplay={props.show}
    >
      <div className="restricted-item-pickup-terms">
        <div className="instructions">
          <p>
            <strong>Bring your ID for age verification</strong>
          </p>
          <p className="instruction">
            Store team members will scan your ID to verify your age and identity before handing over
            your order.
          </p>
          <p className="instruction">Your order cannot be picked up by anyone other than you.</p>
        </div>
        <p className="disclaimer">
          You won’t receive any part of the order if you are underage, intoxicated, or unable to
          present an ID.
        </p>
        {props.orderSession.store?.state === "MI" && (
          <p className="disclaimer">
            By selecting Accept & Continue you acknowledge that your full name, birth date, and
            registered address have been entered and are accurate. You also acknowledge that the
            sale of tobacco products to someone under 21 years old and the purchase of tobacco
            products by someone under 21 years old is illegal.
          </p>
        )}

        <div className="accept-continue-button">
          <SheetzButton
            label="Accept & Continue"
            onClick={(): void => props.setShowRestrictedItemPickupTermsActionSheet(false)}
          />
        </div>
      </div>
    </ActionSheet>
  );
};

export default RestrictedItemPickupTermsActionSheet;
