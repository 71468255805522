import React, { useContext } from "react";

import "./AmountSelector.scss";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";

import { AddFundsHeaderContext } from "util/Payment.util";

interface AmountProps {
  className?: string;
  options: number[];
  buttonStyle: "header" | "body";
}

const AmountSelector = (props: AmountProps) => {
  const addFundsHeaderContext = useContext(AddFundsHeaderContext);
  const ButtonAmounts = (buttonProps: AmountProps) => {
    if (buttonProps) {
      const buttonAmounts = buttonProps.options.map((buttonAmount) => {
        const isSelected = addFundsHeaderContext.amount === buttonAmount;
        let buttonColor = ButtonColor.white;

        if (isSelected && buttonProps.buttonStyle === "body") {
          buttonColor = ButtonColor.payGreen;
        }

        return (
          <SheetzButton
            className="amount-select"
            key={buttonAmount}
            label={"$" + buttonAmount}
            onClick={(): void => {
              addFundsHeaderContext.setAmount(buttonAmount);
            }}
            buttonColor={buttonColor}
            transparentLight={props.buttonStyle === "header" && !isSelected}
          />
        );
      });
      return <>{buttonAmounts}</>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="amount-selector-container">
        <ButtonAmounts
          options={props.options}
          className={props.className}
          buttonStyle={props.buttonStyle}
        />
      </div>
    </>
  );
};

export default AmountSelector;
