import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";

import "./MyWallet.scss";

import {
  CardTypeEnum,
  CreditCard,
  GetCreditCardResponse,
  PaymentMethod,
  ZCard,
} from "assets/dtos/anywhere-dto";
import zcardIcon from "assets/images/GC_2021_lg.png";

import CreditCardModal from "components/Account/MyWallet/CreditCard/CreditCard";
import GiftCard from "components/Account/MyWallet/GiftCard/GiftCard";
import Pin from "components/Account/MyWallet/Pin/Pin";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import LoadingPlaceholder from "components/misc/indicators/LoadingPlaceholder/LoadingPlaceholder";
import ListItem from "components/misc/list/ListItem/ListItem";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import {
  deletePaymentMethod,
  getCardName,
  getCreditCard,
  getCreditCardIconType,
  getGiftCard,
  getPaymentMethods,
  updateCreditCard,
  updateGiftCard,
} from "util/Payment.util";
import { verifyPinStillValid } from "util/Pin.util";
import { removeSelectedWalletPaymentMethodId, setSelectedPaymentMethodId } from "util/Storage.util";

interface PaymentMethodProps {
  paymentMethodList: PaymentMethod[];
}

export enum SubmitWalletActionEnum {
  "Delete",
  "Save",
}

export interface CreditCardFormValues {
  cardNumber?: string;
  cardSecurityCode: string;
  cardType?: CardTypeEnum;
  city: string;
  expirationMonth: number | string;
  expirationYear: number | string;
  firstName: string;
  isDefault?: boolean;
  isExpired?: boolean;
  lastFourDigits?: string;
  lastName: string;
  paymentMethodId?: number;
  postalCode: string;
  state: string;
  street: string;
}

const MyWallet = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [addFundsPaymentMethod, setAddFundsPaymentMethod] = useState<PaymentMethod>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [editCreditCard, setEditCreditCard] = useState<boolean>(false);
  const [currentCreditCard, setCurrentCreditCard] = useState<CreditCard>();
  const [currentGiftCard, setCurrentGiftCard] = useState<PaymentMethod>();
  const [showGiftCard, setShowGiftCard] = useState<boolean>(false);
  const [retry, setRetry] = useState<boolean>(false);
  const [isDefaultPaymentMethod, setDefaultPaymentMethod] = useState<boolean | undefined>(
    undefined
  );

  const walletState = location.state as
    | {
        ordering: boolean;
        amountOwed: number;
        paymentMethodId?: number;
        amount?: number;
        reloadThreshold?: number;
        returnUri: string;
      }
    | undefined;

  /**
   * The main wallet view doesn't require a pin if the user came here from the ordering flow.
   * Note: Adding a payment method or editing an existing one will require PIN input before proceeding.
   */
  const [pinAuthenticated, setPinAuthenticated] = useState<boolean>(walletState?.ordering || false);

  useEffect(() => {
    if (pinAuthenticated) {
      getPaymentMethods().then((response: AxiosResponse) => {
        setPaymentMethods(response.data.paymentMethods);
        // If there is a payment method id in the location state then use that to open edit CC modal to immediately edit that payment method
        // Could be in the state from add funds, auto reload, buy gift card components
        if (walletState?.paymentMethodId) {
          const paymentMethods = response.data.paymentMethods as PaymentMethod[];
          const paymentMethodToEdit = paymentMethods.find((paymentMethod) => {
            return paymentMethod.paymentMethodId === walletState?.paymentMethodId;
          });
          if (paymentMethodToEdit) {
            handleEditPaymentMethodClick(paymentMethodToEdit);
          }
        }
      });

      if (addFundsPaymentMethod) {
        navigate("/account/addFunds", {
          state: { paymentMethodId: addFundsPaymentMethod.paymentMethodId, ordering: true },
        });
        return;
      }

      // Clear out selected payment method for AddFunds, Auto Reload and Buy GC flows
      removeSelectedWalletPaymentMethodId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, pinAuthenticated]);

  const navigateToAddPaymentMethod = (): void => {
    navigate("/account/addPaymentMethod", { state: walletState });
  };

  const handleEditPaymentMethodClick = (paymentMethod: PaymentMethod): void => {
    // A valid PIN is not required when changing between valid payment methods in the ordering flow.
    if (walletState?.ordering) {
      /**
       * User selected to pay with a gift card that has insufficient funds,
       * save paymentMethod to state so user can be redirected if pin isn't authenticated
       */
      if (
        paymentMethod.zCard?.balance !== undefined &&
        paymentMethod.zCard.balance < walletState.amountOwed
      ) {
        setAddFundsPaymentMethod(paymentMethod);
      }

      /**
       * Selecting a payment method that isn't expired, and has enough funds in the case of a gift
       * card, will select that method for payment of the user's order.
       */
      if (
        (paymentMethod.paymentType === "CREDIT_CARD" && !paymentMethod.creditCard?.isExpired) ||
        (paymentMethod.zCard?.balance !== undefined &&
          paymentMethod.zCard.balance >= walletState.amountOwed)
      ) {
        setSelectedPaymentMethodId(paymentMethod.paymentMethodId);
        navigate(-1);
        return;
      }

      if (!verifyPinStillValid()) {
        setPinAuthenticated(false);
        return;
      }
    }

    fetchPaymentDataAndNavigate(paymentMethod);
  };

  const fetchPaymentDataAndNavigate = (paymentMethod: PaymentMethod): void => {
    switch (paymentMethod.paymentType) {
      case "CREDIT_CARD":
        getCreditCard(paymentMethod.paymentMethodId).then((response) => {
          response.data.creditCard.paymentMethodId = paymentMethod.paymentMethodId;
          const returnedCreditCard: GetCreditCardResponse = { ...response.data };
          paymentMethod.isDefault
            ? setDefaultPaymentMethod(paymentMethod.isDefault)
            : setDefaultPaymentMethod(false);
          setCurrentCreditCard(returnedCreditCard.creditCard);
          setEditCreditCard(true);
        });
        break;
      case "ZCARD":
        if (
          walletState?.amountOwed &&
          paymentMethod.zCard?.balance !== undefined &&
          paymentMethod.zCard.balance < walletState.amountOwed
        ) {
          navigate("/account/addFunds", {
            state: { paymentMethodId: paymentMethod.paymentMethodId, ordering: true },
          });
          break;
        }
        getGiftCard(paymentMethod.paymentMethodId).then((response) => {
          const returnedGiftCard: PaymentMethod = { ...response.data.zcard };
          paymentMethod.isDefault
            ? setDefaultPaymentMethod(paymentMethod.isDefault)
            : setDefaultPaymentMethod(false);
          setCurrentGiftCard(returnedGiftCard);
          setShowGiftCard(true);
        });
        break;
      default:
        alert("TODO: UNKNOWN payment type selected");
        break;
    }
  };

  const submitCreditCard = (
    values: CreditCardFormValues,
    submitWalletAction: SubmitWalletActionEnum
  ): void => {
    if (values.paymentMethodId) {
      if (submitWalletAction === SubmitWalletActionEnum.Save) {
        updateCreditCard(values.paymentMethodId, values).then(() => {
          closeEditCard();
          appContext.showToast("Card successfully updated!", "", ToastType.success);
          if (walletState?.ordering) {
            navigate(-1);
          } else {
            retryPaymentMethodFetch();
          }
        });
      } else if (submitWalletAction === SubmitWalletActionEnum.Delete) {
        deletePaymentMethod(values.paymentMethodId).then(() => {
          closeEditCard();
          appContext.showToast("Card successfully removed!", "", ToastType.success);
          retryPaymentMethodFetch();
        });
      }
    }
  };

  const closeEditCard = (): void => {
    setEditCreditCard(false);
    setCurrentCreditCard(undefined);
    setDefaultPaymentMethod(undefined);
    setShowGiftCard(false);
    setCurrentGiftCard(undefined);
    if (walletState && walletState?.returnUri) {
      navigate(-1);
    }
  };

  // We're just making the state change in order to reload the data.
  function retryPaymentMethodFetch(): void {
    setRetry(!retry);
  }

  const creditCardModal = (
    <SheetzModal
      className="credit-card-modal"
      isOpen={editCreditCard}
      closeFunction={closeEditCard}
      contentLabel="Edit Credit Card"
      onRequestClose={closeEditCard}
      shouldCloseOnOverlayClick={false}
      headerText="Edit Credit Card"
      backgroundColor="green"
    >
      {currentCreditCard && (
        <CreditCardModal
          isDefault={isDefaultPaymentMethod ? isDefaultPaymentMethod : false}
          card={currentCreditCard}
          submitFunction={submitCreditCard}
        />
      )}
    </SheetzModal>
  );

  const MyPaymentMethodElements = (props: PaymentMethodProps) => {
    if (paymentMethods && paymentMethods.length > 0) {
      const paymentMethodElements = props.paymentMethodList?.map((paymentMethod) => {
        return (
          <div key={paymentMethod.paymentMethodId}>
            <ListItem hideArrow={true}>
              <div className="payment-method-item">
                <button onClick={(): void => handleEditPaymentMethodClick(paymentMethod)}>
                  <div>
                    {paymentMethod.paymentType === "CREDIT_CARD" && paymentMethod.creditCard && (
                      <>
                        <div className="payment-method-icon-container">
                          {getIcon(
                            getCreditCardIconType(paymentMethod.creditCard.cardType),
                            "payment-method-icon"
                          )}
                        </div>
                        <div className="payment-method-label-container">
                          <p className="payment-method-name">
                            {getCardName(paymentMethod.creditCard.cardType) +
                              " *" +
                              paymentMethod.creditCard.lastFourDigits}
                          </p>
                          {!paymentMethod.creditCard.isExpired && (
                            <p className="payment-method-number">
                              {`Expires: ${
                                paymentMethod.creditCard.expirationMonth
                              }/${paymentMethod.creditCard.expirationYear.toString().slice(2)}`}
                            </p>
                          )}
                          {paymentMethod.creditCard.isExpired && (
                            <p className="payment-method-number-expired">Expired - Update now</p>
                          )}
                        </div>
                      </>
                    )}
                    {paymentMethod.paymentType === "ZCARD" && paymentMethod.zCard && (
                      <>
                        <div className="payment-method-icon-container">
                          <img src={zcardIcon} alt="ZCard" />
                        </div>
                        <div className="payment-method-label-container">
                          <p className="payment-method-name">
                            {paymentMethod.zCard.cardName
                              ? paymentMethod.zCard.cardName
                              : "Gift Card *" + paymentMethod.zCard.cardNumber.substr(-3)}
                          </p>
                          {walletState?.amountOwed !== undefined &&
                          paymentMethod.zCard.balance !== undefined &&
                          paymentMethod.zCard.balance < walletState.amountOwed ? (
                            <p className="low-funds">Insufficient Funds</p>
                          ) : (
                            <p className="payment-method-number">
                              <NumericFormat
                                value={paymentMethod.zCard.balance}
                                displayType="text"
                                decimalScale={2}
                                prefix="$"
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                              />
                            </p>
                          )}
                        </div>
                      </>
                    )}
                    <div className="payment-method-default-container">
                      {walletState?.amountOwed !== undefined &&
                      paymentMethod.zCard?.balance !== undefined &&
                      paymentMethod.zCard.balance < walletState.amountOwed ? (
                        <p className="add-funds">Add Funds</p>
                      ) : (
                        (paymentMethod.isDefault &&
                          !paymentMethod.creditCard?.isExpired &&
                          "Default") ||
                        (paymentMethod.creditCard?.isExpired && (
                          <span className="payment-method-update-label">Update</span>
                        ))
                      )}
                    </div>
                  </div>
                </button>
              </div>
            </ListItem>
          </div>
        );
      });
      return <>{paymentMethodElements}</>;
    } else {
      return <></>;
    }
  };

  const submitGiftCard = (zCard: ZCard, submitAction: SubmitWalletActionEnum): void => {
    if (submitAction === SubmitWalletActionEnum.Save) {
      updateGiftCard(zCard).then(() => {
        appContext.showToast("Gift Card updated!", "", ToastType.success);
        closeEditCard();
        retryPaymentMethodFetch();
      });
    } else if (submitAction === SubmitWalletActionEnum.Delete && currentGiftCard?.paymentMethodId) {
      deletePaymentMethod(currentGiftCard?.paymentMethodId).then(() => {
        closeEditCard();
        retryPaymentMethodFetch();
      });
    }
  };

  const giftCardEditModal = (
    <SheetzModal
      className="gift-card-modal"
      isOpen={showGiftCard}
      closeFunction={closeEditCard}
      contentLabel="Edit Gift Card"
      onRequestClose={closeEditCard}
      shouldCloseOnOverlayClick={false}
      headerText="Edit Gift Card"
      backgroundColor="green"
    >
      {currentGiftCard && (
        <GiftCard
          isDefault={isDefaultPaymentMethod ? isDefaultPaymentMethod : false}
          giftCard={currentGiftCard}
          submitFunction={submitGiftCard}
          onRequestClose={closeEditCard}
        />
      )}
    </SheetzModal>
  );

  const PaymentMethodListRetry = () => {
    return (
      <>
        <ListItem hideArrow={true}>
          <div className="payment-method-item">
            <button onClick={(): void => retryPaymentMethodFetch()}>
              <div>
                <div className="payment-method-icon-container">
                  {getIcon(IconType.card, "card-icon")}
                </div>
                <div className="payment-method-label-container">
                  <p className="payment-method-name">Could not load payment methods</p>
                  <p className="payment-method-number">Tap to Retry</p>
                </div>
                <div className="payment-method-default-container">
                  {getIcon(IconType.refresh, "refresh-icon")}
                </div>
              </div>
            </button>
          </div>
        </ListItem>
      </>
    );
  };

  function setPinAuthentication(authenticated: boolean): void {
    setPinAuthenticated(authenticated);
  }

  function closedWithoutAuthenticating(): void {
    navigate(-1);
  }

  if (pinAuthenticated) {
    if (paymentMethods === undefined) {
      return <LoadingPlaceholder />;
    } else {
      if (paymentMethods && paymentMethods.length > 0) {
        return (
          <ResponsiveLayoutContainer>
            <div className="payment-method-container">
              <p className="message">
                {walletState?.ordering
                  ? "Choose Payment Method"
                  : "Tap a payment method to view or edit"}
              </p>
              <MyPaymentMethodElements paymentMethodList={paymentMethods} />
            </div>
            <div className="add-payment-method-row">
              <SheetzButton
                className="add-payment-method-button"
                transparentDark
                label="Add Payment Method"
                onClick={(): void => {
                  navigateToAddPaymentMethod();
                }}
              />
            </div>
            {showGiftCard && giftCardEditModal}
            {editCreditCard && creditCardModal}
          </ResponsiveLayoutContainer>
        );
      } else if (paymentMethods && paymentMethods.length === 0) {
        return (
          <>
            <div className="empty-wallet-page">
              <div className="empty-wallet-label">
                <span>{getIcon(IconType.card, "card-icon")}</span>
              </div>
              <div className="empty-wallet-title">
                <p>You have no saved payment methods!</p>
                <p>Add one to your Wallet.</p>
              </div>
              <div className="payment-method-add">
                <SheetzButton
                  className="add-payment-method-btn"
                  transparentDark
                  label="Add Payment Method"
                  onClick={(): void => {
                    navigateToAddPaymentMethod();
                  }}
                />
              </div>
            </div>
          </>
        );
      } else {
        return <PaymentMethodListRetry />;
      }
    }
  } else {
    return (
      <>
        <Pin
          setIsAuthenticatedCallback={setPinAuthentication}
          closedWithoutAuthenticatingCallback={closedWithoutAuthenticating}
        />
      </>
    );
  }
};

export default MyWallet;
