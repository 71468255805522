import React, { useState } from "react";

import "./AutoReloadAction.scss";

import AmountSelector from "components/Account/MyWallet/AddFunds/AmountSelector/AmountSelector";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { AddFundsHeaderContext, AddFundsHeaderContextProps } from "util/Payment.util";

interface AutoReloadProps {
  continueCallback: (reloadAmount: number, reloadThreshold: number) => void;
  cancelCallback: () => void;
  purchasingGiftCard?: boolean;
}

const AutoReloadAction = (props: AutoReloadProps) => {
  const [reloadAmount, setReloadAmount] = useState<number>(25);
  const [reloadThreshold, setReloadThreshold] = useState<number>(10);
  const addFundsHeaderContext = AddFundsHeaderContext;

  return (
    <>
      <div className="auto-reload-container">
        <div className="choose-reload-amount-label">Choose reload amount:</div>
        <addFundsHeaderContext.Provider
          value={{ setAmount: setReloadAmount, amount: reloadAmount } as AddFundsHeaderContextProps}
        >
          <AmountSelector options={[10, 25, 50, 100]} buttonStyle={"body"} />
        </addFundsHeaderContext.Provider>
        <div className="auto-reload-border"></div>
        <div className="reload-when-balance-label">Reload when my balance is below:</div>
        <addFundsHeaderContext.Provider
          value={
            { setAmount: setReloadThreshold, amount: reloadThreshold } as AddFundsHeaderContextProps
          }
        >
          <AmountSelector options={[5, 10, 25, 50]} buttonStyle={"body"} />
        </addFundsHeaderContext.Provider>

        <div className="button-row">
          <SheetzTextButton
            className="back-button"
            textDark
            type="button"
            onClick={(): void => {
              props.cancelCallback();
            }}
            label={"No Thanks"}
          />
          <SheetzButton
            className="submit-button"
            type="button"
            onClick={(): void => {
              props.continueCallback(reloadAmount, reloadThreshold);
            }}
            label="Continue"
          />
        </div>
      </div>
    </>
  );
};

export default AutoReloadAction;
