import React from "react";

import "./DeliveryCurrentlyUnavailableActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

interface DeliveryCurrentlyUnavailableActionSheetProps {
  isAddressDeliverable: boolean;
  shouldDisplay: boolean;
  choosePickupCallback: () => void;
  chooseAnotherAddressCallback: () => void;
}

const DeliveryCurrentlyUnavailableActionSheet = (
  props: DeliveryCurrentlyUnavailableActionSheetProps
) => {
  const subtitle = props.isAddressDeliverable
    ? "Delivery Currently Unavailable"
    : "We Don't Deliver Here\u2026 Yet";
  const bodyText = props.isAddressDeliverable
    ? "Looks like there's no drivers available right now - check back soon!"
    : "We're always expanding delivery coverage. Your area might be next. Check back soon!";

  return (
    <div className="delivery-address-action-sheet">
      <ActionSheet
        color={ActionSheetColor.error}
        title={"Oh Sheetz!"}
        text={subtitle}
        overlay={true}
        shouldDisplay={props.shouldDisplay}
      >
        <p className="delivery-address-message">{bodyText}</p>
        <p className="delivery-address-message">
          Please edit this address, choose another, or choose pickup.
        </p>
        <div className="button-container">
          <SheetzButton
            transparentDark
            className="delivery-address-confirm-button"
            label="Choose Another Address"
            onClick={props.chooseAnotherAddressCallback}
          />
          <SheetzButton
            transparentDark
            className="delivery-address-confirm-button"
            label="Choose Pickup"
            onClick={props.choosePickupCallback}
          />
        </div>
      </ActionSheet>
    </div>
  );
};

export default DeliveryCurrentlyUnavailableActionSheet;
