import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import { useLocation, useNavigate } from "react-router-dom";

import "./BuyGiftCard.scss";

import { AutoReloadDTO, BuyZCardRequest, PaymentMethod } from "assets/dtos/anywhere-dto";

import AddFundsHeader from "components/Account/MyWallet/AddFunds/AddFundsHeader/AddFundsHeader";
import ConfirmBuyGiftCard from "components/Account/MyWallet/AddPaymentMethod/BuyGiftCard/ConfirmBuyGiftCard/ConfirmBuyGiftCard";
import AutoReloadAction from "components/Account/MyWallet/AutoReload/AutoReloadAction/AutoReloadAction";
import ChoosePaymentMethod from "components/Account/MyWallet/ChoosePaymentMethod/ChoosePaymentMethod";
import Pin from "components/Account/MyWallet/Pin/Pin";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import {
  AddFundsHeaderContext,
  AddFundsHeaderContextProps,
  GiftCardState,
  buyGiftCard,
} from "util/Payment.util";
import { verifyPinStillValid } from "util/Pin.util";
import getOrGenerateRavelinId from "util/Ravelin.util";
import {
  getPinAuthToken,
  getUserId,
  removeSelectedWalletPaymentMethodId,
  setSelectedPaymentMethodId,
} from "util/Storage.util";
import { showVisaSuccess } from "util/VisaSensoryBranding.util";

const BuyGiftCard = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const [fundAmount, setFundAmount] = useState<number>(25);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod>();
  const [showAutoReloadActionSheet, setShowAutoReloadActionSheet] = useState<boolean>(false);
  const [showConfirmBuyGiftCard, setShowConfirmBuyGiftCard] = useState<boolean>(false);
  const [reloadAmount, setReloadAmount] = useState<number>();
  const [reloadThreshold, setReloadThreshold] = useState<number>();
  const [pinAuthenticated, setPinAuthenticated] = useState<boolean>(false);
  const addFundsHeaderContext = AddFundsHeaderContext;
  const addFundsState = location.state as GiftCardState;

  useEffect(() => {
    if (addFundsState?.amount) {
      setFundAmount(addFundsState.amount);
      setAmount(addFundsState.amount);
    }
  }, [addFundsState]);

  function setAmount(amount: number): void {
    setFundAmount(amount);
  }

  function handleEditPaymentMethodClick(paymentMethod: PaymentMethod): void {
    if (!paymentMethod.creditCard?.isExpired) {
      setPaymentMethodSelected(paymentMethod);
    } else {
      navigate(location.pathname, {
        state: {
          ...addFundsState,
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: fundAmount,
          returnUri: "/account/addFunds",
        },
        replace: true,
      });
      navigate("/account/myWallet", {
        state: {
          paymentMethodId: paymentMethod.paymentMethodId,
          amount: fundAmount,
          returnUri: "/account/buyGiftCard",
        },
      });
    }
  }

  function setPinAuthentication(authenticated: boolean): void {
    setPinAuthenticated(authenticated);
  }

  function closedWithoutAuthenticating(): void {
    navigate("/account/addPaymentMethod");
  }

  function submitPurchase(): void {
    const pinAuthJWT = getPinAuthToken();
    const isPinStillValid = verifyPinStillValid();
    if (!isPinStillValid) {
      appContext.showToast("Oh Sheetz!", "Your Pin session has expired!", ToastType.error);
      navigate("/account/addPaymentMethod");
      return;
    }

    if (paymentMethodSelected?.paymentMethodId && pinAuthJWT && pinAuthJWT.jwtToken) {
      let autoReload: AutoReloadDTO = {} as AutoReloadDTO;
      // If autoreload is set
      if (reloadThreshold && reloadAmount) {
        autoReload = {
          autoReloadEnabled: true,
          balanceThreshold: reloadThreshold,
          reloadAmount: reloadAmount,
          autoReloadType: "BALANCE",
        };
      }

      getOrGenerateRavelinId()
        .then((id) => {
          const buyZCard: BuyZCardRequest = {
            amount: fundAmount,
            paymentMethodId: paymentMethodSelected?.paymentMethodId,
            autoReload: autoReload,
            jwt: pinAuthJWT?.jwtToken,
            deviceId: id,
          };

          const dataLayer = {
            event: "purchase",
            ecommerce: {
              value: fundAmount,
              currency: "USD",
            },
            userId: getUserId(),
            transactionType: "New Gift Card",
            dateTime: DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
            gtmevent: "ordercomplete",
          };

          appContext.showLoading();
          buyGiftCard(buyZCard)
            .then((response) => {
              setShowConfirmBuyGiftCard(false);
              removeSelectedWalletPaymentMethodId();

              showVisaSuccess(true);

              appContext.showToast("Card Added!", "", ToastType.success);

              const tagManagerArgs = {
                dataLayer,
              };

              // Clear previous ecommerce object
              TagManager.dataLayer({ dataLayer: { ecommerce: null } });

              // Push new purchase event to dataLayer
              TagManager.dataLayer(tagManagerArgs);

              if (addFundsState && addFundsState.ordering) {
                setSelectedPaymentMethodId(response.data.paymentMethod.paymentMethodId);
                // Go back three entries to checkout confirmation.
                navigate(-3);
              } else {
                navigate("/account/myWallet");
              }
            })
            .finally(appContext.hideLoading);
        })
        .catch((error) => {
          appContext.hideLoading();
          showBoundary(error);
        });
    }
  }

  const confirmBuyGiftCard = (
    <SheetzModal
      className="confirm-buy-gift-modal"
      isOpen={showConfirmBuyGiftCard}
      closeFunction={closeConfirmBuyGiftCard}
      contentLabel="Buy a Gift Card"
      onRequestClose={closeConfirmBuyGiftCard}
      shouldCloseOnOverlayClick={false}
      headerText="Buy a Gift Card"
      backgroundColor="green"
    >
      {fundAmount && paymentMethodSelected && (
        <ConfirmBuyGiftCard
          reloadAmount={reloadAmount}
          reloadThreshold={reloadThreshold}
          amount={fundAmount}
          paymentMethod={paymentMethodSelected}
          submitCallback={submitPurchase}
          cancelCallback={closeConfirmBuyGiftCard}
        />
      )}
    </SheetzModal>
  );

  const autoReloadActionSheet = (
    <ActionSheet
      color={ActionSheetColor.currency}
      title="ADD AUTO RELOAD?"
      text="ADD FUNDS TO THIS CARD AUTOMATICALLY!"
      shouldDisplay={showAutoReloadActionSheet}
      overlay={true}
      cancelFunction={(): void => {
        setShowAutoReloadActionSheet(false);
      }}
      required
    >
      <AutoReloadAction
        cancelCallback={closeAutoReloadActionSheet}
        continueCallback={showConfirmPurchase}
        purchasingGiftCard={true}
      ></AutoReloadAction>
    </ActionSheet>
  );

  function closeAutoReloadActionSheet(): void {
    setShowAutoReloadActionSheet(false);
    setShowConfirmBuyGiftCard(true);
  }

  function closeConfirmBuyGiftCard(): void {
    setShowConfirmBuyGiftCard(false);
  }

  function showConfirmPurchase(reloadAmount: number, reloadThreshold: number): void {
    setReloadAmount(reloadAmount);
    setReloadThreshold(reloadThreshold);
    setShowAutoReloadActionSheet(false);
    setShowConfirmBuyGiftCard(true);
  }

  // pass the payment method to the Add Payment Method screen so when the user is finished there, they can pass the payment method back
  // this payment method Id represents the gift card that funds are being added to
  function navigateToPaymentMethod(): void {
    navigate(location.pathname, {
      state: {
        ...addFundsState,
        amount: fundAmount,
        returnUri: "/account/addFunds",
      },
      replace: true,
    });
    navigate("/account/AddPaymentMethod", {
      state: {
        amount: fundAmount,
        returnUri: "/account/buyGiftCard",
      },
    });
  }

  if (pinAuthenticated) {
    return (
      <>
        <addFundsHeaderContext.Provider
          value={{ setAmount: setAmount, amount: fundAmount } as AddFundsHeaderContextProps}
        >
          <AddFundsHeader />
        </addFundsHeaderContext.Provider>
        <div className="buy-gc-amount-container">
          <ChoosePaymentMethod paymentMethodSelectedCallback={handleEditPaymentMethodClick} />
          <div className="button-row add-payment-method-button">
            <SheetzButton
              className="submit-button"
              transparentDark
              type="button"
              label="Add Payment Method"
              onClick={(): void => {
                navigateToPaymentMethod();
              }}
            />
          </div>
          <div className="button-row next-button">
            <SheetzButton
              className="submit-button"
              type="button"
              label="Next"
              onClick={(): void => {
                if (paymentMethodSelected && fundAmount > 5) {
                  setShowAutoReloadActionSheet(true);
                } else if (!paymentMethodSelected) {
                  appContext.showToast(
                    "Oh Sheetz!",
                    "Please select a payment method to continue",
                    ToastType.error
                  );
                } else if (fundAmount < 5) {
                  appContext.showToast(
                    "Oh Sheetz!",
                    "$5 minimum required for gift card purchase",
                    ToastType.error
                  );
                }
              }}
            />
          </div>
        </div>
        {showAutoReloadActionSheet && autoReloadActionSheet}
        {showConfirmBuyGiftCard && confirmBuyGiftCard}
      </>
    );
  } else {
    return (
      <>
        <Pin
          setIsAuthenticatedCallback={setPinAuthentication}
          closedWithoutAuthenticatingCallback={closedWithoutAuthenticating}
        />
      </>
    );
  }
};

export default BuyGiftCard;
