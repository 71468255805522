import React from "react";

import "./AdditionalPaymentActions.scss";

import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

interface AdditionalPaymentActionsProps {
  changePaymentMethod: () => void;
  payInStore: boolean;
  showDetailsLink?: boolean;
  viewPriceDetails?: () => void;
}

const AdditionalPaymentActions = ({
  changePaymentMethod,
  payInStore,
  showDetailsLink,
  viewPriceDetails,
}: AdditionalPaymentActionsProps) => {
  return (
    <div className="additional-payment-actions">
      <SheetzTextButton
        label={payInStore ? "Change" : "Payment Options"}
        onClick={changePaymentMethod}
      />
      {showDetailsLink && <SheetzTextButton label="Price Details" onClick={viewPriceDetails} />}
    </div>
  );
};

export default AdditionalPaymentActions;
