import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MyAddresses.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import DeliveryAddressListItem from "components/Order/Delivery/DeliveryAddressListItem/DeliveryAddressListItem";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ButtonFooterContainer from "components/misc/containers/ButtonFooterContainer/ButtonFooterContainer";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { deleteDeliveryAddress, getDeliveryAddresses } from "util/Order.util";

interface MyAddressProps {
  addresses: Array<DeliveryAddress>;
}

const MyAddresses = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const showLoading = appContext.showLoading;
  const hideLoading = appContext.hideLoading;
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  let deliveryAddressToRemove: DeliveryAddress;

  useEffect(() => {
    showLoading();
    loadUserAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadUserAddresses(): void {
    getDeliveryAddresses()
      .then((response) => {
        setDeliveryAddresses(response.data.deliveryAddresses);
        setIsLoaded(true);
      })
      .finally(() => {
        hideLoading();
      });
  }

  function deleteUserAddress(): void {
    if (deliveryAddressToRemove?.deliveryAddressId) {
      deleteDeliveryAddress(deliveryAddressToRemove?.deliveryAddressId).then(() => {
        appContext.showToast("Delivery Address removed!", "", ToastType.success);
        appContext.hideConfirmationActionSheet();
        loadUserAddresses();
      });
    }
  }

  function onDeliveryIconSelected(deliveryAddress: DeliveryAddress): void {
    deliveryAddressToRemove = deliveryAddress;

    appContext.showConfirmationActionSheet(
      "Are you sure you want to remove: " + deliveryAddress.alias,
      "Confirm",
      deleteUserAddress
    );
  }

  const AddressElements: FC<MyAddressProps> = (myAddressProps) => {
    if (myAddressProps.addresses && myAddressProps.addresses.length > 0) {
      const addressElements = myAddressProps.addresses?.map((address, index) => {
        return (
          <DeliveryAddressListItem
            deliveryAddress={address}
            key={address.deliveryAddressId}
            hideArrow
            icon={getIcon(IconType.trash, "trashIcon")}
            onDeliveryIconSelected={(deliveryAddress) => onDeliveryIconSelected(deliveryAddress)}
            specialInstructions
            hideBottomBorder={index + 1 === myAddressProps.addresses.length}
          />
        );
      });
      return <>{addressElements}</>;
    } else {
      return <></>;
    }
  };

  const buttonFooterContainer = (
    <ButtonFooterContainer>
      <SheetzButton
        label="Add New Address"
        borderColor="light-border"
        onClick={(): void => {
          navigate("/account/settings/preferences/myOrderingPreferences/addAddress");
        }}
      />
    </ButtonFooterContainer>
  );

  if (deliveryAddresses && deliveryAddresses.length > 0) {
    return (
      <div className="my-addresses-container">
        <ResponsiveLayoutContainer className="account-settings">
          <AddressElements addresses={deliveryAddresses} />
        </ResponsiveLayoutContainer>

        {buttonFooterContainer}
      </div>
    );
  } else if (!deliveryAddresses || (deliveryAddresses.length === 0 && isLoaded)) {
    return (
      <div className="my-addresses-container empty">
        <EmptyPage title="You have no saved addresses." icon={IconType.home} />

        {buttonFooterContainer}
      </div>
    );
  } else {
    return null;
  }
};

export default MyAddresses;
