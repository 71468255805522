import classNames from "classnames";
import React, { ReactElement, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./Header.scss";

import { DeliveryAddress, MenuCategory, Store } from "assets/dtos/anywhere-dto";
import { ReactComponent as SheetzLogo } from "assets/icons/logo_sheetz.svg";

import AccountSidebar from "components/Account/AccountSidebar/AccountSidebar";
import DeliveryPickupSubHeader from "components/Order/DeliveryPickupSubHeader/DeliveryPickupSubHeader";
import { OrderSessionAction } from "components/Order/OrderSessionReducer";
import NavigationSidebar from "components/layout/NavigationSidebar/NavigationSidebar";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { isLoggedIn } from "util/Authentication.util";
import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import { OrderSession } from "util/Order.util";

export enum HeaderType {
  homepage = "homepage",
  modal = "modal",
  main = "main",
  order = "order",
  deepLink = "deepLink",
}

export type HeaderBackgroundColor = "green" | "red";

interface HeaderProps {
  ageVerificationStatus?: string;
  backgroundColor?: HeaderBackgroundColor;
  children?: ReactElement;
  deliveryAddress?: DeliveryAddress;
  dispatch?: React.Dispatch<OrderSessionAction>;
  fixed?: boolean;
  orderSession?: OrderSession;
  parentMenuCategory?: MenuCategory;
  shouldDisplayBag?: boolean;
  store?: Store;
  title?: string;
  type: HeaderType;
}

const Header = (props: HeaderProps): ReactElement | null => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const loggedIn = isLoggedIn();
  const locationState = location.state;
  const [showHeaderText] = useMediaQuery("(min-width: 850px");
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  // If the app is running inside of the mobile apps, then we don't render a header
  if (isInNativeMobileContext()) {
    return null;
  }

  const toggleMenu = (): void => {
    setMenuOpen(!menuOpen);
  };

  const toggleNav = (): void => {
    setNavOpen(!navOpen);
  };

  const handleMenuOpenStateChange = (isOpen: boolean): void => {
    setMenuOpen(isOpen);
  };

  const handleNavOpenStateChange = (isOpen: boolean): void => {
    setNavOpen(isOpen);
  };

  let headerClasses =
    props.type === HeaderType.homepage ? "sheetz-header" : "sheetz-header standard";

  if (props.fixed) {
    headerClasses = headerClasses + " fixed";
  }

  if (props.backgroundColor && props.backgroundColor === "green") {
    headerClasses = headerClasses + " header-green";
  }

  let leftSideButton;
  if (props.type !== HeaderType.modal) {
    leftSideButton = (
      <>
        {(props.type === HeaderType.main || props.type === HeaderType.order) && (
          <button aria-label="Go back" className="nav-button" onClick={(): void => navigate(-1)}>
            {getIcon(IconType.arrow, "nav-icon")}
          </button>
        )}
        <button
          aria-label="Navigation menu"
          className="menu-button"
          onClick={(): void => toggleNav()}
        >
          {getIcon(IconType.menu, "menu-icon")}
          {props.type === HeaderType.order && <p>Menu</p>}
        </button>
      </>
    );
  } else {
    leftSideButton = <div className="button-placeholder" />;
  }

  let breadCrumbContent;
  if (props.type === HeaderType.order) {
    const pageTitle = (
      <span>
        {props.parentMenuCategory && " •"} {props.title}
      </span>
    );

    breadCrumbContent = (
      <div className="bread-crumb">
        {props.parentMenuCategory && (
          <button
            onClick={(): void =>
              navigate(`/order/menu/${props.parentMenuCategory?.menuCategoryId}`)
            }
          >
            {props.parentMenuCategory.name}
          </button>
        )}
        {showHeaderText && pageTitle}
      </div>
    );
  } else {
    breadCrumbContent = null;
  }

  let centerContent;
  if (props.type === HeaderType.homepage) {
    centerContent = (
      <div className="logo-container">
        <SheetzLogo className={"sheetz-logo"} />
      </div>
    );
  } else if (props.type === HeaderType.order) {
    centerContent = (
      <>
        <div className="page-title">{props.title}</div>
        {showHeaderText && <div className="order-title">My Order</div>}
      </>
    );
  } else {
    centerContent = <div className="page-title">{props.title}</div>;
  }

  let deliveryPickupSubHeader = null;
  if (
    props.type === HeaderType.order &&
    props.dispatch &&
    props.orderSession &&
    (props.orderSession.store ||
      (props.orderSession.delivery && props.orderSession.deliveryAddressId))
  ) {
    deliveryPickupSubHeader = (
      <div className="delivery-pickup-sub-header-container">
        <DeliveryPickupSubHeader
          deliveryAddress={props.deliveryAddress}
          dispatch={props.dispatch}
          noPadding
          orderSession={props.orderSession}
        ></DeliveryPickupSubHeader>
      </div>
    );
  }

  const loginButtonClassNames = classNames("user-button", {
    "desktop-user-button": props.type === HeaderType.order,
  });

  const LoginButton = (): ReactElement => {
    if (loggedIn) {
      return (
        <button
          className={loginButtonClassNames}
          aria-label="User menu"
          onClick={(): void => toggleMenu()}
        >
          {getIcon(IconType.user, "user-icon")}
        </button>
      );
    } else {
      return (
        <SheetzTextButton
          label="Log In"
          className="login-button"
          onClick={(): void => {
            navigate(
              {
                pathname: "/auth/login",
                search: "?destination=" + location.pathname + location.search,
              },
              {
                state: {
                  locationState,
                },
              }
            );
          }}
        />
      );
    }
  };

  const CloseButton = (): ReactElement => {
    return (
      <button
        aria-label={"Close " + props.title ?? ""}
        className="close-button"
        onClick={(): void => navigate(-1)}
      >
        {getIcon(IconType.x, "close-icon")}
      </button>
    );
  };

  let rightSideButton;
  if (props.type === HeaderType.modal) {
    rightSideButton = <CloseButton />;
  } else if (props.type === HeaderType.order) {
    rightSideButton = (
      <>
        <span className="order-login-button">
          <LoginButton />
        </span>
      </>
    );
  } else {
    rightSideButton = <LoginButton />;
  }

  return (
    <div className={headerClasses}>
      {loggedIn && (
        <AccountSidebar
          menuOpen={menuOpen}
          handleMenuOpenStateChange={handleMenuOpenStateChange}
          ageVerificationStatus={props.ageVerificationStatus}
        />
      )}
      <NavigationSidebar menuOpen={navOpen} handleMenuOpenStateChange={handleNavOpenStateChange} />
      <div className={"header-content " + props.type}>
        <div className="left-content">
          {leftSideButton}
          {breadCrumbContent}
        </div>
        {centerContent}
        <div className="right-content">
          {rightSideButton}
          {useDesktopView && deliveryPickupSubHeader}
          {props.shouldDisplayBag && props.children}
        </div>
      </div>
    </div>
  );
};

export default Header;
