import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./DeliveryPickupSubHeader.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import DeliveryPickupInformation from "components/Order/DeliveryPickupInformation/DeliveryPickupInformation";
import MyPickupInfo from "components/Order/MyPickupInfo/MyPickupInfo";
import { OrderSubviewProps } from "components/Order/Order";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { useMediaQuery } from "hooks";

import { desktopMediaQuery } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";

interface DeliveryPickupSubHeaderProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
  noPadding?: boolean;
  showRip?: boolean;
}

const DeliveryPickupSubHeader = (props: DeliveryPickupSubHeaderProps) => {
  const navigate = useNavigate();
  const [showPickupInfo, setShowPickupInfo] = useState(false);
  const isDelivery = props.orderSession.delivery;
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  const deliveryPickupSubHeaderClassNames = classNames("delivery-pickup-sub-header", {
    "no-padding": props.noPadding,
    "bottom-rip sheetz-red reduce-bottom-padding": props.showRip,
  });

  return (
    <>
      <div className={deliveryPickupSubHeaderClassNames}>
        <div className="location-type-details">
          {getIcon(isDelivery ? IconType.delivery : IconType.store, "delivery-pickup-icon")}
          {!useDesktopView && <p>{isDelivery ? "Delivery" : "Pickup"}</p>}
        </div>

        <div className="store-information-container">
          {!useDesktopView && getIcon(IconType.mapPin, "location-icon")}
          <DeliveryPickupInformation
            deliveryAddress={props.deliveryAddress}
            orderSession={props.orderSession}
          ></DeliveryPickupInformation>
        </div>

        <SheetzTextButton label="Change" type="button" onClick={() => setShowPickupInfo(true)} />
      </div>

      <MyPickupInfo
        close={(): void => setShowPickupInfo(false)}
        deliveryAddress={props.deliveryAddress}
        dispatch={props.dispatch}
        orderSession={props.orderSession}
        redirect={(path: string): void => {
          setShowPickupInfo(false);
          navigate(path, {
            state: {
              // TODO: use redirect on order setup pages to redirect after one, instead of going through the full flow
              redirectOnOrderFlowFinish: "/order/menu",
            },
          });
        }}
        show={showPickupInfo}
      />
    </>
  );
};

export default DeliveryPickupSubHeader;
