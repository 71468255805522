import classNames from "classnames";
import React from "react";

import "./OfferRebate.scss";

import {
  AgeRestrictedOffer as AgeRestrictedOfferDTO,
  OfferRequestAction,
} from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";

import { IconType, getIcon } from "util/Icon.util";
import { OfferAction } from "util/Offerz.util";
import { getDateTimeFromString } from "util/Time.util";

interface OfferProps {
  ageRestrictedOffer: AgeRestrictedOfferDTO;
  onUpdateAgeRestrictedOffer: (offerId: number, offerAction: OfferRequestAction) => void;
}

const offersClass = classNames("offers-container");

const RestrictedOffer = ({ ageRestrictedOffer, onUpdateAgeRestrictedOffer }: OfferProps) => {
  return (
    <>
      <div className={offersClass + (ageRestrictedOffer.offer.isAccepted ? " purchased" : "")}>
        <img
          className="offer-image"
          src={ageRestrictedOffer.surgeonGeneralWarningImageUrl}
          alt={ageRestrictedOffer.offer.offerName}
        />
        <div className="offer-contents">
          <img
            className="offer-product-image"
            src={ageRestrictedOffer.offer.imageURL}
            alt={ageRestrictedOffer.offer.offerName}
          />
          <div className="offer-info">
            <div className="offer-title">{ageRestrictedOffer.offer.offerName}</div>
            <div className="offer-description">{ageRestrictedOffer.offer.description}</div>
            {ageRestrictedOffer.offer.endDate && (
              <div className="offer-expiration">
                {"Expires " +
                  getDateTimeFromString(ageRestrictedOffer.offer.endDate).toFormat("L'/'d'/'yy")}
              </div>
            )}
          </div>
        </div>

        {ageRestrictedOffer.offer.offerType === "AGE_RESTRICTED_AUTOMATIC" ||
        ageRestrictedOffer.offer.offerType === "AGE_RESTRICTED_PRELOADED" ||
        ageRestrictedOffer.offer.isAccepted ? (
          <div className="offer-label-container">
            <div className="offer-label">
              {getIcon(IconType.ageicon, "age-icon")}
              On your account!
            </div>
          </div>
        ) : (
          <div className="offer-btn-container">
            {ageRestrictedOffer.offer.offerType === "AGE_RESTRICTED_CLICK_TO_ADD" && (
              <>
                <SheetzTextButton
                  className="remove-btn"
                  textDark
                  label="Remove"
                  onClick={(): void =>
                    onUpdateAgeRestrictedOffer(
                      ageRestrictedOffer.offer.offerId,
                      OfferAction.DECLINE
                    )
                  }
                />
                <SheetzButton
                  className="confirm-btn"
                  label="Add to Card"
                  onClick={(): void => {
                    onUpdateAgeRestrictedOffer(
                      ageRestrictedOffer.offer.offerId,
                      OfferAction.ACCEPT
                    );
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RestrictedOffer;
