import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MyReceipts.scss";

import { Order } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import EmptyPage from "components/misc/indicators/EmptyPage/EmptyPage";
import MenuItem from "components/misc/item/MenuItem/MenuItem";

import { AppContext } from "util/AppContext.util";
import { IconType } from "util/Icon.util";
import { MenuItemType, OrderStatus, getReceipts } from "util/Order.util";

export interface ReceiptState {
  orderNumber: number;
}

const MyReceipts = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [orders, setOrders] = useState<Order[]>();

  useEffect(() => {
    appContext.showLoading();
    getReceipts()
      .then((response) => {
        setOrders(response.data.orders);
      })
      .finally(() => {
        appContext.hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const historyDisplay =
    orders &&
    orders.length > 0 &&
    orders.map((order) => {
      return (
        <li key={order.orderNumber}>
          <MenuItem
            image={order.items[0].image}
            name={"Order #" + order.orderNumber}
            type={MenuItemType.receipt}
            date={order.pickupTime}
            orderStatus={OrderStatus[order.state]}
            orderingSystem={order.orderingSystem}
            onClick={(): void => {
              navigate("/account/receipt", {
                state: { orderNumber: order.orderNumber },
              });
            }}
          />
        </li>
      );
    });

  return (
    <>
      <div className="my-receipts-container">
        <>
          {orders?.length && orders.length > 0 ? (
            <ul className="history-list"> {historyDisplay} </ul>
          ) : (
            <div className="no-receipts-container">
              <EmptyPage
                title="You have no orders!"
                icon={IconType.bag}
                detail="Get some by starting a new order."
              >
                <div className="reorder-empty-page-buttons">
                  <SheetzButton
                    transparentDark
                    label="Main Menu"
                    onClick={(): void => {
                      navigate("/order");
                    }}
                  />
                </div>
              </EmptyPage>
            </div>
          )}
        </>
      </div>
    </>
  );
};
export default MyReceipts;
