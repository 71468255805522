import React, { ReactElement } from "react";

import { ReactComponent as BybeIcon } from "assets/icons/bybe_logo.svg";
import { ReactComponent as Reg87Icon } from "assets/icons/icon_87.svg";
import { ReactComponent as E15Icon } from "assets/icons/icon_88.svg";
import { ReactComponent as Mid89Icon } from "assets/icons/icon_89.svg";
import { ReactComponent as E0Icon } from "assets/icons/icon_90.svg";
import { ReactComponent as Prem93Icon } from "assets/icons/icon_93.svg";
import { ReactComponent as SUVIcon } from "assets/icons/icon_SUV.svg";
import { ReactComponent as AccountInformationIcon } from "assets/icons/icon_account_information.svg";
import { ReactComponent as AccountPreferencesIcon } from "assets/icons/icon_account_preferences.svg";
import { ReactComponent as AccountSecurityIcon } from "assets/icons/icon_account_security.svg";
import { ReactComponent as AgeIcon } from "assets/icons/icon_ageicon.svg";
import { ReactComponent as AlcoholIcon } from "assets/icons/icon_alcohol.svg";
import { ReactComponent as AlertIcon } from "assets/icons/icon_alert.svg";
import { ReactComponent as AmexIcon } from "assets/icons/icon_amex.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/icon_arrow.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/icon_arrow_up.svg";
import { ReactComponent as AtmIcon } from "assets/icons/icon_atm.svg";
import { ReactComponent as AutoDieselIcon } from "assets/icons/icon_auto_diesel.svg";
import { ReactComponent as BagIcon } from "assets/icons/icon_bag.svg";
import { ReactComponent as BeerGreyIcon } from "assets/icons/icon_beer_grey.svg";
import { ReactComponent as BeerWineIcon } from "assets/icons/icon_beer_wine.svg";
import { ReactComponent as BeerWineFiltersStoreDetailIcon } from "assets/icons/icon_beer_wine_filters_store_detail.svg";
import { ReactComponent as BulkDefIcon } from "assets/icons/icon_bulk_def.svg";
import { ReactComponent as BurgerIcon } from "assets/icons/icon_burger.svg";
import { ReactComponent as CarIcon } from "assets/icons/icon_car.svg";
import { ReactComponent as CarEvIcon } from "assets/icons/icon_car_ev.svg";
import { ReactComponent as CardIcon } from "assets/icons/icon_card.svg";
import { ReactComponent as CarwashIcon } from "assets/icons/icon_carwash.svg";
import { ReactComponent as ApplePayIcon } from "assets/icons/icon_cc_applepay.svg";
import { ReactComponent as GooglePayIcon } from "assets/icons/icon_cc_googleplay.svg";
import { ReactComponent as ChargePortCCSIcon } from "assets/icons/icon_chargeport_ccs.svg";
import { ReactComponent as ChargePortCHAdeMOIcon } from "assets/icons/icon_chargeport_chademo.svg";
import { ReactComponent as ChargePortJ1772Icon } from "assets/icons/icon_chargeport_j1772.svg";
import { ReactComponent as ChargePortTeslaIcon } from "assets/icons/icon_chargeport_tesla.svg";
import { ReactComponent as CheckIcon } from "assets/icons/icon_check.svg";
import { ReactComponent as ClockIcon } from "assets/icons/icon_clock.svg";
import { ReactComponent as CrossoverIcon } from "assets/icons/icon_crossover.svg";
import { ReactComponent as CurbsideIcon } from "assets/icons/icon_curbside.svg";
import { ReactComponent as CurbsideLgIcon } from "assets/icons/icon_curbside_lg.svg";
import { ReactComponent as CustomizationCheckIcon } from "assets/icons/icon_cust_check.svg";
import { ReactComponent as CustomizationUnCheckedIcon } from "assets/icons/icon_cust_unchecked.svg";
import { ReactComponent as DefaultStoreIcon } from "assets/icons/icon_default_store.svg";
import { ReactComponent as DeliveryIcon } from "assets/icons/icon_delivery.svg";
import { ReactComponent as DeliveryLgIcon } from "assets/icons/icon_delivery_lg.svg";
import { ReactComponent as DieselIcon } from "assets/icons/icon_diesel.svg";
import { ReactComponent as DiscoverIcon } from "assets/icons/icon_discover.svg";
import { ReactComponent as DriveThruIcon } from "assets/icons/icon_drive_thru.svg";
import { ReactComponent as E85Icon } from "assets/icons/icon_e85.svg";
import { ReactComponent as EditIcon } from "assets/icons/icon_edit.svg";
import { ReactComponent as EmptyBagIcon } from "assets/icons/icon_empty_bag.svg";
import { ReactComponent as EVChargerIcon } from "assets/icons/icon_evCharger.svg";
import { ReactComponent as ExclamationCircle } from "assets/icons/icon_exclamation_circle.svg";
import { ReactComponent as EyeballIcon } from "assets/icons/icon_eyeball.svg";
import { ReactComponent as FanIcon } from "assets/icons/icon_fan.svg";
import { ReactComponent as FBIcon } from "assets/icons/icon_fb.svg";
import { ReactComponent as FilterIcon } from "assets/icons/icon_filter.svg";
import { ReactComponent as FlexaIcon } from "assets/icons/icon_flexa.svg";
import { ReactComponent as FreakIcon } from "assets/icons/icon_freak.svg";
import { ReactComponent as FriendIcon } from "assets/icons/icon_friend.svg";
import { ReactComponent as FuelIcon } from "assets/icons/icon_fuel.svg";
import { ReactComponent as GearIcon } from "assets/icons/icon_gear.svg";
import { ReactComponent as HeartIcon } from "assets/icons/icon_heart.svg";
import { ReactComponent as HelpIcon } from "assets/icons/icon_help.svg";
import { ReactComponent as HighFlowAutoDieselIcon } from "assets/icons/icon_highflow_auto_diesel.svg";
import { ReactComponent as HomeIcon } from "assets/icons/icon_home.svg";
import { ReactComponent as IceCreamIcon } from "assets/icons/icon_icecream.svg";
import { ReactComponent as InsideSeatingIcon } from "assets/icons/icon_inside_seating.svg";
import { ReactComponent as InstaIcon } from "assets/icons/icon_insta.svg";
import { ReactComponent as KeroseneIcon } from "assets/icons/icon_kerosene.svg";
import { ReactComponent as ListIcon } from "assets/icons/icon_list.svg";
import { ReactComponent as LocationIcon } from "assets/icons/icon_location.svg";
import { ReactComponent as LockIcon } from "assets/icons/icon_lock.svg";
import { ReactComponent as MapPinIcon } from "assets/icons/icon_map_pin.svg";
import { ReactComponent as MastercardIcon } from "assets/icons/icon_mastercard.svg";
import { ReactComponent as MenuIcon } from "assets/icons/icon_menu.svg";
import { ReactComponent as MoreDotsIcon } from "assets/icons/icon_more_dots.svg";
import { ReactComponent as OfferIcon } from "assets/icons/icon_offer.svg";
import { ReactComponent as OnlineOrderIcon } from "assets/icons/icon_online_order.svg";
import { ReactComponent as OtherVehicleIcon } from "assets/icons/icon_other_vehicle.svg";
import { ReactComponent as PaypalIcon } from "assets/icons/icon_paypal.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/icon_phone.svg";
import { ReactComponent as PinIcon } from "assets/icons/icon_pin.svg";
import { ReactComponent as PlusIcon } from "assets/icons/icon_plus.svg";
import { ReactComponent as PNCIcon } from "assets/icons/icon_pnc.svg";
import { ReactComponent as PortionHeavyIcon } from "assets/icons/icon_portion_heavy.svg";
import { ReactComponent as PortionLiteIcon } from "assets/icons/icon_portion_lite.svg";
import { ReactComponent as PortionRegularIcon } from "assets/icons/icon_portion_regular.svg";
import { ReactComponent as PortionSideIcon } from "assets/icons/icon_portion_side.svg";
import { ReactComponent as PropaneIcon } from "assets/icons/icon_propane.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/icon_refresh.svg";
import { ReactComponent as RewardIcon } from "assets/icons/icon_reward.svg";
import { ReactComponent as Search } from "assets/icons/icon_search.svg";
import { ReactComponent as SeatingIcon } from "assets/icons/icon_seating.svg";
import { ReactComponent as SheetzCardIcon } from "assets/icons/icon_sheetzCard.svg";
import { ReactComponent as SheetzGo666SQIcon } from "assets/icons/icon_sheetz_go_666_sq.svg";
import { ReactComponent as ShowersIcon } from "assets/icons/icon_showers.svg";
import { ReactComponent as StarIcon } from "assets/icons/icon_star.svg";
import { ReactComponent as StoreIcon } from "assets/icons/icon_store.svg";
import { ReactComponent as StoreCarIcon } from "assets/icons/icon_store_car.svg";
import { ReactComponent as StoreClusterIcon } from "assets/icons/icon_store_cluster.svg";
import { ReactComponent as StorePinIcon } from "assets/icons/icon_store_pin.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/icons/icon_thumbs_up.svg";
import { ReactComponent as ToastedIcon } from "assets/icons/icon_toasted.svg";
import { ReactComponent as TobaccoIcon } from "assets/icons/icon_tobacco.svg";
import { ReactComponent as TrashIcon } from "assets/icons/icon_trash.svg";
import { ReactComponent as TravelPlazaIcon } from "assets/icons/icon_travel_plaza.svg";
import { ReactComponent as TruckIcon } from "assets/icons/icon_truck.svg";
import { ReactComponent as TruckDieselIcon } from "assets/icons/icon_truck_diesel.svg";
import { ReactComponent as TruckDieselLanesIcon } from "assets/icons/icon_truck_diesel_lanes.svg";
import { ReactComponent as TruckParkingIcon } from "assets/icons/icon_truck_parking.svg";
import { ReactComponent as TruckScalesIcon } from "assets/icons/icon_truck_scales.svg";
import { ReactComponent as TwitchIcon } from "assets/icons/icon_twitch.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/icon_twitter_x.svg";
import { ReactComponent as UserIcon } from "assets/icons/icon_user.svg";
import { ReactComponent as VanIcon } from "assets/icons/icon_van.svg";
import { ReactComponent as VehicleIcon } from "assets/icons/icon_vehicle.svg";
import { ReactComponent as VisaIcon } from "assets/icons/icon_visa_2023.svg";
import { ReactComponent as VoiceIcon } from "assets/icons/icon_voice.svg";
import { ReactComponent as WalletIcon } from "assets/icons/icon_wallet.svg";
import { ReactComponent as WifiIcon } from "assets/icons/icon_wifi.svg";
import { ReactComponent as WineGreyIcon } from "assets/icons/icon_wine_grey.svg";
import { ReactComponent as XIcon } from "assets/icons/icon_x.svg";
import { ReactComponent as ZSquadIcon } from "assets/icons/icon_zsquad.svg";

export enum IconType {
  accountInformation,
  accountPreferences,
  accountSecurity,
  alcohol,
  alert,
  amex,
  applepay,
  arrow,
  arrowUp,
  atm,
  autoDiesel,
  bag,
  beerGrey,
  beerWine,
  beerWineFiltersStoreDetail,
  bulkDef,
  burger,
  bybe,
  car,
  carEv,
  storeCar,
  card,
  carwash,
  chargePortCCS,
  chargePortCHAdeMO,
  chargePortJ1772,
  chargePortTesla,
  check,
  clock,
  crossover,
  curbside,
  curbsideLg,
  customizationCheck,
  customizationUnChecked,
  defaultStore,
  delivery,
  deliveryLg,
  diesel,
  discover,
  drivethru,
  e0,
  e15,
  e85,
  edit,
  emptyBag,
  evCharger,
  exclamationCircle,
  eyeball,
  fan,
  fb,
  filter,
  flexa,
  freak,
  ageicon,
  friend,
  fuel,
  gear,
  googlepay,
  heart,
  help,
  highFlowAutoDiesel,
  home,
  iceCream,
  insideSeating,
  insta,
  kerosene,
  list,
  location,
  lock,
  mapPin,
  mastercard,
  menu,
  mid89,
  moreDots,
  offer,
  onlineOrder,
  otherVehicle,
  paypal,
  phone,
  pin,
  plus,
  pnc,
  portionLite,
  portionRegular,
  portionHeavy,
  portionSide,
  prem93,
  propane,
  refresh,
  reg87,
  reward,
  search,
  seating,
  sheetzGo666SQ,
  sheetzCard,
  showers,
  star,
  store,
  storeCluster,
  storePin,
  SUV,
  thumbsUp,
  toasted,
  tobacco,
  trash,
  travelPlaza,
  truck,
  truckDiesel,
  truckDieselLanes,
  truckParking,
  truckScales,
  twitch,
  twitter,
  user,
  van,
  vehicle,
  visa,
  voice,
  wallet,
  wifi,
  wineGrey,
  x,
  zsquad,
}

export const getIcon = (icon: IconType, className: string): ReactElement => {
  switch (icon) {
    case IconType.accountInformation:
      return <AccountInformationIcon className={className} />;
    case IconType.accountPreferences:
      return <AccountPreferencesIcon className={className} />;
    case IconType.accountSecurity:
      return <AccountSecurityIcon className={className} />;
    case IconType.alcohol:
      return <AlcoholIcon className={className} />;
    case IconType.applepay:
      return <ApplePayIcon className={className} />;
    case IconType.storeCar:
      return <StoreCarIcon className={className} />;
    case IconType.amex:
      return <AmexIcon className={className} />;
    case IconType.arrow:
      return <ArrowIcon className={className} />;
    case IconType.arrowUp:
      return <ArrowUpIcon className={className} />;
    case IconType.atm:
      return <AtmIcon className={className} />;
    case IconType.autoDiesel:
      return <AutoDieselIcon className={className} />;
    case IconType.bag:
      return <BagIcon className={className} />;
    case IconType.beerGrey:
      return <BeerGreyIcon className={className} />;
    case IconType.beerWine:
      return <BeerWineIcon className={className} />;
    case IconType.beerWineFiltersStoreDetail:
      return <BeerWineFiltersStoreDetailIcon className={className} />;
    case IconType.bulkDef:
      return <BulkDefIcon className={className} />;
    case IconType.burger:
      return <BurgerIcon className={className} />;
    case IconType.bybe:
      return <BybeIcon className={className} />;
    case IconType.car:
      return <CarIcon className={className} />;
    case IconType.carEv:
      return <CarEvIcon className={className} />;
    case IconType.card:
      return <CardIcon className={className} />;
    case IconType.carwash:
      return <CarwashIcon className={className} />;
    case IconType.chargePortCCS:
      return <ChargePortCCSIcon className={className} />;
    case IconType.chargePortCHAdeMO:
      return <ChargePortCHAdeMOIcon className={className} />;
    case IconType.chargePortJ1772:
      return <ChargePortJ1772Icon className={className} />;
    case IconType.chargePortTesla:
      return <ChargePortTeslaIcon className={className} />;
    case IconType.check:
      return <CheckIcon className={className} />;
    case IconType.clock:
      return <ClockIcon className={className} />;
    case IconType.crossover:
      return <CrossoverIcon className={className} />;
    case IconType.curbside:
      return <CurbsideIcon className={className} />;
    case IconType.curbsideLg:
      return <CurbsideLgIcon className={className} />;
    case IconType.customizationCheck:
      return <CustomizationCheckIcon className={className} />;
    case IconType.customizationUnChecked:
      return <CustomizationUnCheckedIcon className={className} />;
    case IconType.defaultStore:
      return <DefaultStoreIcon className={className} />;
    case IconType.delivery:
      return <DeliveryIcon className={className} />;
    case IconType.deliveryLg:
      return <DeliveryLgIcon className={className} />;
    case IconType.diesel:
      return <DieselIcon className={className} />;
    case IconType.discover:
      return <DiscoverIcon className={className} />;
    case IconType.drivethru:
      return <DriveThruIcon className={className} />;
    case IconType.e0:
      return <E0Icon className={className} />;
    case IconType.e15:
      return <E15Icon className={className} />;
    case IconType.e85:
      return <E85Icon className={className} />;
    case IconType.edit:
      return <EditIcon className={className} />;
    case IconType.emptyBag:
      return <EmptyBagIcon className={className} />;
    case IconType.evCharger:
      return <EVChargerIcon className={className} />;
    case IconType.exclamationCircle:
      return <ExclamationCircle className={className} />;
    case IconType.eyeball:
      return <EyeballIcon className={className} />;
    case IconType.fan:
      return <FanIcon className={className} />;
    case IconType.fb:
      return <FBIcon className={className} />;
    case IconType.flexa:
      return <FlexaIcon className={className} />;
    case IconType.freak:
      return <FreakIcon className={className} />;
    case IconType.ageicon:
      return <AgeIcon className={className} />;
    case IconType.friend:
      return <FriendIcon className={className} />;
    case IconType.fuel:
      return <FuelIcon className={className} />;
    case IconType.filter:
      return <FilterIcon className={className} />;
    case IconType.gear:
      return <GearIcon className={className} />;
    case IconType.googlepay:
      return <GooglePayIcon className={className} />;
    case IconType.help:
      return <HelpIcon className={className} />;
    case IconType.highFlowAutoDiesel:
      return <HighFlowAutoDieselIcon className={className} />;
    case IconType.home:
      return <HomeIcon className={className} />;
    case IconType.iceCream:
      return <IceCreamIcon className={className} />;
    case IconType.insideSeating:
      return <InsideSeatingIcon className={className} />;
    case IconType.heart:
      return <HeartIcon className={className} />;
    case IconType.insta:
      return <InstaIcon className={className} />;
    case IconType.kerosene:
      return <KeroseneIcon className={className} />;
    case IconType.list:
      return <ListIcon className={className} />;
    case IconType.location:
      return <LocationIcon className={className} />;
    case IconType.lock:
      return <LockIcon className={className} />;
    case IconType.mapPin:
      return <MapPinIcon className={className} />;
    case IconType.mastercard:
      return <MastercardIcon className={className} />;
    case IconType.menu:
      return <MenuIcon className={className} />;
    case IconType.mid89:
      return <Mid89Icon className={className} />;
    case IconType.moreDots:
      return <MoreDotsIcon className={className} />;
    case IconType.offer:
      return <OfferIcon className={className} />;
    case IconType.onlineOrder:
      return <OnlineOrderIcon className={className} />;
    case IconType.otherVehicle:
      return <OtherVehicleIcon className={className} />;
    case IconType.paypal:
      return <PaypalIcon className={className} />;
    case IconType.phone:
      return <PhoneIcon className={className} />;
    case IconType.pin:
      return <PinIcon className={className} />;
    case IconType.plus:
      return <PlusIcon className={className} />;
    case IconType.pnc:
      return <PNCIcon className={className} />;
    case IconType.portionLite:
      return <PortionLiteIcon className={className} />;
    case IconType.portionRegular:
      return <PortionRegularIcon className={className} />;
    case IconType.portionHeavy:
      return <PortionHeavyIcon className={className} />;
    case IconType.portionSide:
      return <PortionSideIcon className={className} />;
    case IconType.prem93:
      return <Prem93Icon className={className} />;
    case IconType.propane:
      return <PropaneIcon className={className} />;
    case IconType.refresh:
      return <RefreshIcon className={className} />;
    case IconType.reg87:
      return <Reg87Icon className={className} />;
    case IconType.reward:
      return <RewardIcon className={className} />;
    case IconType.search:
      return <Search className={className} />;
    case IconType.seating:
      return <SeatingIcon className={className} />;
    case IconType.sheetzGo666SQ:
      return <SheetzGo666SQIcon className={className} />;
    case IconType.sheetzCard:
      return <SheetzCardIcon className={className} />;
    case IconType.showers:
      return <ShowersIcon className={className} />;
    case IconType.star:
      return <StarIcon className={className} />;
    case IconType.store:
      return <StoreIcon className={className} />;
    case IconType.storeCluster:
      return <StoreClusterIcon className={className} />;
    case IconType.storePin:
      return <StorePinIcon className={className} />;
    case IconType.SUV:
      return <SUVIcon className={className} />;
    case IconType.thumbsUp:
      return <ThumbsUpIcon className={className} />;
    case IconType.toasted:
      return <ToastedIcon className={className} />;
    case IconType.tobacco:
      return <TobaccoIcon className={className} />;
    case IconType.trash:
      return <TrashIcon className={className} />;
    case IconType.travelPlaza:
      return <TravelPlazaIcon className={className} />;
    case IconType.truck:
      return <TruckIcon className={className} />;
    case IconType.truckDiesel:
      return <TruckDieselIcon className={className} />;
    case IconType.truckDieselLanes:
      return <TruckDieselLanesIcon className={className} />;
    case IconType.truckParking:
      return <TruckParkingIcon className={className} />;
    case IconType.truckScales:
      return <TruckScalesIcon className={className} />;
    case IconType.twitch:
      return <TwitchIcon className={className} />;
    case IconType.twitter:
      return <TwitterIcon className={className} />;
    case IconType.user:
      return <UserIcon className={className} />;
    case IconType.van:
      return <VanIcon className={className} />;
    case IconType.vehicle:
      return <VehicleIcon className={className} />;
    case IconType.visa:
      return <VisaIcon className={className} />;
    case IconType.voice:
      return <VoiceIcon className={className} />;
    case IconType.wallet:
      return <WalletIcon className={className} />;
    case IconType.wifi:
      return <WifiIcon className={className} />;
    case IconType.wineGrey:
      return <WineGreyIcon className={className} />;
    case IconType.x:
      return <XIcon className={className} />;
    case IconType.zsquad:
      return <ZSquadIcon className={className} />;
    case IconType.alert:
    default:
      return <AlertIcon className={className} />;
  }
};
