import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "../BagItem/BagItem.scss";

import EditCombo from "components/Order/ItemCustomization/Combo/EditCombo/EditCombo";
import { OrderSubviewProps } from "components/Order/Order";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ItemDisplay from "components/misc/item/ItemDisplay/ItemDisplay";
import QuantitySelector from "components/misc/item/QuantitySelector/QuantitySelector";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import SheetzModal from "components/misc/view/SheetzModal/SheetzModal";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { ShoppingBagCombo } from "util/Bag.util";
import { isReorderedShoppingBagItem } from "util/Reorder.util";

interface BagComboProps extends OrderSubviewProps {
  shoppingBagCombo: ShoppingBagCombo;
  closeBagFn: () => void;
}

const BagCombo = (props: BagComboProps) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const bagEditState = location.state as { editComboId: number } | undefined;
  const [showEditQuantity, setShowEditQuantity] = useState<boolean>(false);
  const [showRemoveCombo, setShowRemoveCombo] = useState<boolean>(false);
  const [showEditComboModal, setShowEditComboModal] = useState<boolean>(false);

  // If returning from a combo item edit, we'll need to show the combo edit modal again
  useEffect(() => {
    if (bagEditState?.editComboId && props.shoppingBagCombo.id === bagEditState.editComboId) {
      editCombo();

      appContext.showToast("Item Updated!", "", ToastType.success, true);
    }
    // Disable lint check due to numerous dependencies involved in useEffect hook above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bagEditState?.editComboId]);

  function updateComboQuantity(newQuantity: number): void {
    props.dispatch({
      type: "UPDATE_BAG_COMBO_QUANTITY",
      payload: [props.shoppingBagCombo.id, newQuantity],
    });
    setShowEditQuantity(false);
  }

  function removeCombo(): void {
    props.dispatch({
      type: "REMOVE_COMBO_FROM_BAG",
      payload: props.shoppingBagCombo.id,
    });
  }

  function editCombo(): void {
    setShowEditComboModal(true);
  }

  function closeEditComboModal(): void {
    // If the state is such that we are showing the combo edit modal during an edit, revert the state when the modal is closed.
    if (bagEditState?.editComboId) {
      window.history.replaceState({ ...bagEditState, editComboId: undefined }, "");
    }
    setShowEditComboModal(false);
  }

  return (
    <>
      <ItemDisplay
        closeBagFn={props.closeBagFn}
        editable={!isReorderedShoppingBagItem(props.shoppingBagCombo.items[0])}
        editCombo={editCombo}
        item={props.shoppingBagCombo}
        sidePadding
        showEditQuantity={setShowEditQuantity}
        showRemoveButton
        showRemoveItem={setShowRemoveCombo}
      />
      <ActionSheet
        color={ActionSheetColor.red}
        title="How Many?"
        overlay={true}
        shouldDisplay={showEditQuantity}
        required={false}
        onOverlayClick={(): void => {
          setShowEditQuantity(false);
        }}
        cancelFunction={(): void => {
          setShowEditQuantity(false);
        }}
      >
        <QuantitySelector
          selectedQuantity={props.shoppingBagCombo.quantity}
          setQuantity={updateComboQuantity}
        />
      </ActionSheet>
      <ActionSheet
        color={ActionSheetColor.red}
        title="Are You Sure?"
        overlay={true}
        shouldDisplay={showRemoveCombo}
        required={true}
        onOverlayClick={(): void => {
          setShowRemoveCombo(false);
        }}
        cancelFunction={(): void => {
          setShowRemoveCombo(false);
        }}
      >
        <div className="remove-item-container">
          <div className="remove-item-header">Do you want to remove the following item?</div>
          <ItemDisplay
            closeBagFn={props.closeBagFn}
            editCombo={editCombo}
            item={props.shoppingBagCombo}
            showEditQuantity={setShowEditQuantity}
            showRemoveItem={setShowRemoveCombo}
          />
          <div className="remove-item-flex-container">
            <SheetzTextButton
              textDark
              label="Cancel"
              onClick={(): void => setShowRemoveCombo(false)}
            />
            <SheetzButton
              className="remove-item-button"
              label="Remove"
              onClick={(): void => {
                removeCombo();
                setShowRemoveCombo(false);
              }}
            />
          </div>
        </div>
      </ActionSheet>
      {showEditComboModal && (
        <SheetzModal
          className="edit-combo-modal"
          isOpen={showEditComboModal}
          closeFunction={closeEditComboModal}
          contentLabel="Edit My Combo"
          onRequestClose={closeEditComboModal}
          shouldCloseOnOverlayClick={false}
          headerText="My Combo"
        >
          {props.shoppingBagCombo && (
            <EditCombo
              shoppingBagCombo={props.shoppingBagCombo}
              onDoneEditing={closeEditComboModal}
            />
          )}
        </SheetzModal>
      )}
    </>
  );
};

export default BagCombo;
