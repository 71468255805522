import React, { useState } from "react";

import { Selector } from "assets/dtos/anywhere-dto";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SelectorHeader from "components/Order/ItemCustomization/SelectorHeader/SelectorHeader";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import ActionSheetGrid from "components/misc/view/ActionSheet/ActionSheetGrid/ActionSheetGrid";

import { ItemCustomization, SizeSelectOption } from "util/Customization.util";

interface SizeSelectorProps {
  selector: Selector;
  sizeSelectOptions: SizeSelectOption[];
  selectedOption?: SizeSelectOption;
  itemCustomization: ItemCustomization;
  onSizeOptionSelected: (sizeOption: SizeSelectOption) => void;
}

const SizeSelector = (props: SizeSelectorProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedSizeOption, setSelectedSizeOption] = useState<SizeSelectOption | undefined>();

  const options: JSX.Element[] = props.sizeSelectOptions.map((sizeSelectOption) => {
    const isSelected =
      props.selectedOption &&
      sizeSelectOption.retailModifiedItem.retailModifiedItemId ===
        props.selectedOption.retailModifiedItem.retailModifiedItemId;

    return (
      <div key={sizeSelectOption.option.text + "_" + sizeSelectOption.option.sizeTags?.toString()}>
        {props.itemCustomization ? (
          <CustomizationOption
            type="SIZE"
            isSelected={isSelected}
            sizeSelectOption={sizeSelectOption}
            onSizeOptionSelected={handleOptionSelected}
          />
        ) : null}
      </div>
    );
  });

  function handleOptionSelected(sizeSelectorOption: SizeSelectOption): void {
    /**
     * Only show the warning if the size selector is NOT displayed as an action sheet.
     * This is because the ChangeSizeButton component already shows a warning before
     * displaying this selector.
     */
    if (props.selector.displayAsActionSheet) {
      props.onSizeOptionSelected(sizeSelectorOption);
    } else {
      if (props.selectedOption) {
        setShowWarning(true);
      } else {
        props.onSizeOptionSelected(sizeSelectorOption);
      }
      setSelectedSizeOption(sizeSelectorOption);
    }
  }

  if (props.selector.displayAsActionSheet) {
    return <ActionSheetGrid>{options}</ActionSheetGrid>;
  } else {
    return (
      <>
        <SelectorHeader headerText={props.selector.text ?? ""} maxSelections={1} />
        <CondimentGrid>{options}</CondimentGrid>
        <ActionSheet
          color={ActionSheetColor.error}
          title="SON OF A SHMISCUIT!"
          text="Changing your size will remove any customizations in place!"
          overlay={true}
          shouldDisplay={showWarning}
          required
          onOverlayClick={(): void => {
            setShowWarning(false);
          }}
        >
          <div className="warning-flex-container">
            <SheetzTextButton
              textDark
              label="No, Thanks"
              onClick={(): void => setShowWarning(false)}
            />
            <SheetzButton
              className="continue-button"
              label="Continue"
              onClick={(): void => {
                setShowWarning(false);
                if (selectedSizeOption) {
                  props.onSizeOptionSelected(selectedSizeOption);
                }
              }}
            />
          </div>
        </ActionSheet>
      </>
    );
  }
};

export default SizeSelector;
