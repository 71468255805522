import React from "react";

import "./ReorderAvailabilityActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { UnavailabilityDetails } from "util/Reorder.util";

interface ReorderAvailabilityActionSheetProps {
  show: boolean;
  unavailabilityDetails?: UnavailabilityDetails;
  onOrderWithoutPressed: () => void;
  onCancelPressed: () => void;
}

const ReorderAvailabilityActionSheet = (props: ReorderAvailabilityActionSheetProps) => {
  return (
    <ActionSheet
      color={ActionSheetColor.error}
      title="It's not you, it's us."
      overlay={true}
      shouldDisplay={props.show}
      required={true}
    >
      <div className="unavailability-action-sheet-container">
        {props.unavailabilityDetails && props.unavailabilityDetails.noLongerOffer && (
          <div className="unavailability-details-container">
            <p>We no longer offer:</p>
            <p>{Array.from(props.unavailabilityDetails.noLongerOffer).join(", ")}</p>
          </div>
        )}
        {props.unavailabilityDetails && props.unavailabilityDetails.currentlyUnavailable && (
          <div className="unavailability-details-container">
            <p>Currently Unavailable:</p>
            <p>{Array.from(props.unavailabilityDetails.currentlyUnavailable).join(", ")}</p>
          </div>
        )}
        {props.unavailabilityDetails && props.unavailabilityDetails.temporarilyUnavailable && (
          <div className="unavailability-details-container">
            <p>Temporarily Unavailable:</p>
            <p>{Array.from(props.unavailabilityDetails.temporarilyUnavailable).join(", ")}</p>
          </div>
        )}
        {props.unavailabilityDetails && props.unavailabilityDetails.unavailableAtStore && (
          <div className="unavailability-details-container">
            <p>Unavailable at this store:</p>
            <p>{Array.from(props.unavailabilityDetails.unavailableAtStore).join(", ")}</p>
          </div>
        )}
        {props.unavailabilityDetails && props.unavailabilityDetails.unavailableAsCustomized && (
          <div className="unavailability-details-container">
            <p>Unavailable as customized, but available in the menu:</p>
            <p>{Array.from(props.unavailabilityDetails.unavailableAsCustomized).join(", ")}</p>
          </div>
        )}
        <SheetzButton
          transparentDark
          label="Order without"
          className="order-without-button"
          onClick={props.onOrderWithoutPressed}
        />
        <SheetzButton
          transparentDark
          label="Cancel"
          className="cancel-order-button"
          onClick={props.onCancelPressed}
        />
      </div>
    </ActionSheet>
  );
};

export default ReorderAvailabilityActionSheet;
