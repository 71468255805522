import classNames from "classnames";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./AddedToBagNotice.scss";

import { ReactComponent as Triangle } from "assets/icons/icon_triangle.svg";
import { ReactComponent as Swoop } from "assets/swoops/added_to_bag_swoop.svg";

import SheetzButton, { ButtonColor } from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import FlexContainer from "components/misc/containers/FlexContainer";

import { useMediaQuery } from "hooks";

import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";

interface AddedToBagNoticeProps {
  checkoutButtonPressed: () => void;
  toggleBag: () => void;
}

const AddedToBagNotice = (props: AddedToBagNoticeProps) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);

  const addedToBagContainerClasses = classNames(
    "added-to-bag-container",
    appContext.addToBagPopup.show ? " show " : " hide ",
    { "in-mobile-app": isInNativeMobileContext() }
  );

  return (
    <div className={addedToBagContainerClasses}>
      {useDesktopView ? (
        <Triangle className="added-to-bag-triangle" />
      ) : (
        <Swoop className="added-to-bag-swoop" />
      )}
      <div className="added-to-bag-label">
        {getIcon(IconType.check, "added-to-bag-check")}
        <span className="added-to-bag-title">{appContext.addToBagPopup.title}</span>
      </div>
      {useDesktopView && (
        <SheetzButton
          buttonColor={ButtonColor.white}
          label="Checkout"
          className="added-to-bag-checkout-button"
          onClick={props.checkoutButtonPressed}
        />
      )}

      {!useDesktopView && (
        <div className="button-container">
          <FlexContainer flexStyles={{ gap: "12px" }}>
            <SheetzButton
              transparentLight
              label="Main Menu"
              className="added-to-bag-main-menu-button"
              onClick={(): void => {
                appContext.hideAddToBagPopup();
                navigate("/order/menu");
              }}
            />
            <SheetzButton
              label="Checkout"
              className="added-to-bag-checkout-button"
              borderColor="light-border"
              onClick={(): void => {
                appContext.hideAddToBagPopup();
                props.checkoutButtonPressed();
              }}
            />
          </FlexContainer>
        </div>
      )}

      {useDesktopView && (
        <SheetzTextButton
          textDark
          label="View Bag"
          className="added-to-bag-toggle-button"
          onClick={props.toggleBag}
        />
      )}
    </div>
  );
};

export default AddedToBagNotice;
