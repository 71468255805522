import axios, { AxiosError } from "axios";
import { Field, Formik, FormikProps } from "formik";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { BFFErrorResponse } from "axiosConfig";

import "./AgeVerification.scss";

import {
  IdentityAnswer,
  IdentityQuestion,
  IdentityQuestionsRequest,
  IdentityVerificationRequest,
} from "assets/dtos/anywhere-dto";

import AgeVerificationHeader from "components/Account/AgeVerification/AgeVerificationHeader/AgeVerificationHeader";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";

import { identityQuestionsAnswers, verifyIdentityQuestions } from "util/Account.util";
import { AppContext } from "util/AppContext.util";

const IdentityQuestions = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [countdown, setCountdown] = useState("90");
  const [showCountdown, setShowCountdown] = useState(false);
  const [questions, setQuestions] = useState<IdentityQuestion[]>([]);
  const [identityVerificationId, setIdentityVerificationId] = useState<number>(0);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const baseUrl = queryParams.baseUrl as string | undefined;
  const destination = queryParams.destination as string | undefined;

  const identityQuestionsRequest = location.state as IdentityQuestionsRequest;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationObject: { [key: string]: any } = {};
  const showConfirmationActionSheet = appContext.showConfirmationActionSheet;

  useEffect(() => {
    appContext.showLoading();

    verifyIdentityQuestions(identityQuestionsRequest)
      .then((response) => {
        appContext.hideLoading();
        setQuestions(response.data.questions);
        setIdentityVerificationId(response.data.identityVerificationId);
      })

      .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            setTimeout(() => {
              appContext.hideToast();
            }, 1000);
            navigate("/order/menu");
            appContext.showAlertActionSheet(
              "Son of a Shmiscuit! There’s an issue with our identity validation service. Please try again or call Customer Service at 1(800) 765-4686 to manually validate your identity.",
              undefined,
              "Oh Sheetz!"
            );
          }
        }
      })
      .finally(() => {
        appContext.hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (questions) {
      timer = setInterval(() => {
        if (Number(countdown) === 30) {
          setShowCountdown(true);
        }
        if (Number(countdown) > 0) {
          const value = Number(countdown) - 1;
          const finalValue = value < 10 ? "0" + value : value.toString();
          setCountdown(finalValue);
        } else {
          showConfirmationActionSheet(
            "Son of a Shmiscuit! Time limit exceeded. Please try again.",
            "Retry",
            retryIdentityQuestions
          );
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [countdown, questions, showConfirmationActionSheet]);

  function retryIdentityQuestions(): void {
    window.location.reload();
  }

  const questionList = questions?.map((question, questionIndex) => {
    const answerList = question.questionAnswers?.map((answer, answerIndex) => {
      // create validation object
      validationObject[question.questionId] = Yup.string().required("Required");

      return (
        <div className="radio" key={questionIndex + "_" + answerIndex}>
          <label htmlFor={answer}>
            <Field type="radio" name={question.questionId} value={answer} id={answer} required />
            {answer}
          </label>
        </div>
      );
    });

    return (
      <div className="list-questions" key={questionIndex}>
        <div className="question-text">{question.questionText}</div>
        <div className="question-answer" role="group">
          {answerList}
        </div>
      </div>
    );
  });

  function onCancel(): void {
    if (baseUrl) {
      window.location.href = baseUrl;
    } else if (destination && destination !== "/account/ageRestrictedOffers") {
      navigate(destination);
    } else {
      navigate(-1);
    }
  }

  function onSubmit(values: Record<string, string>): void {
    const answers: IdentityAnswer[] = [];

    for (const key in values) {
      answers.push({ questionId: key, questionAnswer: values[key] });
    }
    const request: IdentityVerificationRequest = {
      identityVerificationId: identityVerificationId,
      answers: answers,
    };

    identityQuestionsAnswers(request)
      .then(() => {
        if (baseUrl) {
          appContext.showAlertActionSheet(
            "Successfully Age Verified!",
            () => (window.location.href = baseUrl),
            "Success!"
          );
        } else if (destination !== undefined) {
          appContext.showAlertActionSheet(
            "Successfully Age Verified!",
            () => navigate(destination, { replace: true }),
            "Success!"
          );
        } else {
          appContext.showAlertActionSheet(
            "Successfully Age Verified!",
            () => {
              window.open("https://www.sheetz.com/tobacco", "_blank");
              navigate("/order/information");
            },
            "Success!"
          );
        }
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>): void => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 400) {
            appContext.showConfirmationActionSheet(
              "Your info may be incorrect. Please check all info and try again.",
              "Retry",
              retryIdentityQuestions
            );
          }
        }
      });
  }

  if (questions && questions.length > 0) {
    return (
      <div className="verify-age-container">
        <AgeVerificationHeader />
        <div className="verify-your-age-form-container">
          <ResponsiveLayoutContainer>
            <Formik
              initialValues={{}}
              onSubmit={(values: Record<string, string>): void => {
                onSubmit(values);
              }}
              validationSchema={Yup.object().shape(validationObject)}
            >
              {(formProps: FormikProps<Record<string, string>>) => (
                <form onSubmit={formProps.handleSubmit}>
                  <div className="my-questions-container">{questionList}</div>

                  <SheetzButton
                    className="submit-identity-button"
                    label={showCountdown ? "Continue - 0:" + countdown : " Continue"}
                    type="submit"
                  />

                  <SheetzButton
                    transparentDark
                    className="submit-identity-cancel-button"
                    label="Cancel"
                    type="button"
                    onClick={onCancel}
                  />
                </form>
              )}
            </Formik>
          </ResponsiveLayoutContainer>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default IdentityQuestions;
