import { AxiosError } from "axios";
import { Formik, FormikProps } from "formik";
import qs from "qs";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { BFFErrorResponse } from "axiosConfig";

import "./DeferredFavorite.scss";

import { DeferredFavoriteOrder, Item } from "assets/dtos/anywhere-dto";

import ReorderDetailListItem from "components/Order/Reorder/ReorderDetailListItem/ReorderDetailListItem";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { AppContext } from "util/AppContext.util";
import {
  addDeferredFavoriteOrder,
  deleteDeferredFavoriteOrder,
  getDeferredFavoriteOrder,
} from "util/DeferredFavorite.util";

interface DeferredFavoriteFormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Required").max(50),
});

const DeferredFavorite = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deferredFavoriteOrder, setDeferredFavoriteOrder] = useState<DeferredFavoriteOrder>();
  const appContext = useContext(AppContext);
  const initialFormValues: DeferredFavoriteFormValues = { name: "" as string };

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    appContext.showLoading();
    getDeferredFavoriteOrder(queryParams.rmt as string)
      .then((response) => {
        setDeferredFavoriteOrder(response.data.order);
      })
      .catch((error: AxiosError<BFFErrorResponse>): void => {
        if (error.response && error.response?.status !== 401) {
          appContext.showAlertActionSheet(
            "This order has already been added or cannot be found.",
            () => navigate("/order/favorites")
          );
        }
      })
      .finally(() => {
        appContext.hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addToFavorites(newName: string): void {
    addDeferredFavoriteOrder({
      deferredFavoriteOrderId: deferredFavoriteOrder?.deferredFavoriteOrderId as number,
      rememberMeToken: deferredFavoriteOrder?.rememberMeToken as string,
      name: newName,
    })
      .then(() => {
        navigate("/order/favorites");
      })
      .catch((error: AxiosError<BFFErrorResponse>): void => {
        if (error.response) {
          appContext.showAlertActionSheet("Favorite not added", () => navigate("/order/favorites"));
        }
      });
  }

  function noThanksPressed(): void {
    deleteDeferredFavoriteOrder(
      deferredFavoriteOrder?.deferredFavoriteOrderId as number,
      deferredFavoriteOrder?.rememberMeToken as string
    ).then(() => {
      navigate("/order/favorites");
    });
  }

  function createDeferredFavoriteItem(item: Item, index: number): ReactElement {
    let condiments = "";
    item.condiments?.forEach((condiment) => {
      condiments += condiments.length === 0 ? condiment.receiptText : ", " + condiment.receiptText;
    });

    return (
      <li key={index}>
        <ReorderDetailListItem
          type={"favoriteOrderItem"}
          description={condiments}
          image={item.image}
          name={item.receiptText}
          quantity={item.quantity}
          available={true}
          price={0}
          unactionableOrder={false}
        />
      </li>
    );
  }

  return (
    <>
      <div className="edit-favorite-container">
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values): void => {
            addToFavorites(values.name);
          }}
        >
          {(formProps: FormikProps<DeferredFavoriteFormValues>): ReactElement => (
            <form onSubmit={formProps.handleSubmit} id="deferredFavoriteForm" autoComplete="off">
              <div className="favorite-details-scroll-container">
                <div className="deferred-favorite-title">ADD TO YOUR FAVORITES?</div>

                <SheetzInput type="text" name="name" label="Name it" placeholder="Name it" />

                <ul className="favorite-order-item-list">
                  {deferredFavoriteOrder?.items.map(createDeferredFavoriteItem)}
                </ul>
              </div>
              <div className="edit-favorite-footer">
                <SheetzButton label="Add to Favorites" type="submit" />
                <SheetzButton
                  transparentLight
                  label="No thanks"
                  type="button"
                  onClick={noThanksPressed}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DeferredFavorite;
