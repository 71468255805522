import React, { useState } from "react";

import { Condiment, Selector } from "assets/dtos/anywhere-dto";
import { ReactComponent as BottomSwoop } from "assets/swoops/action_swoop_bottom.svg";
import { ReactComponent as TopSwoop } from "assets/swoops/action_swoop_top.svg";

import CondimentGrid from "components/Order/ItemCustomization/CondimentGrid/CondimentGrid";
import CustomizationOption from "components/Order/ItemCustomization/CustomizationOption/CustomizationOption";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { InlineSubOption, MultiOptionSwitchOption } from "util/Customization.util";

interface MultiOptionSwitchSelectorProps {
  selector: Selector;
  options: MultiOptionSwitchOption[];
  selectedOption?: MultiOptionSwitchOption;
  onSwitchOptionSelected: (option: MultiOptionSwitchOption) => void;
  onSwitchOptionDeselected: () => void;
}

const MultiOptionSwitchSelector = (props: MultiOptionSwitchSelectorProps) => {
  const [showInlineSubActionSheet, setShowInlineSubActionSheet] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<MultiOptionSwitchOption>();

  const options = props.options.map((multiOptionSwitchOption) => {
    const isSelected =
      props.selectedOption &&
      multiOptionSwitchOption.condiment.retailModifiedItem.retailModifiedItemId ===
        props.selectedOption.condiment.retailModifiedItem.retailModifiedItemId;
    return (
      <CustomizationOption
        key={multiOptionSwitchOption.option.text}
        option={multiOptionSwitchOption.option}
        type={props.selector.type}
        condiment={multiOptionSwitchOption.condiment}
        isSelected={isSelected}
        onCondimentOptionSelected={switchOptionCondimentSelected}
        isGreenBorder={props.selector.theme === "ULTIMATE"}
      />
    );
  });

  function createOptionButtons(): JSX.Element {
    let buttons = null;

    if (selectedOption?.option.inlineSubSelector && selectedOption.inlineSubOptions?.length) {
      buttons = selectedOption.inlineSubOptions.map((inlineSubOption) => {
        return (
          <li key={inlineSubOption.option.text}>
            <SheetzButton
              transparentDark
              className="inline-sub-option-button"
              label={inlineSubOption.option.text}
              label2={"+" + inlineSubOption.condiment.retailModifiedItem.price.toFixed(2)}
              onClick={(): void => {
                setShowInlineSubActionSheet(false);
                inlineSubOptionSelected(inlineSubOption);
              }}
            />
          </li>
        );
      });
    }

    return <ul className="inline-options-list">{buttons}</ul>;
  }

  function inlineSubOptionSelected(inlineSubOption: InlineSubOption): void {
    const selectedMultiOptionSwitchOption = {
      ...selectedOption,
      selectedInlineSubOption: inlineSubOption,
    } as MultiOptionSwitchOption;
    props.onSwitchOptionSelected(selectedMultiOptionSwitchOption);
  }

  function switchOptionCondimentSelected(condiment: Condiment): void {
    const newlySelectedOption = props.options.find(
      (option) =>
        option.condiment.retailModifiedItem.retailModifiedItemId ===
        condiment.retailModifiedItem.retailModifiedItemId
    );

    if (!newlySelectedOption) {
      return;
    }

    // If the newly selected condiment is the same as the currently selected condiment, then un-switch.
    if (
      props.selectedOption?.condiment.retailModifiedItem.retailModifiedItemId ===
      condiment.retailModifiedItem.retailModifiedItemId
    ) {
      setShowInlineSubActionSheet(false);
      props.onSwitchOptionDeselected();
    } else {
      // Show the action sheet if the option contains an inline sub selector.
      if (
        newlySelectedOption.option.inlineSubSelector &&
        newlySelectedOption.inlineSubOptions?.length
      ) {
        setShowInlineSubActionSheet(true);
        setSelectedOption(newlySelectedOption);
        return;
      } else {
        props.onSwitchOptionSelected(newlySelectedOption);
      }
    }
  }

  return (
    <>
      <div className={"multi-option-switch-selector " + props.selector.theme}>
        <TopSwoop className="top-swoop swoop" />
        <div className="selector-content">
          <p className="selector-title">{props.selector.text}</p>
          <p className="selector-sub-title">{props.selector.longText}</p>
          <CondimentGrid>{options}</CondimentGrid>
        </div>
        <BottomSwoop className="bottom-swoop swoop" />
      </div>
      <ActionSheet
        color={ActionSheetColor.red}
        title={selectedOption?.option.inlineSubSelector?.text ?? "Choose an option"}
        overlay={true}
        shouldDisplay={showInlineSubActionSheet}
        onOverlayClick={(): void => {
          setShowInlineSubActionSheet(false);
          setSelectedOption(undefined);
        }}
        cancelFunction={(): void => {
          setShowInlineSubActionSheet(false);
          setSelectedOption(undefined);
        }}
      >
        {props.selector?.options && createOptionButtons()}
      </ActionSheet>
    </>
  );
};

export default MultiOptionSwitchSelector;
