import { SubmitOrderResponse } from "assets/dtos/anywhere-dto";

import { getAuthTokenPayload } from "util/Authentication.util";

/**
 * This interface represents functions that communicate FROM the native apps TO Anywhere.
 * That is to say, the mobile apps initiate these calls. Anywhere is responsible for implementing these
 * and ensuring they are available in the `window.anywhere` namespace.
 * The native apps are responsible for calling these functions when needed.
 */
export interface AnywhereNativeMobile {
  closeBag: () => void;
  closeSearch: () => void;
  isBagOpen: () => boolean;
  isSearchOpen: () => boolean;
  nativeWalletCanceled: () => void;
  pinCreateUpdateStatus: (status: boolean) => void;
  setMobileAccountPreferences: (mobileAccountPreferences: string) => void;
  setMobilePayAuthorization: (base64Data: string, iOSBillingAddress: string | undefined) => void;
  setPaymentMethodId: (paymentMethodId: number) => void;
}

/**
 * This interfaces represent functions that communicate FROM Anywhere TO the native apps. That is to say,
 * Anywhere initiates these calls. The native apps will inject implementations for all methods in the
 * namespace which in turn will call native code. Anywhere is responsible for calling these functions
 * with the appropriate arguments when needed.
 *
 * NOTE: Anywhere could provide the implementations for these functions, however they are mobile
 * platform-specific and could change over time.
 */

/**
 * `window.sheetzNativeOrderz`
 */
export interface SheetzNativeOrderz {
  authTokenInvalid: () => void;
  closeWebView?: () => void;
  downloadUserData: () => void;
  finishMobileOrder: (order: string) => void;
  getAuthToken: () => string | null;
  getMobileAccountPreferences: () => string;
  getMobilePayAuthorization: (totalAmount: number) => void;
  getMobilePayStatus: () => string;
  getRavelinId?: () => string | null;
  hideLoadingIndicator: () => void;
  openAccountSettingsWebView: () => void;
  setPageTitle: (title: string) => void;
  showBioMetrics: (status: boolean) => void;
  showCreateUpdatePin: () => void;
  showDialog?: (error: boolean, title: string | null, description: string) => void;
  showLoadingIndicator: () => void;
  showNativeWallet: (orderTotal: number) => void;
  showPushNotifications: (status: boolean) => void;
  showSuccess?: (title: string, subtext: string) => void;
  storeSelectionLoaded?: () => void;
  storeSelectionUnloaded?: () => void;
}

/**
 * authTokenInvalid
 */
export function nativeMobileAuthTokenInvalid(): void {
  window.sheetzNativeOrderz?.authTokenInvalid();
}

/**
 * closeWebView
 */
export function closeWebView(): void {
  window.sheetzNativeOrderz?.closeWebView?.();
}

/**
 * downloadUserData
 */

export function downloadUserData(): void {
  window.sheetzNativeOrderz?.downloadUserData();
}

/**
 * finishMobileOrder
 */
export function finishMobileOrder(order: SubmitOrderResponse & { isVisa: boolean }): void {
  window.sheetzNativeOrderz?.finishMobileOrder(JSON.stringify(order));
}

/**
 * getAuthToken
 */
export function getAuthTokenFromNativeMobileBridge(): string | undefined | null {
  return window.sheetzNativeOrderz?.getAuthToken();
}

/**
 * getMobileAccountPreferences
 */
export function getAccountSettingsPreferences(): Promise<string | undefined> {
  /**
   * Create a promise that the mobile apps will resolve/reject when
   * a push notification or bioMetrics update or cancellation
   * is complete. The mobile apps will do this by calling
   * one of the functions below that are added to the `window.anywhere`
   * namespace. These functions will resolve with the passed in mobileAccountPreferences.
   */
  const accountSettingsPreferences = new Promise<string | undefined>((resolve, reject) => {
    const setMobileAccountPreferences = (mobileAccountPreferences: string): void => {
      if (mobileAccountPreferences) {
        resolve(mobileAccountPreferences);
      } else {
        reject(undefined);
      }
    };

    if (window.anywhere !== undefined) {
      window.anywhere.setMobileAccountPreferences = setMobileAccountPreferences;
    }
  });

  window.sheetzNativeOrderz?.getMobileAccountPreferences();

  return accountSettingsPreferences;
}

/**
 * getMobilePayAuthorization
 */
export function getMobilePaymentAuthorization(
  totalAmount: number
): Promise<[string, string | undefined] | undefined> {
  /**
   * Create a promise that the mobile apps will resolve/reject when Apple Pay / Google Pay
   * or cancellation is complete. The mobile apps will do this by calling
   * one of the functions below that are added to the `window.anywhere`
   * namespace. These functions will resolve with the passed base64 encoded string or
   * reject the promise if Apple Pay / Google Pay was canceled.
   */
  const mobilePaymentAuthorizationPromise = new Promise<[string, string | undefined] | undefined>(
    (resolve, reject) => {
      const setMobilePayAuthorization = (base64Data?: string, iOSBillingAddress?: string): void => {
        if (base64Data) {
          resolve([base64Data, iOSBillingAddress]);
        } else {
          reject(undefined);
        }
      };

      if (window.anywhere !== undefined) {
        window.anywhere.setMobilePayAuthorization = setMobilePayAuthorization;
      }
    }
  );

  window.sheetzNativeOrderz?.getMobilePayAuthorization(totalAmount);

  return mobilePaymentAuthorizationPromise;
}

/**
 * getMobilePayStatus
 */
export function getMobilePaymentMethods(): string | undefined {
  return window.sheetzNativeOrderz?.getMobilePayStatus?.();
}

/**
 * getRavelinId
 */
export function getRavelinIdFromNativeMobileBridge(): string | undefined | null {
  return window.sheetzNativeOrderz?.getRavelinId?.();
}

/**
 * hideLoadingIndicator
 */
export function hideNativeMobileLoadingIndicator(): void {
  window.sheetzNativeOrderz?.hideLoadingIndicator();
}

/**
 * openAccountSettings
 */

export function openAccountSettings(): void {
  window.sheetzNativeOrderz?.openAccountSettingsWebView();
}

/**
 * setPageTitle
 */
export function setPageTitleForMobileHeader(title?: string): void {
  window.sheetzNativeOrderz?.setPageTitle(title || "Sheetz Ordering");
}

/**
 * showBioMetrics
 */
export function showBioMetricsStatus(status: boolean): void {
  window.sheetzNativeOrderz?.showBioMetrics?.(status);
}

/**
 * showCreateUpdatePin
 */
export function setShowCreateUpdatePin(): Promise<boolean | undefined> {
  /**
   * Create a promise that the mobile apps will resolve/reject when create / update pin
   * or cancellation is complete. The mobile apps will do this by calling
   * one of the functions below that are added to the `window.anywhere`
   * namespace. These functions will resolve with a true or
   * reject the promise if something went wrong or pin create / update was canceled.
   */
  const createUpdatePinPromise = new Promise<boolean | undefined>((resolve, reject) => {
    const pinCreateUpdateStatus = (status?: boolean): void => {
      if (status) {
        resolve(status);
      } else {
        reject(undefined);
      }
    };

    if (window.anywhere !== undefined) {
      window.anywhere.pinCreateUpdateStatus = pinCreateUpdateStatus;
    }
  });

  window.sheetzNativeOrderz?.showCreateUpdatePin();

  return createUpdatePinPromise;
}

/**
 * showDialog
 */
export function showNativeDialog(error: boolean, title: string | null, description: string): void {
  window.sheetzNativeOrderz?.showDialog?.(error, title, description);
}

/**
 * showLoadingIndicator
 */
export function showNativeMobileLoadingIndicator(): void {
  window.sheetzNativeOrderz?.showLoadingIndicator();
}

/**
 * showNativeWallet
 */
export function showNativeWalletInterface(orderTotal: number): Promise<number> {
  /**
   * Create a promise that the mobile apps will resolve/reject when pin entry
   * or cancellation is complete. The mobile apps will do this by calling
   * one of the functions below that are added to the `window.anywhere`
   * namespace. These functions will resolve with the passed in pin or
   * reject the promise if pin entry was canceled.
   */
  const walletPromise = new Promise<number>((resolve, reject) => {
    const setPaymentMethodId = (paymentMethodId: number): void => resolve(paymentMethodId);
    const nativeWalletCanceled = (): void => reject();

    if (window.anywhere !== undefined) {
      window.anywhere.setPaymentMethodId = setPaymentMethodId;
      window.anywhere.nativeWalletCanceled = nativeWalletCanceled;
    }
  });

  window.sheetzNativeOrderz?.showNativeWallet?.(orderTotal);

  return walletPromise;
}

/**
 * showPushNotifications
 */
export function showPushNotificationStatus(status: boolean): void {
  window.sheetzNativeOrderz?.showPushNotifications?.(status);
}

/**
 * showSuccess
 */
export function showNativeSuccess(title: string, subtext: string): void {
  window.sheetzNativeOrderz?.showSuccess?.(title, subtext);
}

/**
 * storeSelectionLoaded
 */
export function notifyNativeMobileStoreSelectionLoaded(): void {
  window.sheetzNativeOrderz?.storeSelectionLoaded?.();
}

/**
 * storeSelectionUnloaded
 */
export function notifyNativeMobileStoreSelectionUnloaded(): void {
  window.sheetzNativeOrderz?.storeSelectionUnloaded?.();
}

/**
 * Helper functions that are used throughout the app
 * but don't communicate between native and anywhere
 */

// Check if user is in orderz.
export function isInNativeMobileContext(): boolean {
  return !!window.sheetzNativeOrderz;
}

// Make sure we are able to show the native dialog.
export function showNativeDialogAvailable(): boolean {
  return window.sheetzNativeOrderz?.showDialog !== undefined;
}

// Get the userId from the auth token and compare it to any existing userId on the order session.
export function getUserIdFromNativeMobileAuthToken(): number | undefined {
  const jwtToken = getAuthTokenFromNativeMobileBridge();
  const tokenPayload = getAuthTokenPayload(jwtToken);
  return tokenPayload?.sub ? parseInt(tokenPayload?.sub) : undefined;
}

// Try and detect the device a user is on and send them to the correct app store.
export function getAppStoreURL(): string {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.ust.sheetzretapp&hl=en_US";
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "https://apps.apple.com/us/app/sheetz/id623188302";
  } else {
    return "https://sheetz.com/app";
  }
}
