import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import { Formik, FormikProps } from "formik";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import "./EditFavorite.scss";

import {
  FavoriteOrder,
  FavoriteOrderCombo,
  FavoriteOrderItem,
  UpdateFavoriteOrderRequest,
} from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import ReorderAvailabilityActionSheet from "components/Order/Reorder/RedorderAvailabilityActionSheet/ReorderAvailabilityActionSheet";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { ShoppingBag } from "util/Bag.util";
import {
  UnavailabilityDetails,
  areAllItemsUnavailable,
  createReorderItemsDisplay,
  deleteFavoriteOrder,
  filterUnavailableEntities,
  getUnavailabilityDetails,
  isFavoriteOrderCombo,
  isOrderFullyAvailable,
  mapPreviousOrderToShoppingBag,
  updateFavoriteOrder,
} from "util/Reorder.util";

interface EditFavoriteFormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Required").max(50),
});

export interface EditFavoriteProps extends OrderSubviewProps {
  handleAddHistoryFavoriteToBag: (order: ShoppingBag, path?: string) => void;
}

const EditFavorite = (props: EditFavoriteProps) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state as { favoriteOrder: FavoriteOrder };
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [favoriteOrder, setFavoriteOrder] = useState<FavoriteOrder>(locationState.favoriteOrder);
  const [itemIndiciesToRemove, setItemIndiciesToRemove] = useState<number[]>([]);
  const initialFormValues: EditFavoriteFormValues = { name: favoriteOrder.name };
  const [showUnavailabilityActionSheet, setShowUnavailabilityActionSheet] =
    useState<boolean>(false);
  const [unavailabilityDetails, setUnavailabilityDetails] = useState<
    UnavailabilityDetails | undefined
  >();

  useEffect(() => {
    if (queryParams.addToBag) {
      if (!isOrderFullyAvailable(favoriteOrder)) {
        // Slight delay needed due to navigation immediately followed by action sheet showing.
        setTimeout(() => {
          setUnavailabilityDetails(getUnavailabilityDetails(favoriteOrder));
          setShowUnavailabilityActionSheet(true);
        }, 500);
      } else {
        addOrderToBag();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addOrderToBag(filteredFavorite?: FavoriteOrder): void {
    const tempShoppingBag = mapPreviousOrderToShoppingBag(
      filteredFavorite ?? favoriteOrder,
      "FAVORITE"
    );

    props.handleAddHistoryFavoriteToBag(tempShoppingBag, "/order/favorites");
  }

  function addToBagPressed(): void {
    if (
      (!props.orderSession.store ||
        !props.orderSession.pickupTime ||
        !props.orderSession.pickupLocation) &&
      !props.orderSession.delivery
    ) {
      navigate("/order/selectStore", {
        state: {
          redirectOnOrderFlowFinish: "/order/favorites/detail?addToBag=true",
          favoriteOrder: favoriteOrder,
        },
      });
    } else if (!isOrderFullyAvailable(favoriteOrder)) {
      setUnavailabilityDetails(getUnavailabilityDetails(favoriteOrder));
      setShowUnavailabilityActionSheet(true);
    } else {
      addOrderToBag();
    }
  }

  function createFilteredItemsAndCombos(): [FavoriteOrderCombo[]?, FavoriteOrderItem[]?] {
    const favoriteOrderCombos = favoriteOrder.combos?.length ? favoriteOrder.combos : undefined;
    const favoriteOrderItems = favoriteOrder.items;
    const numberOfLineItems = favoriteOrderItems.length + (favoriteOrderCombos?.length ?? 0);
    let allFavoriteLineItems: (FavoriteOrderCombo | FavoriteOrderItem)[];
    // The order created here corresponds to order the favorite items and combos are displayed.
    if (favoriteOrderCombos !== undefined) {
      allFavoriteLineItems = [...favoriteOrderCombos, ...favoriteOrderItems];
    } else {
      allFavoriteLineItems = [...favoriteOrderItems];
    }
    const combos: FavoriteOrderCombo[] = [];
    const items: FavoriteOrderItem[] = [];
    const combosRemoved: number[] = [];

    // If the number of items to remove is at least 1 and less than the total number of line items in the favorite, proceed.
    if (itemIndiciesToRemove.length > 0 && itemIndiciesToRemove.length < numberOfLineItems) {
      // Iterate over all line items of the favorite and only copy the ones that are not set to be removed.
      allFavoriteLineItems.forEach((lineItem, index) => {
        if (!itemIndiciesToRemove.includes(index)) {
          if (isFavoriteOrderCombo(lineItem)) {
            combos.push(lineItem);
            // If the item doesn't have a comboId (isn't part of a combo), or isn't part of a combo that is being removed, then add the item.
          } else if (lineItem.comboId === undefined || !combosRemoved.includes(lineItem.comboId)) {
            items.push(lineItem);
          }
        } else {
          if (isFavoriteOrderCombo(lineItem)) {
            combosRemoved.push(lineItem.comboId);
          }
        }
      });
      // If the user is trying to remove all favorite line items, then show an error.
    } else if (itemIndiciesToRemove.length === numberOfLineItems) {
      appContext.showToast(
        "Oh Sheetz!",
        "Cannot remove all items from a favorite. Please use the delete option instead.",
        ToastType.error
      );

      return [];
    }

    return [combos, items];
  }

  function deleteFavorite(): void {
    if (favoriteOrder.favoriteId === undefined) {
      throw new SheetzError("Attempting to delete a favorite without a favoriteID.", {
        userReadableMessage: "Unable to update your Favorite. Please try again.",
        primaryButton: SheetzErrorButtonType.OK,
        secondaybutton: SheetzErrorButtonType.CLOSE,
      });
    }

    appContext.showLoading();

    deleteFavoriteOrder(favoriteOrder.favoriteId)
      .then(() => {
        appContext.showToast(
          "Success",
          "Your favorite has been deleted successfully!",
          ToastType.success
        );
        navigate(-1);
      })
      .finally(() => {
        appContext.hideLoading();
        appContext.hideConfirmationActionSheet();
      });
  }

  function deleteFavoritePressed(): void {
    appContext.showConfirmationActionSheet(
      "Are you sure you want to delete this favorite? This cannot be undone.",
      "Delete Favorite",
      deleteFavorite
    );
  }

  function orderWithoutPressed(): void {
    setShowUnavailabilityActionSheet(false);

    const filteredFavorite = filterUnavailableEntities(favoriteOrder) as FavoriteOrder;
    addOrderToBag(filteredFavorite);
  }

  function toggleItemForRemoval(index: number, remove: boolean): void {
    if (remove) {
      setItemIndiciesToRemove(itemIndiciesToRemove.concat([index]));
    } else {
      setItemIndiciesToRemove(itemIndiciesToRemove.filter((itemIndex) => itemIndex !== index));
    }
  }

  function updateFavorite(newName: string): void {
    const updateFavoriteOrderRequest: UpdateFavoriteOrderRequest = {
      order: {
        favoriteId: favoriteOrder.favoriteId,
        name: newName,
      },
    };

    // If there are items to remove, then filter them out.
    if (itemIndiciesToRemove.length > 0) {
      const [combos, items] = createFilteredItemsAndCombos();

      if (combos === undefined && items === undefined) {
        return;
      }

      updateFavoriteOrderRequest.order.combos =
        combos && combos.length > 0
          ? combos
          : favoriteOrder.combos?.length
          ? favoriteOrder.combos
          : undefined;

      updateFavoriteOrderRequest.order.items =
        items && items.length > 0 ? items : favoriteOrder.items;
    }

    if (props.orderSession.store) {
      updateFavoriteOrderRequest.storeNumber = props.orderSession.store.storeNumber;
    }

    appContext.showLoading();

    updateFavoriteOrder(updateFavoriteOrderRequest)
      .then((response) => {
        setFavoriteOrder(response.data.order);
        setItemIndiciesToRemove([]);

        navigate(location.pathname, {
          state: {
            ...locationState,
            favoriteOrder: response.data.order,
          },
          replace: true,
        });
      })
      .finally(() => {
        setTimeout(() => {
          appContext.showToast(
            "Favorite Successfully Updated!",
            "You're good to go!",
            ToastType.success
          );
          appContext.hideLoading();
        }, 1700);
      });
  }

  return (
    <>
      <div className="edit-favorite-container">
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values): void => {
            updateFavorite(values.name);
          }}
        >
          {(formProps: FormikProps<EditFavoriteFormValues>) => (
            <form
              onSubmit={formProps.handleSubmit}
              id="editFavoriteForm"
              className="edit-favorite-form"
              autoComplete="off"
            >
              <div className="favorite-details-scroll-container">
                <div className="edit-favorite">
                  <SheetzInput
                    type="text"
                    name="name"
                    label="Favorite Name"
                    placeholder="Favorite Name"
                  />
                </div>

                <ul className="favorite-order-item-list">
                  {createReorderItemsDisplay(favoriteOrder, toggleItemForRemoval)}
                </ul>

                <SheetzButton
                  transparentDark
                  className="delete-favorite-button"
                  label="Delete Favorite"
                  type="button"
                  onClick={deleteFavoritePressed}
                />
              </div>

              <div className="edit-favorite-footer">
                {!areAllItemsUnavailable(favoriteOrder) && (
                  <SheetzButton
                    label="Add to Bag"
                    type="button"
                    borderColor="light-border"
                    onClick={addToBagPressed}
                  />
                )}
                <SheetzButton
                  transparentLight
                  label="Save"
                  type="submit"
                  disabled={!formProps.dirty && itemIndiciesToRemove.length === 0}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <ReorderAvailabilityActionSheet
        show={showUnavailabilityActionSheet}
        unavailabilityDetails={unavailabilityDetails}
        onOrderWithoutPressed={orderWithoutPressed}
        onCancelPressed={(): void => setShowUnavailabilityActionSheet(false)}
      />
    </>
  );
};

export default EditFavorite;
