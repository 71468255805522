import classNames from "classnames";
import { DateTime } from "luxon";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import "./MenuItem.scss";

import { OrderingSystem } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import ItemImageTextDisplay from "components/misc/item/ItemDisplay/ItemImageTextDisplay/ItemImageTextDisplay";
import ListItem from "components/misc/list/ListItem/ListItem";

import { IconType, getIcon } from "util/Icon.util";
import { MenuItemType, OrderStatus } from "util/Order.util";
import { getDateTimeFromString } from "util/Time.util";

interface MenuItemProps {
  name: string;
  subtext?: string;
  image?: string;
  price?: string;
  calories?: number;
  link?: string;
  onClick?: () => void;
  date?: string;
  type: MenuItemType;
  orderStatus?: string;
  orderingSystem?: OrderingSystem;
  staticImage?: string;
  isCustomizable?: boolean;

  // For type=FAVORITE
  reorderUnavailable?: boolean;
  onReorderUnavailableSelected?: () => void;
  onEditSelected?: () => void;
}

const MenuItem = (props: MenuItemProps) => {
  let description: JSX.Element | string | undefined = props.subtext;
  let icon: ReactElement | null = null;

  if (!props.image) {
    icon =
      props.orderingSystem && props.orderingSystem === "MPOS"
        ? getIcon(IconType.sheetzGo666SQ, "menu-item-placeholder-image-sheetz-go")
        : getIcon(IconType.bag, "menu-item-placeholder-image");
  }

  if (props.orderStatus) {
    description = (
      <span
        className={classNames({
          "secondary-text": props.orderStatus === OrderStatus.PENDING_DELIVERY,
          "sheetz-red":
            props.orderStatus === OrderStatus.CANCELLED || props.orderStatus === OrderStatus.FAILED,
        })}
      >
        <strong>
          {props.orderingSystem && props.orderingSystem === "MPOS"
            ? props.orderStatus + "- SheetzGo!"
            : props.orderStatus}
        </strong>
      </span>
    );

    if (!props.orderStatus && props.orderingSystem && props.orderingSystem === "MPOS") {
      description = (
        <span className="menu-item-order-status">
          <strong>SheetzGo</strong>
        </span>
      );
    }
  }

  const InnerListItem = (
    <>
      <div className="menu-item-flex-container">
        <ListItem
          hideArrow={true}
          clickHandler={props.onClick}
          // Orders from history can still be navigated to, so don't disable in this case.
          disabled={props.reorderUnavailable && props.type === MenuItemType.favorite}
          hideBottomBorder={[MenuItemType.favorite, MenuItemType.order].includes(props.type)}
        >
          <div className="menu-item">
            <div className="item-image-text-container">
              <ItemImageTextDisplay
                description={description}
                icon={icon}
                image={props.image}
                paddingRight
                staticImage={props.staticImage}
                title={props.name}
              />
            </div>

            {(props.price !== undefined || props.calories !== undefined) &&
              props.type !== MenuItemType.receipt && (
                <div className="menu-item-info-container">
                  {props.price !== undefined && (
                    <p className="price">
                      {props.price}
                      {props.isCustomizable && <span>+</span>}
                    </p>
                  )}
                  {props.calories !== undefined && (
                    <p className="calories">{props.calories} cals</p>
                  )}
                </div>
              )}
            {props.type === MenuItemType.receipt && props.date && (
              <div className="menu-item-info-container">
                {props.date !== undefined && (
                  <p className="pickup-date">
                    {getDateTimeFromString(props?.date).toLocaleString(DateTime.DATE_SHORT)}
                  </p>
                )}
              </div>
            )}
          </div>
        </ListItem>
        {!!props.onEditSelected && (
          <SheetzContainerButton
            aria-label={"Edit Favorite: " + props.name}
            className="favorite-edit-button"
            onClick={(): void => props.onEditSelected?.()}
          >
            {getIcon(IconType.edit, "")}
          </SheetzContainerButton>
        )}
      </div>
      {props.reorderUnavailable && (
        <div className="item-unavailable-button-container">
          <SheetzButton
            className="unavailable-notice"
            label="Ingredient / Item Unavailable"
            borderColor="light-border"
            transparentDark
            smallButton
            onClick={props.onReorderUnavailableSelected}
          />
        </div>
      )}
    </>
  );

  return props.link ? (
    <Link className="menu-item-link" to={props.link} tabIndex={-1}>
      {InnerListItem}
    </Link>
  ) : (
    <div className={"menu-item-button " + MenuItemType[props.type]}>{InnerListItem}</div>
  );
};

export default MenuItem;
