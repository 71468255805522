import { Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import { PatternFormat } from "react-number-format";
import * as Yup from "yup";

import "./ChangePhoneNumber.scss";

import { GetUserProfileResponse } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";

import { updateUserProfile } from "util/Account.util";
import { AppContext } from "util/AppContext.util";
import { phoneValidation } from "util/Validation.util";

interface ChangePhoneNumberFormValues {
  phoneNumber: string;
}

interface ChangePhoneNumberProps {
  userProfile: GetUserProfileResponse;
  onPhoneNumberChanged: (phoneNumber: string) => void;
}

const ChangePhoneNumber = (props: ChangePhoneNumberProps) => {
  const appContext = useContext(AppContext);
  const validationSchema = Yup.object({
    phoneNumber: phoneValidation.required("Required"),
  });

  function updatePhone(values: ChangePhoneNumberFormValues): void {
    if (props.userProfile.phoneNumber === values.phoneNumber) {
      props.onPhoneNumberChanged(values.phoneNumber);
      return;
    }
    const updatedUserProfile: GetUserProfileResponse = {
      ...props.userProfile,
      phoneNumber: values.phoneNumber,
    };
    appContext.showLoading();
    updateUserProfile(updatedUserProfile)
      .then((response) => {
        props.onPhoneNumberChanged(values.phoneNumber);
      })
      .finally(appContext.hideLoading);
  }

  const initialFormValues: ChangePhoneNumberFormValues = {
    phoneNumber: props.userProfile.phoneNumber,
  };

  return (
    <div className="change-phone-number">
      <p className="change-notice">
        Changing your phone number will update your account information.
      </p>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={updatePhone}
      >
        {(formProps: FormikProps<ChangePhoneNumberFormValues>) => (
          <form onSubmit={formProps.handleSubmit}>
            <PatternFormat
              format="(###) ###-####"
              name="phoneNumber"
              placeholder="Phone Number"
              label="Phone Number"
              type="tel"
              customInput={SheetzInput}
              defaultValue={initialFormValues.phoneNumber}
              onValueChange={(value): void => formProps.setFieldValue("phoneNumber", value.value)}
              valueIsNumericString
            />
            <div className="buttons">
              <SheetzButton type="submit" label="Update on my account" className="submit-button" />
              <SheetzTextButton
                label="Cancel"
                textDark
                type="button"
                onClick={(): void => props.onPhoneNumberChanged(props.userProfile.phoneNumber)}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePhoneNumber;
