import classNames from "classnames";
import React, { ReactElement } from "react";
import { useErrorBoundary } from "react-error-boundary";

import "./SheetzTextButton.scss";

interface SheetzTextButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  label: string | ReactElement;
  label2?: string | ReactElement;
  textDark?: boolean;
}

const SheetzTextButton = (props: SheetzTextButtonProps) => {
  const { showBoundary } = useErrorBoundary();
  const { label, label2, onClick, ...rest } = props;
  const buttonClasses = classNames("text-button", rest.className, {
    disabled: rest.disabled,
    dark: props.textDark,
  });

  function handleClickWithErrorBoundary(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    try {
      props.onClick?.(event);
    } catch (error) {
      showBoundary(error);
    }
  }

  return (
    <button {...rest} onClick={handleClickWithErrorBoundary} className={buttonClasses}>
      {label}
      {label2}
    </button>
  );
};

export default SheetzTextButton;
