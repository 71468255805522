import { Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import "./CodeEntry.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { logout, sendCode, setAuthorization, validatePasscode } from "util/Authentication.util";
import { closeWebView, isInNativeMobileContext } from "util/MobileApp.util";
import { getFPRToken } from "util/Storage.util";

const forcePasswordResetTokenParamId = "fpr";

interface FormValues {
  codeEntry: string;
}

const CodeEntry = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInNativeWebView = isInNativeMobileContext();

  // Get FPR token either from the URL search params (coming from mobile app), or from session storage.
  function resolveFPRToken(): string | null {
    return searchParams.get(forcePasswordResetTokenParamId) ?? getFPRToken();
  }

  return (
    <>
      <div className="code-entry-container">
        <p className="enter-code-here-label">ENTER YOUR CODE HERE</p>
        <p className="code-entry-info">
          We&apos;ve sent a code to your associated email address to reset your password. If you
          didn&apos;t receive an email,{" "}
          <SheetzTextButton
            className="resend-code"
            onClick={(): Promise<void> =>
              sendCode(resolveFPRToken()).then(() => {
                appContext.showToast(
                  "New code has been sent, check your email.",
                  "",
                  ToastType.success
                );
              })
            }
            label="try sending your code"
            type="button"
            textDark
          />
          .
        </p>

        <Formik
          initialValues={{ codeEntry: "" }}
          onSubmit={(values: FormValues): void => {
            appContext.showLoading();
            validatePasscode(values.codeEntry, resolveFPRToken())
              .then((response) => {
                // This is a temporary auth token until the user resets their password
                setAuthorization(response.data.authorizationToken);
                setTimeout(() => {
                  navigate("/auth/resetPassword", { state: { bypassValidateReset: true } });
                }, 1000);
              })
              .finally(() => appContext.hideLoading());
          }}
          validationSchema={Yup.object({
            codeEntry: Yup.string()
              .required("Required")
              .matches(new RegExp("\\d{6}"), "Invalid code. This code must be 6 numbers.")
              .max(6)
              .min(6),
          })}
        >
          {(props: FormikProps<FormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <SheetzInput
                type="text"
                name="codeEntry"
                placeholder="6-Digit Code"
                label="6-Digit Code"
              />
              <div className="code-entry-continue-button">
                <SheetzButton className="submit-button" type="submit" label="Continue" />
              </div>
              <SheetzTextButton
                label="Cancel"
                type="button"
                textDark
                onClick={() => {
                  logout();
                  if (isInNativeWebView) {
                    closeWebView();
                  } else {
                    navigate("/auth/login");
                  }
                }}
              />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CodeEntry;
