import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import classNames from "classnames";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useLocation, useNavigate } from "react-router-dom";

import "./ShoppingBag.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import DeliveryPickupInformation from "components/Order/DeliveryPickupInformation/DeliveryPickupInformation";
import MyPickupInfo from "components/Order/MyPickupInfo/MyPickupInfo";
import { OrderSubviewProps } from "components/Order/Order";
import AddedToBagNotice from "components/layout/Bag/AddedToBagNotice/AddedToBagNotice";
import BagCombo from "components/layout/Bag/BagCombo/BagCombo";
import BagItem from "components/layout/Bag/BagItem/BagItem";
import ShoppingBagSubtotal from "components/layout/Bag/ShoppingBagSubtotal";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { useMediaQuery } from "hooks";

import { AppContext, desktopMediaQuery } from "util/AppContext.util";
import { isLoggedIn } from "util/Authentication.util";
import {
  BagRequestItemEvent,
  BagUpdates,
  createUpdateShoppingBagRequest,
  isShoppingBagCombo,
  isShoppingBagItem,
  updateBagFromServerCall,
  updateShoppingBag,
} from "util/Bag.util";
import { IconType, getIcon } from "util/Icon.util";
import { isInNativeMobileContext } from "util/MobileApp.util";

export interface ShoppingBagProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
  openBagExternal?: boolean;
  setBagUpdateMessages?: Dispatch<BagUpdates>;
}

const ShoppingBag = (props: ShoppingBagProps) => {
  const appContext = useContext(AppContext);
  const [useDesktopView] = useMediaQuery(desktopMediaQuery);
  const location = useLocation();
  const navigate = useNavigate();
  const [showBag, setShowBag] = useState<boolean>(props.openBagExternal || false);
  const [quantity, setQuantity] = useState<number>(0);
  const [shouldCallServerForUpdate, setShouldCallServerForUpdate] = useState<boolean>(true);
  const [showPickupInfo, setShowPickupInfo] = useState<boolean>(false);
  const isDelivery = props.orderSession.delivery;

  // Set a function on the `window.anywhere` namespace that allows the mobile apps to get the status of the bag.
  useEffect(() => {
    if (!isInNativeMobileContext() || window.anywhere === undefined) {
      return;
    }

    window.anywhere.isBagOpen = (): boolean => showBag;
    window.anywhere.closeBag = (): void => {
      setShowBag(false);
    };

    // Cleanup when shopping bag is unmounted.
    return (): void => {
      if (window.anywhere !== undefined) {
        window.anywhere.isBagOpen = (): boolean => false;
        window.anywhere.closeBag = (): void => {
          return;
        };
      }
    };
  }, [showBag]);

  useEffect(() => {
    if (props.openBagExternal) {
      toggleBag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openBagExternal]);

  const toggleBag = (): void => {
    setShowBag(!showBag);
  };

  const handleContinueButtonPress = (): void => {
    if (!/\/order\/menu/i.test(location.pathname)) {
      navigate("/order/menu");
    }
    handleBagOpenStateChange(false);
  };

  const handleBagOpenStateChange = (isOpen: boolean): void => {
    setShowBag(isOpen);
  };

  const shoppingBagClasses = classNames("shopping-bag", {
    "in-mobile-app": isInNativeMobileContext(),
    empty: quantity === 0,
  });

  function checkoutButtonPressed(): void {
    setShowBag(false);

    if (isLoggedIn()) {
      navigate("/order/paymentType");
    } else {
      navigate({
        pathname: "/auth/login",
        search: "?destination=/order/paymentType",
      });
    }
  }

  const shoppingBagContents = [
    ...(props.orderSession.shoppingBag?.items || []),
    ...(props.orderSession.shoppingBag?.combos || []),
  ]
    .sort((a, b) => (a.id < b.id ? -1 : 1))
    .map((bagContent) => {
      if (isShoppingBagItem(bagContent)) {
        return (
          <BagItem
            orderSession={props.orderSession}
            dispatch={props.dispatch}
            closeBagFn={toggleBag}
            shoppingBagItem={bagContent}
            key={bagContent.id}
          />
        );
      } else if (isShoppingBagCombo(bagContent)) {
        return (
          <BagCombo
            orderSession={props.orderSession}
            dispatch={props.dispatch}
            closeBagFn={toggleBag}
            shoppingBagCombo={bagContent}
            key={bagContent.id}
          />
        );
      } else {
        // This shouldn't happen, as we only have ShoppingBagItem and ShoppingBagCombo objects... but better to check typing on both.
        throw new SheetzError("Unhandled object type in Shopping bag contents", {
          userReadableMessage: "Looks like we've hit a snag. Please try again.",
          primaryButton: SheetzErrorButtonType.OK,
        });
      }
    });

  const emptyBagMessage: JSX.Element = (
    <div className="empty-bag-container">
      {getIcon(IconType.emptyBag, "empty-bag-icon")}
      <div className="empty-bag-message">
        <div className="empty-bag-title">Bags Don&apos;t Like to Feel Empty.</div>
        <div className="empty-bag-subtext">Add some stuff!</div>
      </div>
    </div>
  );

  // Monitor the shopping bag for changes so that the quantity can be properly updated.
  useEffect(() => {
    let newQuantity = 0;
    props.orderSession.shoppingBag?.items.forEach((shoppingBagItem) => {
      newQuantity += shoppingBagItem.quantity;
    });
    props.orderSession.shoppingBag?.combos.forEach((shoppingBagCombo) => {
      newQuantity += shoppingBagCombo.quantity;
    });
    setQuantity(newQuantity);
  }, [props.orderSession.shoppingBag]);

  // Monitor the order session for bag changes and store changes. A call to re-price the bag may be needed at that point.
  useEffect(() => {
    // For a delivery order, we only have the store number.
    const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;
    if (storeNumber === undefined || !props.orderSession.shoppingBag) {
      return;
    }

    // Ensure we are not pricing an empty bag.
    if (
      props.orderSession.shoppingBag.items.length < 1 &&
      props.orderSession.shoppingBag.combos.length < 1
    ) {
      return;
    }

    // When the bag or the store is changed, check with the server for prices and discounts.
    // The shouldCallServerForUpdate flag is used so it only happens once.
    // Otherwise, we'd have a loop where updating the price or discount would cause this effect to trigger again.
    if (!shouldCallServerForUpdate) {
      //If it's already happened, this flag is false, so we just set it to true for the next actual update.
      setShouldCallServerForUpdate(true);
      return;
    }

    const updateShoppingBagRequest = createUpdateShoppingBagRequest(
      storeNumber,
      isInNativeMobileContext() ? "MOBILE" : "WEB",
      props.orderSession.shoppingBag
    );

    updateShoppingBag(updateShoppingBagRequest, props.orderSession.orderSessionId).then(
      (response) => {
        const serverUpdates = updateBagFromServerCall(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          props.orderSession.shoppingBag!,
          response.data.items,
          updateShoppingBagRequest.items as BagRequestItemEvent[]
        );

        props.setBagUpdateMessages?.(serverUpdates);

        props.dispatch({
          type: "UPDATE_BAG_FROM_SERVER",
          payload: serverUpdates.updatedBag,
        });
      }
    );

    setShouldCallServerForUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderSession.shoppingBag, props.orderSession.store]);

  return (
    <>
      {appContext.addToBagPopup.render && (
        <AddedToBagNotice checkoutButtonPressed={checkoutButtonPressed} toggleBag={toggleBag} />
      )}
      <button
        className="shopping-bag-button"
        onClick={toggleBag}
        aria-label={showBag ? "Close Shopping Bag" : "Open Shopping Bag"}
      >
        <div className="shopping-bag-quantity">{quantity}</div>
        {getIcon(IconType.bag, "shopping-bag-button-icon")}
      </button>
      <Menu
        isOpen={showBag}
        right
        onStateChange={(state): void => handleBagOpenStateChange(state.isOpen)}
        customBurgerIcon={false}
        customCrossIcon={false}
        disableAutoFocus
        width={useDesktopView ? "480px" : "300px"}
        className={shoppingBagClasses}
        overlayClassName="shopping-bag-overlay"
        htmlClassName="menu-open"
      >
        <button
          className="shopping-bag-store-button"
          onClick={(): void => {
            toggleBag();
            setShowPickupInfo(true);
          }}
        >
          <div className="shopping-bag-store-details">
            {isDelivery
              ? getIcon(IconType.home, "home-icon")
              : getIcon(IconType.store, "store-icon")}
            <div className="shopping-bag-store-label">
              <DeliveryPickupInformation
                deliveryAddress={props.deliveryAddress}
                orderSession={props.orderSession}
              ></DeliveryPickupInformation>
            </div>
          </div>
          {getIcon(IconType.moreDots, "icon-more-dots")}
        </button>
        <div className={"continue-ordering-container" + (quantity === 0 ? " empty" : "")}>
          {quantity === 0 && emptyBagMessage}
          <SheetzButton
            className="continue-ordering-button"
            transparentDark={quantity > 0}
            label="Continue Ordering"
            onClick={handleContinueButtonPress}
          />
          {quantity === 0 && (
            <p className="version">
              Online Ordering v{process.env.REACT_APP_VERSION}-
              {process.env.REACT_APP_BUILD_NUMBER ?? "local"}
            </p>
          )}
        </div>
        {quantity > 0 && (
          <>
            {shoppingBagContents}
            <div className="shopping-bag-checkout-container">
              <SheetzButton
                className="shopping-bag-checkout-button"
                label="To Checkout"
                label2={<ShoppingBagSubtotal shoppingBag={props.orderSession.shoppingBag} />}
                borderColor="light-border"
                onClick={checkoutButtonPressed}
              />
            </div>
          </>
        )}
      </Menu>
      <MyPickupInfo
        show={showPickupInfo}
        close={(): void => setShowPickupInfo(false)}
        redirect={(path: string): void => {
          setShowPickupInfo(false);
          toggleBag();
          navigate(path, {
            state: {
              // TODO: use redirect on order setup pages to redirect after one, instead of going through the full flow
              redirectOnOrderFlowFinish: "/order/menu",
            },
          });
        }}
        orderSession={props.orderSession}
        dispatch={props.dispatch}
      />
    </>
  );
};

export default ShoppingBag;
